import React, { useReducer, Suspense, useState, useEffect } from "react";
import { PropTypes } from "prop-types";

import { init, reducer } from "./reducer";
import Spinner from "../../../../common/Spinner";

import  SummaryComponent from "./SummaryComponent";
const TopSitesComponent = React.lazy(() => import("./TopSitesComponent"));
import css from "../../app360MagneticStyle.less";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
// i18n files
import i18nMessageBundle from "amdi18n-loader!../../../nls/i18n";

const [
  HbrCard,
  HbrRadioButton,
  HbrViewSwitcher,
  HbrBadge,
  HbrIcon
] = reactWrapper([
  "hbr-card",
  "hbr-radio-button",
  "hbr-view-switcher",
  "hbr-badge",
  "hbr-icon"
]);

const MapSummaryComponent = props => {
  const { switchOptions, dispatchMap } = props;
  const { appName } = props.match.params;
  const [sitesDataCount, setSiteDataCount] = useState(0);
  const { OVERVIEW, TOP_SITES } = switchOptions;
  const [state, dispatch] = useReducer(reducer, OVERVIEW.value, init);
  const [toogle, setToggle] = useState(false);
  const [siteDashboardWidget, showDashboardWidget] = useState(true);

  useEffect(() => {
    if (
      props?.globalFilter?.app_data_with_family_sla != undefined &&
      props?.globalFilter?.app_data_with_family_sla?.length > 0
    ) {
      const filteredData = props?.globalFilter.app_data_with_family_sla.filter(
        row => row.application == appName
      );
      if (filteredData && filteredData?.length > 0) {
        const sitesData =
          filteredData[0].bad_count +
          filteredData[0].fair_count +
          filteredData[0].good_count +
          filteredData[0].gray_count;
        setSiteDataCount(sitesData);
      }
    }
  }, [props?.globalFilter?.app_data_with_family_sla]);

  /**  Temporary fix . Show and hide the content of the map widget card .
   * Once harbour accordion is ready need to implement component * */

  const showHideMapSummary = () => {
    if (!toogle) {
      showDashboardWidget(false);
      setToggle(true);
    } else {
      showDashboardWidget(true);
      setToggle(false);
    }
  };

  const handleChange = evt => {
    const { currentTarget } = evt;

    dispatch({
      type: currentTarget.value
    });

    dispatchMap({
      type: currentTarget.value
    });
  };

  return (
    <div className={css.outerdivapp360}>
      <HbrCard id="non-interactive" slot="label">
        <div className="map-summary-total-sites hbr-type-h3">
          {i18nMessageBundle.app360TotalSites}
          <HbrBadge className="app360-overview-badge" pill size="small">
            {sitesDataCount}
          </HbrBadge>

          <div className="site-toggle-icon" onClick={showHideMapSummary}>
            {toogle ? (
              <HbrIcon
                className="qoeTitleIcon"
                name="caret-down"
                sentiment="neutral"
              ></HbrIcon>
            ) : (
              <HbrIcon
                className="qoeTitleIcon"
                name="caret-up"
                sentiment="neutral"
              ></HbrIcon>
            )}
          </div>
        </div>
        {siteDashboardWidget ? (
          <div className="site-dashboard-widget">
            <div>
              <HbrViewSwitcher
                value={state}
                flavor="toggle-large"
                name="qoeFilter"
                onClick={handleChange}
              >
                <HbrRadioButton value={OVERVIEW.value}>
                  {OVERVIEW.label}
                </HbrRadioButton>
                <HbrRadioButton value={TOP_SITES.value}>
                  {TOP_SITES.label}
                </HbrRadioButton>
              </HbrViewSwitcher>
            </div>

            <div>
              {state === OVERVIEW.value ? (
                  <SummaryComponent />
              ) : (
                <Suspense fallback={<Spinner />}>
                  <TopSitesComponent {...props} />
                </Suspense>
              )}
            </div>
          </div>
        ) : null}
      </HbrCard>
    </div>
  );
};

MapSummaryComponent.propTypes = {
  sites: PropTypes.array,
  switchOptions: PropTypes.object.isRequired,
  dispatchMap: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  globalFilter: PropTypes.object
};

export default MapSummaryComponent;
