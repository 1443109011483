import React, { useState, useEffect, useReducer } from "react";
import { PropTypes } from "prop-types";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import App360Tabs from "./app360Tabs";
import { getTabList, o365AppList } from "./tabConfig";
import SelectedTabView from "../SelectedTabView";
import { init, reducer } from "../useReducer";
import reduxContext from "../reducer";
import * as actions from "../actions";
import AppHoc from "../../../generics/AppHOC";
import css from "../app360MagneticStyle.less";

const [HbrCard] = reactWrapper(["hbr-card"]);
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

const TabComponent = ({ showTabs }) => {
  const tabList = getTabList(showTabs);
  const defaultTabValue = tabList.find(tab => tab.default).name;
  const [state, dispatch] = useReducer(reducer, defaultTabValue, init);
  return (
    <HbrCard
      id="non-interactive"
      slot="label"
      className="app360-experience-widget-hbr-card"
    >
      <div className={css.app360container} data-cy="tab-view-app-360">
        <App360Tabs
          dispatch={dispatch}
          tabs={tabList}
          defaultTabValue={defaultTabValue}
        />

        <SelectedTabView tabContent={state} />
      </div>
    </HbrCard>
  );
};

TabComponent.propTypes = {
  showTabs: PropTypes.bool
};

const TabView = ({ globalFilter, match }) => {
  const { selectedSite, overlays, selectedOverlay } = globalFilter;
  const [component, setComponent] = useState(<></>);

  useEffect(() => {
    setTabsComponent();
  }, [selectedSite]);

  const filterRoles = () => {
    if (selectedOverlay) {
      const sOverlay = overlays.filter(
        overlay => overlay.key === selectedOverlay
      );
      return sOverlay.length > 0 ? sOverlay[0].roles : null;
    }
  };

  const setTabsComponent = () => {
    const o365Role = "o365";
    let showTabs = false;
    const roles = filterRoles();
    if (roles && roles.includes(o365Role)) {
      // show/hide O365 tabs
      showTabs = o365AppList.some(app => app === match.params.appName);
    }

    if (selectedSite < 0) {
      setComponent(<TabComponent showTabs={showTabs} />);
    } else {
      // clear the map and tabs when switching to app360 at site
      setComponent(<></>);
    }
  };

  return component;
};

TabView.propTypes = {
  globalFilter: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default reduxContext.withProvider(
  connect(null, mapDispatchToProps)(AppHoc(TabView))
);
