import { useEffect } from "react";
import { PropTypes } from "prop-types";
import { formatSizeUnitsRound, capitalizeString } from "../../../utils/format";
import useAggregateDataApis from "./viewHooks/useCommons";
import { getSankeyConfigData, sankeyKeys } from "./SankeyChartDataConfig";
import { colors, statusColors } from "../../../utils/colors";

export const SankeyChartData = props => {
  const { state, getSankeyChartData } = useAggregateDataApis();

  useEffect(() => {
    const { selectedSite, timeFilter: { current_period } } =
      props.globalFilter;
    if (!state.isFetchingSankeyChartData) {
      getSankeyChartData(current_period, props.appName, selectedSite);
      props.sankeyLoaderCallback(true);
      props.sankeyDropdownDataCallback([]);
      props.sankeyDataCallback([]);
      props.sankeyErrorCallback(false);
      props.sankeyNoDataCallback(false);
    }
  }, []);

  useEffect(() => {
    if (
      state.isFetchingSankeyChartDataDone &&
      !state.isFetchingSankeyChartData &&
      !state.isFetchingSankeyChartDataError &&
      state.sankeyChartData &&
      state.sankeyChartData.data &&
      state.sankeyChartData.data.length > 0
    ) {
      let sankeyChartData = [];

      let showRemoteSite = false;
      let sankeyChartOthersData = [];
      let remoteSites = [];
      const nodeLinksIdData = {};
      let uniqueSites = [];
      let topSites = [];
      let siteDefaultDropdownData = [];
      let siteDropdownData = [
        {
          text: "Top 5 Sites",
          value: "Reset"
        }
      ];

      let sankeyChartOthersLinks = [];
      state.sankeyChartData.data.forEach((data, index) => {
        let pathId = index + 1;
        if (!showRemoteSite && data.remote_color != "undefined") {
          showRemoteSite = true;
        }
        let linkValue = parseInt(
          data.usage.toString().length + data.usage.toString().slice(0, 2)
        );

        let color = statusColors[data.qoe.toLowerCase()];

        if (props.sankeyFilterKeys && props.sankeyFilterKeys.length > 0) {
          props.sankeyLoaderCallback(true);
          if (
            props.sankeyFilterKeys.indexOf(data.remote_site_id.toString()) != -1
          ) {
            remoteSites.push(data.remote_site_id);
          }
        } else if (topSites.length < 5) {
          if (remoteSites.indexOf(data.remote_site_id) == -1) {
            remoteSites.push(data.remote_site_id);
            topSites.push(data.remote_site_id);
            siteDefaultDropdownData.push(data.remote_site_id);
          }
        }

        //sets sites dropdown values

        if (uniqueSites.indexOf(data.remote_site_id) == -1) {
          siteDropdownData.push({
            text: data.remote_site_id,
            value: data.remote_site_id
          });
          uniqueSites.push(data.remote_site_id);
        }

        /** generates data for list of id's passing through node */
        sankeyKeys.forEach(keysData => {
          let dataKey =
            keysData == "qoe"
              ? data[keysData]
              : keysData == "remote_site_id" &&
                remoteSites.indexOf(data.remote_site_id) == -1
                ? "others" + "*duplicate*" + keysData
                : data[keysData] + "*duplicate*" + keysData;
          if (
            Object.prototype.hasOwnProperty.call(
              nodeLinksIdData,
              dataKey.toLowerCase()
            )
          ) {
            let linkIdNode = nodeLinksIdData[dataKey.toLowerCase()];
            linkIdNode.push(pathId);
          } else {
            nodeLinksIdData[dataKey.toLowerCase()] = [pathId];
          }
        });

        /***
         * @desc  generates sankey chart data
         * @param data {object} data from api response
         * @param   linkValue {number} defines the width of the link
         * @param color {string} defines color of the link
         */

        let sankeyData = getSankeyConfigData(data, linkValue, color, pathId);
        sankeyChartData.push(...sankeyData);

        if (remoteSites.indexOf(data.remote_site_id) != -1) {
          sankeyChartData.push({
            from:
              data.remote_color == "undefined"
                ? "noLabel" + "*duplicate*" + capitalizeString(data.qoe)
                : capitalizeString(
                  data.remote_color + "*duplicate*" + "remote_color"
                ),
            to: capitalizeString(
              data.remote_site_id + "*duplicate*" + "remote_site_id"
            ),
            tooltipValue: formatSizeUnitsRound(data.usage, true),
            value: linkValue,
            width: 10,
            color: colors.blue70,
            linkID: pathId,
            linkColor: colors.blue70,
            opacity: "0.1"
          });
        } else {
          sankeyChartOthersData.push({
            from:
              data.remote_color == "undefined"
                ? "noLabel" + "*duplicate*" + capitalizeString(data.qoe)
                : capitalizeString(
                  data.remote_color + "*duplicate*" + "remote_color"
                ),
            to: "Others" + "*duplicate*" + "remote_site_id",
            tooltipValue: formatSizeUnitsRound(data.usage, true),
            value: linkValue,
            width: 10,
            color: colors.blue70,
            linkID: pathId,
            linkColor: colors.blue70,
            opacity: "0.1"
          });
          sankeyChartOthersLinks.push(
            data.local_site_id +
            data.local_color +
            data.qoe +
            data.remote_color +
            data.remote_site_id
          );
        }
      });
      props.sankeyDropdownDataCallback(siteDropdownData);
      sankeyChartData.push(...sankeyChartOthersData);
      props.sankeyDataCallback(sankeyChartData);
      props.sankeyLoaderCallback(false);
      props.sankeyIdDataCallback(nodeLinksIdData);
      if (!showRemoteSite) {
        props.showRemoteSite(showRemoteSite);
      }
      if (props.sankeyFilterKeys.length < 1) {
        props.sankeyDefaultDropdownDataCallback(siteDefaultDropdownData);
      }
    } else if (
      state.isFetchingSankeyChartDataDone &&
      !state.isFetchingSankeyChartData &&
      state.isFetchingSankeyChartDataError
    ) {
      props.sankeyLoaderCallback(false);
      props.sankeyErrorCallback(true);
      props.sankeyErrorObjectCallback(state.isFetchingSankeyChartError);
    } else {
      if (
        state.isFetchingSankeyChartDataDone &&
        !state.isFetchingSankeyChartData &&
        !state.isFetchingSankeyChartDataError &&
        state.sankeyChartData &&
        state.sankeyChartData.data &&
        state.sankeyChartData.data.length === 0
      ) {
        props.sankeyNoDataCallback(true);
        props.sankeyLoaderCallback(false);
      }
    }
  }, [state.isFetchingSankeyChartData, props.sankeyFilterKeys]);
  return null;
};

SankeyChartData.propTypes = {
  globalFilter: PropTypes.object.isRequired,
  sankeyDataCallback: PropTypes.func,
  sankeyFilterKeys: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  sankeyIdDataCallback: PropTypes.func,
  showRemoteSite: PropTypes.func,
  sankeyErrorCallback: PropTypes.func,
  sankeyLoaderCallback: PropTypes.func,
  sankeyDropdownDataCallback: PropTypes.func,
  sankeyDefaultDropdownDataCallback: PropTypes.func,
  sankeyErrorObjectCallback: PropTypes.func,
  sankeyNoDataCallback: PropTypes.func,
  appName: PropTypes.string
};

SankeyChartData.defaultProps = {
  sankeyErrorCallback: true
};

export default SankeyChartData;
