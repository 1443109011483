import React, { useState, useEffect, useRef } from "react";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import { Funnel } from "phosphor-react";
import i18n from "amdi18n-loader!../../../nls/i18n";

const [
  HbrButton,
  HbrRadioGroup,
  HbrRadio,
  HbrCheckboxGroup,
  HbrCheckbox
] =
  reactWrapper([
    "hbr-button",
    "hbr-radio-group",
    "hbr-radio",
    "hbr-checkbox-group",
    "hbr-checkbox"
  ]);

export const PathAnaltyicsFilter = ({ appInfo, filters, onChange }) => {
  let timer;
  const [popupShow, setPopShow] = useState(false);
  const [deviceName, setDeviceName] = useState();
  const [interfaces, setInterfaces] = useState();
  const [serviceArea, setServiceArea] = useState();
  const [serverRegion, setServerRegion] = useState();
  const [deviceInfo, setDeviceInfo] = useState();
  const popupRef = useRef(null);

  useEffect(() => {
    if (deviceName) {
      const device = appInfo.find(
        item => item.device_host_name === deviceName
      );
      setDeviceInfo(device);
    }
  }, [deviceName]);

  useEffect(() => {
    if (popupShow) {
      setDeviceName(filters.devices.value);
      setInterfaces(filters.interfaces.value);
      if (filters.serviceArea)
        setServiceArea(filters.serviceArea.value);
      if (filters.serverRegion)
        setServerRegion(filters.serverRegion.value);
    }
  }, [popupShow]);

  useEffect(() => {
    const handleClickOutside = e => {
      if (popupRef.current && !popupRef.current.contains(e.target))
        setPopShow(false);
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const showPopup = () => {
    if (timer) clearTimeout(timer);
    setPopShow(true);
  };
  const closePopup = () => setPopShow(false);
  const cancel = () => closePopup();
  const apply = () => {
    const device = appInfo.find(
      item => item.device_host_name === deviceName
    );
    const result = {
      devices: { value: deviceName },
      interfaces: { value: interfaces },
      system_ip: { ...filters.system_ip, value: device.vdevice_id },
      feature: { value: device.feature }
    };
    if (serviceArea) {
      result.serviceArea = { value: serviceArea };
    } else if (serverRegion) {
      result.serverRegion = { value: serverRegion };
    }
    onChange(result);
    closePopup();
  };

  const changeDevices = e => {
    const targetValue = e.target.value;
    if (targetValue) {
      const _deviceInfo = appInfo.find(
        item => item.device_host_name === targetValue
      );
      setDeviceName(targetValue);
      setInterfaces(_deviceInfo?.interface);
      if (serviceArea) setServiceArea(_deviceInfo?.service_area[0]);
      else setServerRegion(_deviceInfo?.region_name[0])
    }
  };

  const changeInterfaces = e => {
    const value = e.target.value;
    if (interfaces.includes(value))
      setInterfaces(interfaces.filter(item => item !== value));
    else setInterfaces(interfaces.concat(value));
  };

  const changeServicesArea = e => setServiceArea(e.target.value);

  const changeServerRegion = e => setServerRegion(e.target.value);

  return (
    <div className="flex-items-two app360-section-title pa-filter">
      <div className="hbr-type-h2">
        {i18n.app360PathAnalytics.dataComparison}
      </div>
      <div>
        <HbrButton
          className="hbr-type-body3"
          sentiment="neutral"
          variant="outline"
          onClick={showPopup}
        >
          <Funnel slot="prefix" size={18} weight="bold" />
          {i18n.app360PathAnalytics.filter}
        </HbrButton>
        <div ref={popupRef}>
          <div
            className="popup-layout"
            style={{ display: popupShow ? "block" : "none" }}
          >
            <div className="popup-container">
              {deviceName && (
                <HbrRadioGroup
                  name="selected_device"
                  direction="column"
                  label={i18n.app360PathAnalytics.deviceName}
                  onHbr-click={changeDevices}
                >
                  {appInfo.map((item, index) => (
                    <HbrRadio
                      key={index}
                      name={item.device_host_name}
                      value={item.device_host_name}
                      checked={item.device_host_name === deviceName}
                    >
                      {item.device_host_name}
                    </HbrRadio>
                  ))}
                </HbrRadioGroup>
              )}
              {deviceInfo && (
                <HbrCheckboxGroup
                  name="selected_interfaces"
                  direction="column"
                  label={i18n.app360PathScoreColumnInterface}
                  onHbr-change={changeInterfaces}
                >
                  {deviceInfo?.interface.map(item => (
                    <HbrCheckbox
                      key={item}
                      name={item}
                      value={item}
                      checked={interfaces.includes(item)}
                      disabled={
                        interfaces.length === 1 && item === interfaces[0]
                      }
                    >
                      {item}
                    </HbrCheckbox>
                  ))}
                </HbrCheckboxGroup>
              )}
              {deviceInfo && serviceArea && (
                <HbrRadioGroup
                  name="selected_services_area"
                  direction="column"
                  label={i18n.app360PathAnalytics.serviceArea}
                  onHbr-click={changeServicesArea}
                >
                  {deviceInfo?.service_area.map(item => (
                    <HbrRadio
                      key={item}
                      name={item}
                      value={item}
                      checked={item === serviceArea}
                    >
                      {item}
                    </HbrRadio>
                  ))}
                </HbrRadioGroup>
              )}
              {deviceInfo && serverRegion && (
                <HbrRadioGroup
                  name="selected_server-region"
                  direction="column"
                  label={i18n.app360PathAnalytics.serverRegion}
                  onHbr-click={changeServerRegion}
                >
                  {deviceInfo?.region_name.map(item => (
                    <HbrRadio
                      key={item}
                      name={item}
                      value={item}
                      checked={item === serverRegion}
                    >
                      {item}
                    </HbrRadio>
                  ))}
                </HbrRadioGroup>
              )}
              <div className="flex-items-center button-container">
                <HbrButton variant="text" onClick={cancel}>
                  {i18n.app360PathAnalytics.cancel}
                </HbrButton>
                <HbrButton
                  variant="fill"
                  onClick={apply}
                >
                  {i18n.apply}
                </HbrButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PathAnaltyicsFilter.propTypes = {
  appInfo: PropTypes.array,
  filters: PropTypes.object,
  onChange: PropTypes.func
};
