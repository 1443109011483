import Authorization from "../../generics/authorization";
import FilterView from "./FilterView";
import TabView from "./TabView";

const xValue = 0;
const wValue = 12;

const pageProps = {
  filterDashlets: [{
    component: FilterView,
    name: "filter-view-app-360",
    layout: { x: xValue, y: 0, w: wValue, h: 30 },
    style: { zIndex: 1 }
  }],
  dashlets: [{
    component: TabView,
    name: "tab-view-app-360",
    layout: { x: xValue, y: 10, w: wValue, h: 45 }
  }]
};

const Page = () => (<Authorization pageProps={pageProps} />);

export default Page;
