import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Spinner from "../../../../../common/Spinner";
import NoDataAvailable from "../../../../../common/NoDataAvailable";
import reduxContext from "../../../reducer";
import * as actions from "../../../actions";
import AppHoc from "../../../../../generics/AppHOC";
import "../../../app360MagneticStyle.less";
import SummaryComponent from "./SummaryComponent";

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = state => ({
  siteSummary: state.vanalytics.app360
});

const MapSummaryComponent = props => {
  const { appName } = props.match.params;
  const [loader, setLoader] = useState(true);
  const [siteSummary, setSiteSummary] = useState([]);

  useEffect(() => {
    if (
      props?.globalFilter?.app_data_with_family_sla != undefined &&
      props?.globalFilter?.app_data_with_family_sla?.length > 0
    ) {
      setLoader(false);

      /** created summary data object from custom list api
       *
       */
      const filteredData = props.globalFilter.app_data_with_family_sla.filter(
        row => row.application == appName
      );
      const sitesData = [
        {
          bad_count: filteredData[0].bad_count,
          fair_count: filteredData[0].fair_count,
          good_count: filteredData[0].good_count,
          gray_count: filteredData[0].gray_count
        }
      ];
      setSiteSummary(sitesData);
    } else if (props?.globalFilter?.app_data_with_family_sla == undefined) {
      setLoader(true);
    }
  }, [props?.globalFilter?.app_data_with_family_sla]);

  if (loader) {
    return (
      <div className="map-summary-loader">
        <Spinner />
      </div>
    );
  }
  if (siteSummary) {
    if (siteSummary.length > 0) {
      return <SummaryComponent summaryData={siteSummary} />;
    }
    if (!props.siteSummary.siteSummary_loader) {
      return <NoDataAvailable />;
    }
  }
};

MapSummaryComponent.propTypes = {
  siteSummary: PropTypes.object.isRequired,
  globalFilter: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default reduxContext.withProvider(
  connect(mapStateToProps, mapDispatchToProps)(AppHoc(MapSummaryComponent))
);
