import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import reduxContext from "../reducer";
import * as actions from "../actions";
import AppHOC from "../../../generics/AppHOC";
import Spinner from "../../../common/Spinner";
import AppFlowTable from "./AppFlowTable";
import { isCustom, enableTunnelSelection } from "../../../utils/common";

const AppFlow = props => {
  const [topTunnels, setTopTunnels] = useState([]);
  const [topTunnelsData, setTopTunnelsData] = useState(false);
  const {
    selectedSite,
    timeFilter,
    timePeriodSelected,
    selectedCustomTime,
    currentTimeStamp
  } = props.globalFilter;
  const { getTunnelData, setTunnelTableLoading } = props.actions;

  useEffect(() => {
    if (
      (!isCustom(timePeriodSelected) || (selectedCustomTime?.current_period[0] && selectedCustomTime?.current_period[1])) &&
      !props.app360.appflow_table_loader
    ) {
      setTunnelTableLoading();
      getTunnelData(timeFilter, props.match.params.appName, selectedSite);
    }
  }, [
    timeFilter.current_period && timeFilter.current_period[0],
    selectedSite,
    currentTimeStamp && currentTimeStamp,
    selectedCustomTime?.current_period[0],
    selectedCustomTime?.current_period[1]
  ]);

  useEffect(() => {
    setTopTunnels([]);
    setTopTunnelsData(false);
    if (props.app360.appflow_data && props.app360.appflow_data.length > 0) {
      props.app360.appflow_data.sort(function (a, b) {
        let currValue = enableTunnelSelection(a) ? a.vqoe_score : 11;
        let prevValue = enableTunnelSelection(b) ? b.vqoe_score : 11;
        return currValue - prevValue;
      });
      let tunnelData = props.app360.appflow_data.slice(0, 5);
      tunnelData.forEach(data => {
        if (enableTunnelSelection(data)) {
          setTopTunnels(topTunnels => [...topTunnels, data.tunnel_name]);
        }
      });
      setTopTunnelsData(true);
    }
  }, [props.app360.appflow_data]);

  return (
    <div data-cy="appFlowContainer">
      {topTunnelsData === false || props.app360.appflow_table_loader ? (
        <div className="flow-table-loader">
          <Spinner />
        </div>
      ) : (
        <AppFlowTable {...props} data={props} topTunnels={topTunnels} />
      )}
    </div>
  );
};

AppFlow.propTypes = {
  globalFilter: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  app360: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = state => ({ app360: state.vanalytics.app360 });

export default reduxContext.withProvider(
  connect(mapStateToProps, mapDispatchToProps)(AppHOC(AppFlow))
);
