import React, { useRef, useMemo } from "react";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import Spinner from "../../../../common/Spinner";
import ErrorComponent from "../../../../common/ErrorComponent";
import NoDataAvailable from "../../../../common/NoDataAvailable";
import MultiLineChart from "../../../../common/MultiLineChart";
import ChartLegend from "../../../../common/ChartLegend";
import { displayDateTime } from "../../../../utils/displayTime";
import { formatSizeUnitsRound } from "../../../../utils/format";
import utils from "../utils";
import i18n from "amdi18n-loader!../../../nls/i18n";
import { chartPrimaryColors } from "../../../../utils/colors";
import { defaultTextValue } from "../../../../utils/enums";

const [HbrBadge] = reactWrapper(["hbr-badge"]);

const LineCharts = ({
  config,
  numberFormat,
  timePeriod,
  loader,
  error,
  noData,
  data,
  chartsData,
  created,
  hoverMultiLineCharts,
  hoverOutFromMultiLineChart
}) => {
  const legendRef = useRef();

  const { chartList, lineData, chartData } = useMemo(() => {
    const result = {
      chartList: config.data.map(item => ({
        name: item.label,
        key: getKeyForCategory(item.fields.value),
        title: item.title,
        unit: item.unit,
        isUsageChart: item.isUsageChart,
        average: ""
      })),
      lineData: [],
      chartData: {}
    };
    if (loader === false && error === null && noData === false) {
      result.chartList.forEach((item, index) => {
        const configItem = config.data[index];
        let average = utils.getAvearage(configItem.fields.value, data) || "";
        if (configItem.unitFn) average = configItem.unitFn(average);
        item.average = average;
      });
      result.lineData = utils.getLineSeries(data);
      result.chartData =
        utils.getCategoryData(result.chartList, result.lineData, data);
    }
    return result;
  }, [loader]);

  const getLegends = key => {
    legendRef.current = lineData.map((item, index) => ({
      label:
        key !== undefined && chartData[key].length > 0 ? item.name : "",
      field: item.field,
      color: item.color || chartPrimaryColors[index],
      strokeWidth: 2,
      type: "bar"
    }));
    return legendRef.current;
  };

  const getBaseLine = key => {
    if (chartData[key]) {
      return lineData.map((line, index) =>
        chartData[key].map(item => ({
          date: item.date,
          value: item[line.field + "_baseline"],
          color: line.color || chartPrimaryColors[index],
          strokeWidth: 1,
          strokeDasharray: [3, 2]
        }))
      );
    }
    return undefined;
  };

  const checkHasBaseLine = key => {
    const baseLines = getBaseLine(key);
    let hasBase = false;
    if (baseLines) {
      baseLines.forEach(line => {
        line.forEach(item => {
          if (item.value) {
            hasBase = true;
          }
        });
      });
    }
    return hasBase;
  };

  const getUsageValue = data => {
    return formatSizeUnitsRound(data, true);
  };

  const getTooltip = (data, config) => {
    let tooltipHTML;

    if (data) {
      tooltipHTML = `
        <div class="ttip-header">
          <span class="date">${displayDateTime(data.date)}</span>
        </div>
        <table class="ttip-content">
        ${legendRef.current
          .map(item => `
            <tr>
              <td class="ttip-bwidth">
                <div class="flex-items">
                  <span 
                    class="bar-legend" 
                    style="background-color:${item.color};"
                  ></span>
                  ${config.name}
                </div>
              </td>
              <td>${Number.isFinite(data[item.field])
              ? config.isUsageChart
                ? getUsageValue(data[item.field])
                : (String(data[item.field]).indexOf(".") > -1
                  ? data[item.field].toFixed(1)
                  : data[item.field]) + (config.unit ? config.unit : "")
              : defaultTextValue
            }</td>
            </tr>
          `)
          .join("")}
        </table>
      `;
    }
    return tooltipHTML;
  };

  return (
    <div className="section-container">
      <div className="hbr-type-h2 app360-section-title">
        {config.title}
      </div>
      {chartList.map(item => (
        <hbr-accordion key={item.title} open size="large" triggerposition="right">
          <div slot="label" className="width-full">
            <div className="flex-items-two">
              <div className="hbr-type-body2">{item.title}</div>
              {item.average !== "" && (
                <HbrBadge pill size="small">
                  {i18n.app360PathAnalytics.avearage + " " + item.average}
                </HbrBadge>
              )}
            </div>
          </div>
          <div className="flex-items-column width-full">
            {loader === true ? (
              <div className="pa-line-chart">
                <Spinner />
              </div>
            ) : error !== null ? (
              <div className="pa-line-chart">
                <ErrorComponent
                  {...error}
                  width="119px"
                  className="one-line-dashlet-error"
                />
              </div>
            ) : noData === true ? (
              <div className="flex-column-full pa-line-chart no-data-flex">
                <NoDataAvailable />
              </div>
            ) : (
              <>
                <MultiLineChart
                  id={"app360_at_site_path_" + config.title + "_" + item.name}
                  label={item.name}
                  showMultiLineTip
                  formatText
                  seriesConnect={false}
                  baseInterval={{ timeUnit: "minute", count: 10 }}
                  data={chartData[item.key]}
                  min={timePeriod[0]}
                  max={timePeriod[1]}
                  hoverMultiLineCharts={hoverMultiLineCharts}
                  uniqueKeysForlegend={getLegends()}
                  baseLine={getBaseLine(item.key)}
                  numberFormat={numberFormat}
                  chartsData={chartsData}
                  callbackFromParent={created}
                  globalToolTipsFn={dataItem => {
                    return getTooltip(dataItem, item);
                  }}
                  hoverOutFromMultiLineChart={
                    hoverOutFromMultiLineChart
                  }
                />
                <ChartLegend
                  data={[].concat(
                    getLegends(item.key),
                    checkHasBaseLine(item.key)
                      ? [{
                        label: i18n.app360PathAnalytics.threshold,
                        type: "dashed"
                      }]
                      : []
                  )}
                />
              </>
            )}
          </div>
        </hbr-accordion>
      ))}
    </div>
  );
};

LineCharts.propTypes = {
  config: PropTypes.object.isRequired,
  numberFormat: PropTypes.string,
  timePeriod: PropTypes.array,
  loader: PropTypes.bool.isRequired,
  error: PropTypes.object,
  noData: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  chartsData: PropTypes.arrayOf(PropTypes.object),
  created: PropTypes.func,
  hoverMultiLineCharts: PropTypes.func,
  hoverOutFromMultiLineChart: PropTypes.func
};

const getKeyForCategory = value => {
  let field = "";
  switch (true) {
    case value.includes("loss"):
      field = "loss";
      break;
    case value.includes("resolution"):
      field = "resolution";
      break;
    case value.includes("frame"):
      field = "frame";
      break;
    case value.includes("media"):
      field = "media";
      break;
    default:
      field = value;
  }
  return field !== "" ? field : value;
};

export default LineCharts;
