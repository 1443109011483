import React, {
  useState,
  useCallback,
  useEffect,
  useReducer,
  useMemo,
  Suspense
} from "react";
import { PropTypes } from "prop-types";

//components
import AppHoc from "../../../generics/AppHOC";
import Spinner from "../../../common/Spinner";
import MapSummaryComponent from "./MapSummaryComponent";
import GeoMapComponent from "./GeoMapComponent";

//lazy loading
const NoDataAvailable = React.lazy(() =>
  import("../../../common/NoDataAvailable")
);

//utils
import { isCustom } from "../../../utils/common";
import { init, reducer } from "./reducer";
import { shortDisplayDate24HourWithSec, getStartfDayUTCFormat } from "../../../utils/displayTime"

//configs
import apiService from "../../../config/api-config";

//enums
import { EXPERIENCE_COLORS, CUSTOM_MAKER, timeFilterTypes, fields } from "../../../utils/enums";

const SWITCH = {
  OVERVIEW: {
    value: "overview",
    label: "QoE"
  },
  TOP_SITES: {
    value: "topSites",
    label: "Top Sites by QoE"
  }
};
const qoeKey = "vqoe_score";

const MapComponent = props => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [state, dispatch] = useReducer(reducer, SWITCH.OVERVIEW.value, init);
  const {
    timeFilter,
    timePeriodSelected,
    selectedSite,
    selectedCustomTime,
    selectedOverlay,
    currentTimeStamp
  } = props.globalFilter;

  const mapOptions = useMemo(
    () => ({
      customMarker:
        state === SWITCH.OVERVIEW.value
          ? CUSTOM_MAKER.CIRCLE
          : state === SWITCH.TOP_SITES.value
            ? CUSTOM_MAKER.TRAY
            : undefined,
      disableClustering: state === SWITCH.TOP_SITES.value ? true : false,
      experienceColors: EXPERIENCE_COLORS
    }),
    [state]
  );

  const sitesToDisplay = useMemo(
    () => (state === SWITCH.TOP_SITES.value ? data.filter(row => row.vqoe_status !=='unknown').slice(0, 10) : data),
    [data, state]
  );

  const loadData = useCallback(async request => {
    setLoading(true);

    let sites = [];

    const response = await apiService.getMapViewSitesOptimized(request, localStorage.getItem("currentOverlay"));

    if (!response.errorObject) {
      sites = response.data.data;

      setData(
        Array.isArray(sites)
          ? sites.sort((site1, site2) =>
            site1[qoeKey] > site2[qoeKey]
              ? 1
              : site1[qoeKey] < site2[qoeKey]
                ? -1
                : 0
          )
          : []
      );
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    const timeFrame = timeFilterTypes[timePeriodSelected];
    const appName = props.match.params.appName;

    const request = {
      time_frame: timeFrame.toLowerCase(),
      application: appName,
      entry_ts: {
        start: shortDisplayDate24HourWithSec(getStartfDayUTCFormat(timeFilter.current_period[0])),
        end: shortDisplayDate24HourWithSec(getStartfDayUTCFormat(timeFilter.current_period[1]))//"2024-03-28 00:00:00"
      },
      ...{ fields: fields } 
    }

    if (!isCustom(timePeriodSelected) || selectedCustomTime) {
      loadData(request);
    }
  }, [
    timePeriodSelected,
    selectedSite,
    selectedCustomTime && selectedCustomTime.current_period[0],
    selectedCustomTime && selectedCustomTime.current_period[1],
    selectedOverlay,
    currentTimeStamp && currentTimeStamp
  ]);

  return (
    <>
      {loading ? (
        <div className="app360-table-spinner"><Spinner /></div>
      ) : !data.length ? (
        <Suspense fallback={<Spinner />}>
          <NoDataAvailable />
        </Suspense>
      ) : (
            <div className="map-container-app360">
              <MapSummaryComponent
                {...props}
                sites={sitesToDisplay}
                switchOptions={SWITCH}
                dispatchMap={dispatch}
              />

              <GeoMapComponent
                {...props}
                sites={sitesToDisplay}
                options={mapOptions}
              />
            </div>
          )}
    </>
  );
};

MapComponent.propTypes = {
  globalFilter: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default AppHoc(MapComponent);
