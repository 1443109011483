import axios from "axios";
import { createGenericErrorObject } from "../../utils/common";
import { formatBytes } from "../../utils/format";
import apiService from "../../config/api-config";
import {
  getPathTraceChartRequest,
  getPathTraceGraphRequest,
  getSankeyChartData,
} from "../../config/reqConfig/app360";
import * as apiConstant from "../../apis/apiConstants";
import { webexGroup } from "../../utils/enums";

export const ACTIONS = {
  TUNNEL_TABLE_LOADING: "TUNNEL_TABLE_LOADING",
  SET_TUNNEL_TABLE: "SET_TUNNEL_TABLE",
  TOPUSERS_TABLE_LOADING: "TOPUSERS_TABLE_LOADING",
  SET_TOPUSERS_TABLE: "SET_TOPUSERS_TABLE",
  SET_APPFLOW_TABLE: "SET_APPFLOW_TABLE",
  SITE_LIST_LOADING: "SITE_LIST_LOADING",
  SITE_LIST: "SITE_LIST",
  SITE_SUMMARY: "SITE_SUMMARY",
  SITE_SUMMARY_LOADING: "SITE_SUMMARY_LOADING",
  GET_SUMMARY: "GET_SUMMARY",
  PATH_SCORE: "PATH_SCORE",
  PARTNER_DATA_EXCHANGE: "PARTNER_DATA_EXCHANGE",
  EDGE_DATA_EXCHANGE: "EDGE_DATA_EXCHANGE",
  SET_PATHANALYTICS_APPFILTER: "SET_PATHANALYTICS_APPFILTER"
};

export const setTunnelTableLoading = () => ({
  type: ACTIONS.TUNNEL_TABLE_LOADING
});

export const getTunnelData = (timePeriod, appName, siteId) => dispatch => {
  const query = {
    current_period: timePeriod.current_period,
    filter: {
      application: appName,
      site_id: siteId.toString()
    }
  };
  siteId < 0 && delete query.filter.site_id;
  apiService.getApp360AppFlow(query).then(res => {
    const data = res.data?.data ? res.data.data : [];
    for (const item of data) item.usage = formatBytes(item.octets_sum);
    dispatch({
      type: ACTIONS.SET_APPFLOW_TABLE,
      payload: data
    });
  });
};

export const setTopUsersTableLoading = () => ({
  type: ACTIONS.TOPUSERS_TABLE_LOADING
});

export const getTopUsersData = (timePeriod, appName, siteId) => dispatch => {
  const query = {
    current_period: timePeriod.current_period,
    filter: {
      application: appName,
      site_id: siteId.toString()
    }
  };
  siteId < 0 && delete query.filter.site_id;
  apiService.getApp360TopUsers(query).then(res => {
    dispatch({
      type: ACTIONS.SET_TOPUSERS_TABLE,
      payload: res.data.data
    });
  });
};

export const isSiteListLoading = () => ({
  type: ACTIONS.SITE_LIST_LOADING
});

export const isSiteSummaryLoading = () => ({
  type: ACTIONS.SITE_SUMMARY_LOADING
});

export const getMapViewSiteDistributions = filter => dispatch => {
  apiService.getMapViewSiteDistributions(filter).then(res => {
    dispatch({
      type: ACTIONS.SITE_SUMMARY,
      payload: res.data.data
    });
  });
};

export const getAggregateData = async (appName, globalFilter, siteName) => {
  try {
    const aggregateDataConfig = {
      ...globalFilter.globalV4Payload,
      application: appName,
      site_id: siteName,
      sort: { "entry_ts": "asc" }
    };
    return await axios.post(
      apiConstant.AGGREGATE_DATA_SITE_DPI_URL,
      aggregateDataConfig
    );
  } catch (e) {
    return createGenericErrorObject(e);
  }
};

export const getCircuitsData = async (appName, globalV4Payload) => {
  try {
    const inputData = {
      ...globalV4Payload,
      application: appName
    }
    return await axios.post(
      apiConstant.CIRCUIT_DATA_SITE_URL,
      inputData
    );
  } catch (e) {
    return createGenericErrorObject(e);
  }
};

export const getPAAppInfo = async (payload) => {
  try {
    return await axios.post(
      apiConstant.SAAS_APP_BESTPATH_APPINFO,
      payload
    );
  } catch (e) {
    return createGenericErrorObject(e);
  }
};

export const getPAAppFilterData = async (payload, feature) => {
  const url = feature === webexGroup ? apiConstant.SAAS_APP_WEBEX_BESTPATH_APPFILTER : apiConstant.SAAS_APP_O365_BESTPATH_APPFILTER;
  try {
    return await axios.post(
      url,
      payload
    );
  } catch (e) {
    return createGenericErrorObject(e);
  }
};

export const getLineChartFormattedData = d => {
  const fieldKeys = Object.keys(d);
  const graphElem = {};
  fieldKeys.filter(fk => {
    switch (true) {
      case fk.includes("loss"):
        graphElem["loss"] = d[fk];
        break;
      case fk.includes("frame"):
        graphElem["frame"] = d[fk];
        break;
      case fk.includes("latency"):
        graphElem["latency"] = d[fk];
        break;
      case fk.includes("jitter"):
        graphElem["jitter"] = d[fk];
        break;
      case fk.includes("resolution"):
        graphElem["resolution"] = d[fk];
        break;
      case fk.includes("media"):
        graphElem["media"] = d[fk];
        break;
      default:
        graphElem[fk] = d[fk];
    }
  });
  return graphElem;
};

export const getPathTraceChartData = (tunnels, timeFilter, selectedSiteId) => {
  const baseUrl = apiConstant.PATH_TRACE_CHART_URL;
  let defaultRequest = JSON.parse(JSON.stringify(getPathTraceChartRequest));
  defaultRequest.query.rules[0].value = tunnels;
  defaultRequest.query.rules[1].value = timeFilter;
  defaultRequest.query.rules[2].value = [selectedSiteId];
  return axios.request({
    method: "POST",
    url: baseUrl,
    data: defaultRequest
  });
};

export const getPathTraceGraphData = (
  eventsList,
  timeFilter,
  selectedSiteId
) => {
  const baseUrl = apiConstant.PATH_TRACE_GRAPH_URL;
  let defaultRequest = JSON.parse(JSON.stringify(getPathTraceGraphRequest));
  defaultRequest.query.rules[0].value = eventsList.eventIdList;
  defaultRequest.query.rules[1].value = timeFilter;
  defaultRequest.query.rules[2].value = [selectedSiteId];
  return axios.request({
    method: "POST",
    url: baseUrl,
    data: defaultRequest
  });
};

export const getSankeyData = (timeFilter, appName, siteId) => {
  const baseUrl = apiConstant.SANKEY_DATA_SITE_URL;
  getSankeyChartData.current_period = timeFilter;
  getSankeyChartData.filter.application = appName;
  getSankeyChartData.filter.site_id = siteId;
  return axios.request({
    method: "POST",
    url: baseUrl,
    data: getSankeyChartData
  });
};

export const getPAPathData = async (payload, filters) => {
  try {
    let url = filters.feature.value === webexGroup ? apiConstant.SAAS_APP_WEBEX_BESTPATH_LINECHART : apiConstant.SAAS_APP_O365_BESTPATH_LINECHART;
    return await axios.post(
      url,
      payload
    );
  } catch (e) {
    return createGenericErrorObject(e);
  }
};

export const getPAMicrosoftData = async (payload) => {
  try {
    return await axios.post(
      apiConstant.APP365_PATH_ANALYTICS_CHART,
      payload
    );
  } catch (e) {
    return createGenericErrorObject(e);
  }
};

export const getPAUsageData = async (payload, filters) => {
  try {
    const url = filters.feature.value === webexGroup ? apiConstant.SAAS_APP_PATH_ANALYTICS_WEBEX_USAGE : apiConstant.SAAS_APP_PATH_ANALYTICS_O365_USAGE;
    return await axios.post(
      url,
      payload
    );
  } catch (e) {
    return createGenericErrorObject(e);
  }
};

export const getPANetworkTelemetryData = async (payload, filters) => {
  try {
    const url = filters.feature.value === webexGroup ? apiConstant.SAAS_APP_CXP_VIEW_WEBEX_AGGREGATE : apiConstant.SAAS_APP_CXP_VIEW_O365_AGGREGATE;
    return await axios.post(
      url,
      payload
    );
  } catch (e) {
    return createGenericErrorObject(e);
  }
};

export const getPAMediaData = async (payload) => {
  try {
    return await axios.post(
      apiConstant.WEBEX_PATH_ANALYTICS_CHART,
      payload
    );
  } catch (e) {
    return createGenericErrorObject(e);
  }
};
