export const init = selectedTab => selectedTab;

export const reducer = (state, action) => {
  let switchState = state;

  switch (action.type) {
    case "sites":
      switchState = "sites";
      break;

    case "appFlow":
      switchState = "appFlow";
      break;

    case "topUsers":
      switchState = "topUsers";
      break;

    case "pathScore":
      switchState = "pathScore";
      break;

    case "partnerDataExchange":
      switchState = "partnerDataExchange";
      break;

    case "edgeDataExchange":
      switchState = "edgeDataExchange";
      break;

    default:
      switchState = "sites";
  }
  return switchState;
};
