import i18nMessageBundle from "amdi18n-loader!../../nls/i18n";
import moment from "moment";
export const getColumns = [
  {
    data: "entry_time",
    title: i18nMessageBundle.partnerDataExchangeColumnDate,
    searchable: false,
    searchPopover: false,
    render: data => {
      if (data) {
        return moment(new Date(data)).format("DD MMM yy h:mm:ss A");
      }
    }
  },
  {
    data: "direction",
    title: i18nMessageBundle.partnerDataExchangeColumnDirection,
    searchable: true,
    searchPopover: true
  },
  {
    data: "status",
    title: i18nMessageBundle.partnerDataExchangeColumnStatus,
    searchable: true,
    searchPopover: true,
    render: data => {
      return (
        `<i class="dnac-icon-check-circle-filled-sm" style="color: rgb(0, 171, 80);"></i>` +
        " " +
        data
      );
    }
  },
  {
    data: "message",
    title: i18nMessageBundle.partnerDataExchangeColumnMessage,
    searchable: true,
    searchPopover: true
  },
  {
    data: "filename",
    title: i18nMessageBundle.partnerDataExchangeColumnFileName,
    searchable: true,
    searchPopover: true
  }
];
