import i18nMessageBundle from "amdi18n-loader!../../nls/i18n";
import moment from "moment";

export const getColumns = [
  {
    data: "entry_time",
    title: i18nMessageBundle.app360PathScoreColumnPublishedDate,
    searchable: false,
    searchPopover: false,
    render: data => {
      if (data) {
        return moment(new Date(data)).format("DD MMM yy h:mm:ss A");
      }
    },
    createdCell: (td, cellData, rowData) => {
      if (!rowData.entry_time && !rowData.site_id) {
        td.classList.add("group-cell");
      }
    }
  },
  {
    data: "site_id",
    title: i18nMessageBundle.app360PathScoreColumnSiteID,
    searchable: true,
    searchPopover: true,
    createdCell: (td, cellData, rowData) => {
      if (!rowData.entry_time && !rowData.site_id) {
        td.classList.add("group-cell");
      }
    }
  },
  {
    data: "vdevice_id",
    title: i18nMessageBundle.app360PathScoreColumnSystemIP,
    searchable: true,
    searchPopover: true,
    createdCell: (td, cellData, rowData) => {
      if (!rowData.entry_time && !rowData.site_id) {
        td.classList.add("group-cell");
      }
    }
  },
  {
    data: "device_host_name",
    title: i18nMessageBundle.app360PathScoreColumnHostname,
    searchable: true,
    searchPopover: true,
    createdCell: (td, cellData, rowData) => {
      if (!rowData.entry_time && !rowData.site_id) {
        td.classList.add("group-cell");
      }
    }
  },
  {
    data: "interface",
    title: i18nMessageBundle.app360PathScoreColumnInterface,
    searchable: true,
    searchPopover: true,
    createdCell: (td, cellData, rowData) => {
      if (!rowData.entry_time && !rowData.site_id) {
        td.classList.add("group-cell");
      }
    }
  },
  {
    data: "service_area",
    title: i18nMessageBundle.app360PathScoreColumnService,
    searchable: true,
    searchPopover: true,
    createdCell: (td, cellData, rowData) => {
      if (!rowData.entry_time && !rowData.site_id) {
        td.classList.add("group-cell");
      }
    }
  },
  {
    data: "msft_user_qoe_score",
    title: i18nMessageBundle.app360PathScoreColumnReceived,
    searchable: true,
    searchPopover: true,
    render: data => {
      const element =
        data === "NOOPINION"
          ? `<i class="dnac-icon-hide-field"> </i>`
          : data === "OK"
          ? `<i class="dnac-icon-check-circle-filled-sm" style="color: rgb(0, 171, 80);"></i>`
          : "";
      return element + " " + data;
    },
    createdCell: (td, cellData, rowData) => {
      if (!rowData.entry_time && !rowData.site_id) {
        td.classList.add("group-cell");
      }
    }
  },
  {
    data: "status",
    title: i18nMessageBundle.app360PathScoreColumnComputed,
    searchable: true,
    searchPopover: true,
    render: data => {
      const element =
        data === "INIT"
          ? `<i class="dnac-icon-status-warning-filled" style="color: rgb(252, 157, 3);"></i>`
          : data === "OK"
          ? `<i class="dnac-icon-check-circle-filled-sm" style="color: rgb(0, 171, 80);"></i>`
          : data === "NOT-OK"
          ? `<i class="dnac-icon-x-circle-filled-sm" style="color: rgb(212, 55, 28);"></i>`
          : "";
      return element + " " + data;
    },
    createdCell: (td, cellData, rowData) => {
      if (!rowData.entry_time && !rowData.site_id) {
        td.classList.add("group-cell");
      }
    }
  },
  {
    data: "msft_latency_anomaly",
    title: i18nMessageBundle.app360PathScoreColumnMicrosoft,
    searchable: true,
    searchPopover: true,
    render: data => {
      const element =
        data === "no"
          ? `<i class="dnac-icon-check-circle-filled-sm" style="color: rgb(0, 171, 80);"></i>`
          : data === "yes"
          ? `<i class="dnac-icon-x-circle-filled-sm" style="color: rgb(212, 55, 28);"></i>`
          : "";
      return element + " " + data;
    },
    createdCell: (td, cellData, rowData) => {
      if (!rowData.entry_time && !rowData.site_id) {
        td.classList.add("group-cell");
      }
    }
  },
  {
    data: "artcxp_cnd_anomaly",
    title: i18nMessageBundle.app360PathScoreCND,
    searchable: true,
    searchPopover: true,
    createdCell: (td, cellData, rowData) => {
      if (!rowData.entry_time && !rowData.site_id) {
        td.classList.add("group-cell");
      }
    }
  },
  {
    data: "artcxp_snd_anomaly",
    title: i18nMessageBundle.app360PathScoreSND,
    searchable: true,
    searchPopover: true,
    createdCell: (td, cellData, rowData) => {
      if (!rowData.entry_time && !rowData.site_id) {
        td.classList.add("group-cell");
      }
    }
  }
];

export const rowGroup = ["entry_time", "site_id"];
export const columnGroup = "entry_time";
