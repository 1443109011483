import React, { useMemo } from "react";
import { PropTypes } from "prop-types";
import Spinner from "../../../../../../common/Spinner";
import ErrorComponent from "../../../../../../common/ErrorComponent";
import NoDataAvailable from "../../../../../../common/NoDataAvailable";
import GanttChart from "../../../../../../common/GanttChart";
import ChartLegend from "../../../../../../common/ChartLegend";
import utils from "../../../utils";
import { chartConfig } from "../ChartConfig";
import i18n from "amdi18n-loader!../../../../../nls/i18n";
import { colors, statusColors } from "../../../../../../utils/colors";
import { defaultTextValue } from "../../../../../../utils/enums";

const sdwanLabel = chartConfig.appScore.data[0].label;
const sdwanScoreField = chartConfig.appScore.data[0].fields.score;
const msftLabel = chartConfig.appScore.data[1].label;
const msftScore = chartConfig.appScore.data[1].fields.value;

const sdwanLegends = [
  {
    label: i18n.app360PathAnalytics.scoreGood,
    color: statusColors.good
  },
  {
    label: i18n.app360PathAnalytics.scoreFair,
    color: statusColors.poor
  },
  {
    label: i18n.noData,
    color: statusColors.gray
  }
];
const msftLegends = [
  {
    label: i18n.app360PathAnalytics.appScoreQoeStatusGood,
    color: statusColors.good
  },
  {
    label: i18n.app360PathAnalytics.appScoreQoeStatusDegraged,
    color: statusColors.fair
  },
  {
    label: i18n.app360PathAnalytics.appScoreQoeStatusBad,
    color: statusColors.poor
  },
  {
    label: i18n.app360PathAnalytics.appScoreQoeStatusNoOpinion,
    color: statusColors.gray
  }
];

const AppScoreChart = props => {
  const getTooltip = data => {
    const _arr = [];
    const _id = data.id && data.id.toUpperCase();
    if (_id === "SDWAN") {
      sdWanData.forEach((item, i) => {
        if (item.fromDate === data.fromDate && item.toDate === data.toDate) {
          const _tempStr = `${sdWanData[i].interface} Score: ${getStatusName(
            sdWanData[i][sdwanScoreField]
          )}`;
          _arr.push(`<div>${_tempStr}</div>`);
        }
      });
    }
    if (_id === "MSFT") {
      msftData.forEach((item, i) => {
        if (item.fromDate === data.fromDate && item.toDate === data.toDate) {
          const _tempStr = `${msftData[i].interface} Score: ${getQoeStatusName(
            msftData[i][msftScore]
          )}`;
          _arr.push(`<div>${_tempStr}</div>`);
        }
      });
    }

    if (_arr.length > 0) return `<div>${_arr.join("")}</div>`;
    return "";
  };

  const sdWanData = useMemo(() => orgData(props.data, "SDWAN"), [props.data]);
  const msftData = useMemo(() => orgData(props.data, "MSFT"), [props.data]);
  const chartHeight = useMemo(() => {
    let interfaceCount = Object.keys(props.data).length;
    if (interfaceCount < 2) interfaceCount = 2;
    return 90 + (interfaceCount * 27);
  }, [props.data]);

  return (
    <div className="section-container">
      <div className="hbr-type-h2 app360-section-title">
        {chartConfig.appScore.title}
      </div>

      <hbr-accordion open size="large" triggerposition="right">
        <div slot="label" className="width-full">
          <div className="hbr-type-body2">{sdwanLabel}</div>
        </div>
        <div style={{ height: `${chartHeight}px` }}>
          {props.loader === true ? (
            <Spinner />
          ) : props.error !== null ? (
            <ErrorComponent
              {...props.error}
              width={`${chartHeight - 73}px`}
              className="one-line-dashlet-error"
            />
          ) : sdWanData.length === 0 ? (
            <div className="flex-column-full no-data-flex">
              <NoDataAvailable />
            </div>
          ) : (
            <>
              <div style={{ height: `${chartHeight - 30}px` }}>
                <GanttChart
                  id="app360_at_site_msft_sdwan"
                  label={sdwanLabel}
                  disableYAxisLabel
                  seriesHeight={10}
                  customized={false}
                  calcBaseIterval
                  data={sdWanData}
                  chartHeight={chartHeight - 30}
                  min={props.timePeriod[0]}
                  max={props.timePeriod[1]}
                  chartsData={props.chartsData}
                  callbackFromParent={props.created}
                  globalToolTipsFn={getTooltip}
                  hoverCallback={props.hoverCallback}
                />
              </div>
              <ChartLegend
                data={sdwanLegends}
                includeQuality={false}
              />
            </>
          )}
        </div>
      </hbr-accordion>

      <hbr-accordion open size="large" triggerposition="right">
        <div slot="label" className="width-full">
          <div className="hbr-type-body2">{msftLabel}</div>
        </div>
        <div style={{ height: `${chartHeight}px` }}>
          {props.loader === true ? (
            <Spinner />
          ) : props.error !== null ? (
            <ErrorComponent
              {...props.error}
              width={`${chartHeight - 73}px`}
              className="one-line-dashlet-error"
            />
          ) : msftData.length === 0 ? (
            <div className="flex-column-full no-data-flex">
              <NoDataAvailable />
            </div>
          ) : (
            <>
              <div style={{ height: `${chartHeight - 30}px` }}>
                <GanttChart
                  id="app360_at_site_msft"
                  label={msftLabel}
                  disableYAxisLabel
                  seriesHeight={10}
                  customized={false}
                  calcBaseIterval
                  data={msftData}
                  chartHeight={chartHeight - 30}
                  min={props.timePeriod[0]}
                  max={props.timePeriod[1]}
                  chartsData={props.chartsData}
                  callbackFromParent={props.created}
                  globalToolTipsFn={(dataItem, index) => {
                    return getTooltip(dataItem, index);
                  }}
                  hoverCallback={props.hoverCallback}
                />
              </div>
              <ChartLegend
                data={msftLegends}
                includeQuality={false}
              />
            </>
          )}
        </div>
      </hbr-accordion>
    </div >
  );
};

AppScoreChart.propTypes = {
  filters: PropTypes.object,
  timePeriod: PropTypes.arrayOf(PropTypes.number),
  loader: PropTypes.bool.isRequired,
  error: PropTypes.object,
  data: PropTypes.object.isRequired,
  created: PropTypes.func,
  chartsData: PropTypes.arrayOf(PropTypes.object),
  hoverCallback: PropTypes.func
};

const getColor = (dataItem, chartType) => {
  if (chartType === "SDWAN") {
    const _scoreVal = dataItem[sdwanScoreField];
    if (_scoreVal || _scoreVal === 0) {
      const _score = parseFloat(_scoreVal);
      if (_score > 50) {
        return statusColors.good;
      } else if (_score <= 50 && _score > 0) {
        return statusColors.poor;
      } else if (_score == 0) {
        return statusColors.gray;
      }
    }
    return colors.gray100;
  }
  if (chartType === "MSFT") {
    const msftScoreVal = dataItem[msftScore];
    if (msftScoreVal === "OK") {
      return statusColors.good;
    } else if (msftScoreVal === "DEGRADED") {
      return statusColors.fair;
    } else if (msftScoreVal === "BAD") {
      return statusColors.poor;
    } else if (msftScoreVal === "NOOPINION") {
      return statusColors.gray;
    }
    return colors.gray100;
  }
};

const getStatusName = scoreVal => {
  if (scoreVal || scoreVal === 0) {
    return scoreVal;
  }
  return defaultTextValue;
};

const getQoeStatusName = val => {
  const _val = val.toUpperCase();
  if (_val === "OK") {
    return i18n.app360PathAnalytics.appScoreQoeStatusGood;
  }
  if (_val === "BAD") {
    return i18n.app360PathAnalytics.appScoreQoeStatusBad;
  }
  if (_val === "DEGRADED") {
    return i18n.app360PathAnalytics.appScoreQoeStatusDegraged;
  }
  if (_val === "NOOPINION") {
    return i18n.app360PathAnalytics.appScoreQoeStatusNoOpinion;
  }
  return defaultTextValue;
};

const orgData = (chartData, type) => {
  const newData = [];
  Object.keys(chartData).forEach(interfaceName => {
    const step = utils.getMinStep(chartData[interfaceName]);
    chartData[interfaceName].forEach(item => {
      const result = { ...item };
      result.fromDate = item.entry_time - step;
      result.toDate = item.entry_time;
      result.color = getColor(item, type);
      result.name = item.interface;
      result.id = type;
      newData.push(result);
    });
  });
  return newData;
};

export default AppScoreChart;
