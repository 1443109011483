import React, { useEffect } from "react";
import { PropTypes } from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import reduxContext from "../reducer";
import AppHOC from "../../../generics/AppHOC";
import Spinner from "../../../common/Spinner";
import TopUsersTable from "./TopUsersTable";
import { isCustom } from "../../../utils/common";
import * as actions from "../actions";

const TopUsers = props => {
  const {
    timeFilter,
    selectedSite,
    timePeriodSelected,
    selectedCustomTime,
    currentTimeStamp
  } = props.globalFilter;
  const { getTopUsersData, setTopUsersTableLoading } = props.actions;

  useEffect(() => {
    if (!isCustom(timePeriodSelected) || selectedCustomTime) {
      setTopUsersTableLoading();
      getTopUsersData(timeFilter, props.match.params.appName, selectedSite);
    }
  }, [
    timeFilter.current_period[0],
    timeFilter.current_period[1],
    selectedSite,
    currentTimeStamp
  ]);
  
  return (
    <div data-cy="app360_top_clients">
      {props.app360.top_users_loader ? (
        <Spinner />
      ) : (
        <TopUsersTable tableId="topusers" data={props} />
      )}
    </div>
  );
};

TopUsers.propTypes = {
  globalFilter: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  app360: PropTypes.object.isRequired
};

const mapStateToProps = ({ vanalytics: { app360 } }) => ({ app360 });
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

export default reduxContext.withProvider(
  connect(mapStateToProps, mapDispatchToProps)(AppHOC(TopUsers))
);
