import React from "react";
import css from "./style.less";
import PartnerDataExchangeTable from "./PartnerDataExchangeTable";

const PartnerDataExchange = () => {
  return (
    <div className={css["pde-table"]} data-cy="pdeTableView">
      <PartnerDataExchangeTable
        tableId="applicationList"
        rowSelect={false}
      ></PartnerDataExchangeTable>
    </div>
  );
};

export default PartnerDataExchange;
