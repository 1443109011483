const setGanttChartTooltipStyle = (item, tooltipText) => {
  if (item && item.tooltip && item.isRendered) {
    item.tooltip.isActive = true;
    item.tooltipHTML = tooltipText;
  }
};

const findNearValue = (currentTime, item) => {
  let result;
  let currentData;
  let halfInterval = item.intervalMiliSecs / 2;
  if (item.data) {
    for (let i = 0; i < item.data.length; i++) {
      const ts = new Date(item.data[i]["date"]).getTime();
      const __ms = currentTime - halfInterval / 3;
      if (__ms < ts + halfInterval && __ms > ts - halfInterval) {
        currentData = item.data[i];
        break;
      }
    }
  }
  if (currentData) {
    const keys = Object.keys(currentData);
    keys.forEach(item => {
      if (item.indexOf("value") > -1 && Number.isFinite(currentData[item])) {
        result = currentData;
      }
    });
  }
  return result;
};

const findGanttTooltipText = (chartIns, ms) => {
  let tooltipText;
  let cT = ms;
  for (let i = 0; i < chartIns.data.length; i++) {
    const tsFrom = new Date(chartIns.data[i]["fromDate"]).getTime();
    const tsTo = new Date(chartIns.data[i]["toDate"]).getTime();
    if (cT <= tsTo && cT > tsFrom) {
      tooltipText = chartIns.toolTipFn(chartIns.data[i], i);
      break;
    }
  }
  return tooltipText;
};

const getCurrentTimestamp = (ev, chartIns, type) => {
  let xAxis = ev.target.chart.xAxes.getIndex(0);
  let ms = new Date(
    xAxis.positionToDate(xAxis.toAxisPosition(ev.target.xPosition))
  ).getTime();

  if (type || chartIns.showTipsAlways) {
    return ms;
  }

  let curTs = ms;
  let halfInterval = chartIns.intervalMiliSecs / 2;
  if (chartIns.chartType === "ganttChart") {
    let cT = ms;
    for (let i = 0; i < chartIns.data.length; i++) {
      const ts = new Date(chartIns.data[i]["entry_time"]).getTime();
      const tsFrom = new Date(chartIns.data[i]["fromDate"]).getTime();
      const tsTo = new Date(chartIns.data[i]["toDate"]).getTime();

      if (cT <= tsTo && cT > tsFrom) {
        curTs = ts;
        break;
      }
    }
  }

  if (chartIns.chartType === "multiLineChart") {
    for (let i = 0; i < chartIns.data.length; i++) {
      const ts = new Date(chartIns.data[i]["date"]).getTime();
      const __ms = ms - halfInterval / 3;
      if (__ms <= ts + halfInterval && __ms > ts - halfInterval) {
        curTs = ts;
        break;
      }
    }
  }
  return curTs;
};

const hoverChartsOnO365View = (ev, currentChartIns, chartIns) => {
  let _ms = getCurrentTimestamp(ev, currentChartIns);

  chartIns.forEach((item, index) => {
    let ms = _ms;
    if (item.chartType === "ganttChart") {
      let tooltipText = "";
      if (item.showTipsAlways) {
        if (item.data && item.data.length) {
          ms = getCurrentTimestamp(ev, currentChartIns, true);
          tooltipText = item.toolTipFn(ms);
        } else {
          tooltipText = "";
        }
      } else {
        tooltipText = findGanttTooltipText(item, ms);
      }
      if (tooltipText) {
        const tooltipX =
          ((ms - item.xAxes._values[0]._minZoomed) /
            (item.xAxes._values[0]._maxZoomed -
              item.xAxes._values[0]._minZoomed)) *
          item.contentWidth;
        item.tooltipX = tooltipX;
        item.tooltipY = ev.target.point.y;
        if (tooltipX < ev.target.contentWidth / 2) {
          item.tooltip.pointerOrientation = "left";
        } else {
          item.tooltip.pointerOrientation = "right";
        }
        if (item._disposers !== null) {
          setGanttChartTooltipStyle(item, tooltipText);
        }
      } else {
        item.tooltipHTML = "";
      }
    }

    if (item.chartType === "multiLineChart") {
      const leftGap = item.leftAxesContainer.contentWidth;
      const currentData = findNearValue(ms, item);
      if (currentData && item._disposers !== null) {
        const tooltipX =
          ((ms - item.xAxes._values[0]._minZoomed) /
            (item.xAxes._values[0]._maxZoomed -
              item.xAxes._values[0]._minZoomed)) *
            item.plotContainer.contentWidth +
          leftGap;
        item.tooltipX = tooltipX;
        item.tooltipY = 50;
        item.tooltip.isActive = true;
        item.tooltipHTML = item.toolTipFn(currentData, index);

        if (tooltipX < ev.target.contentWidth / 2) {
          item.tooltip.pointerOrientation = "left";
        } else {
          item.tooltip.pointerOrientation = "right";
        }
      } else {
        item.tooltipHTML = "";
      }
    }
  });
};

const hoverMultilineChart = (ev, currentChartIns, chartIns) => {
  let _ms = getCurrentTimestamp(ev, currentChartIns);
  chartIns.forEach((item, index) => {
    let ms = _ms;
    if (item.chartType === "ganttChart") {
      let tooltipText = "";
      if (item.showTipsAlways) {
        if (item.data && item.data.length) {
          ms = getCurrentTimestamp(ev, currentChartIns, true);
          tooltipText = item.toolTipFn(ms);
        } else {
          tooltipText = "";
        }
      } else {
        tooltipText = findGanttTooltipText(item, ms);
      }
      if (tooltipText) {
        const tooltipX =
          ((ms - item.xAxes._values[0]._minZoomed) /
            (item.xAxes._values[0]._maxZoomed -
              item.xAxes._values[0]._minZoomed)) *
          item.contentWidth;
        item.tooltipX = tooltipX;
        item.tooltipY = 20;
        if (tooltipX < ev.target.contentWidth / 2) {
          item.tooltip.pointerOrientation = "left";
        } else {
          item.tooltip.pointerOrientation = "right";
        }
        if (item._disposers !== null) {
          setGanttChartTooltipStyle(item, tooltipText);
        }
      } else {
        item.tooltipHTML = "";
      }
    }
    if (item.chartType === "multiLineChart") {
      let leftGap = item.leftAxesContainer.contentWidth;
      const currentData = findNearValue(ms, item);
      if (currentData) {
        const tooltipX =
          ((ms - item.xAxes._values[0]._minZoomed) /
            (item.xAxes._values[0]._maxZoomed -
              item.xAxes._values[0]._minZoomed)) *
            item.plotContainer.contentWidth +
          leftGap;
        if (item._disposers !== null) {
          item.tooltipX = tooltipX;
          item.tooltipY = ev.target.point.y;
          item.tooltip.isActive = true;
          if (tooltipX < ev.target.contentWidth / 2) {
            item.tooltip.pointerOrientation = "left";
          } else {
            item.tooltip.pointerOrientation = "right";
          }
          item.tooltipHTML = item.toolTipFn(currentData, index);
        }
      } else {
        item.tooltipHTML = "";
      }
    }
  });
};

const hoverOutFromMultiLineChart = chartIns => {
  if (chartIns.length) {
    chartIns.forEach(item => {
      if (item.isRendered && item._disposers !== null) {
        if (item && item.tooltip && item.chartType === "ganttChart") {
          item.tooltip.isActive = false;
          item.tooltipHTML = "";
        }
        if (item && item.tooltip && item.chartType === "multiLineChart") {
          if (item.cursor && item.cursor.tooltip) {
            item.cursor.tooltip.isActive = false;
            item.cursor.tooltipHTML = "";
          }

          item.tooltip.isActive = false;
          item.tooltipHTML = "";
        }
      }
    });
  }
};

const getLineSeries = data => {
  if (Object.keys(data).length > 0) {
    return Object.keys(data).map((item, index) => ({
      name: item,
      field: "value" + index
    }));
  }
  return undefined;
};
const getAvearage = (dataKey, data) => {
  let totalLength = 0;
  let total = 0;
  Object.keys(data)
    .sort()
    .forEach(deviceInterface => {
      data[deviceInterface].forEach(item => {
        if (item[dataKey]) {
          totalLength++;
          total += item[dataKey];
        }
      });
    });
  if (total === 0 || totalLength === 0) {
    return "";
  }
  return (total / totalLength).toFixed();
};
const getCategoryData = (category, lineSeries, data) => {
  const categoryData = {};
  category.forEach(item => {
    categoryData[item.key] = [];
    Object.keys(data).forEach((i, dex) => {
      if (categoryData[item.key].length === 0) {
        categoryData[item.key] = data[i].map(d => {
          const obj = {
            date: d.entry_time
          };
          if (Number.isFinite(d[item.key])) {
            obj[lineSeries[dex].field] = d[item.key];
            obj[lineSeries[dex].field + "_baseline"] =
              d[item.key + "_baseline"];
          }
          return obj;
        });
      } else {
        categoryData[item.key].forEach(c => {
          const currentEvent = data[i].find(d => d.entry_time === c.date);
          if (currentEvent && currentEvent[item.key]) {
            c[lineSeries[dex].field] = currentEvent[item.key];
            c[lineSeries[dex].field + "_baseline"] =
              currentEvent[item.key + "_baseline"];
          }
        });
      }
    });
  });
  return categoryData;
};

const getChartHeight = categoryCount => {
  return 60 + (categoryCount === 1 ? 2 : categoryCount) * 27;
};

const getMinStep = chartData => {
  let step = 0;
  chartData.forEach((item, dex, self) => {
    if (dex < self.length - 1) {
      const newStep = item.entry_time - self[dex + 1].entry_time;
      if (step === 0) {
        step = newStep;
      } else {
        step = newStep < step ? newStep : step;
      }
    }
  });
  return step;
};

export const convertMediaTransportNames = name => {
  let formatedName = "";
  if (name.includes("_")) {
    let namesList = name.replace("_", " ").split(" ");
    namesList.map(nl => {
      formatedName =
        formatedName + " " + nl.substr(0, 1).toUpperCase() + nl.substr(1);
    });
  } else {
    formatedName = name.substr(0, 1).toUpperCase() + name.substr(1);
  }
  return formatedName;
};

export default {
  getLineSeries,
  getAvearage,
  getCategoryData,
  getChartHeight,
  getMinStep,
  hoverChartsOnO365View,
  hoverMultilineChart,
  hoverOutFromMultiLineChart
};
