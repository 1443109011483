import React from "react";
import PathScoreTable from "./pathScoreTable";
import css from "./style.less";
const PathScore = () => {
  return (
    <div className={css["path-score-table"]} data-cy="pathScoreTableView">
      <PathScoreTable
        tableId="applicationList"
        rowSelect={false}
      ></PathScoreTable>
    </div>
  );
};

export default PathScore;
