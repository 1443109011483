import { formatSizeUnitsRound, capitalizeString } from "../../../utils/format";
import { colors } from "../../../utils/colors";

export const getSankeyConfigData = (data, linkValue, color, index) => {
  let sankeyChartData = [
    {
      from: capitalizeString(
        data.local_site_id + "*duplicate*" + "local_site_id"
      ),
      to: capitalizeString(data.local_color + "*duplicate*" + "local_color"),
      tooltipValue: formatSizeUnitsRound(data.usage, true),
      value: linkValue,
      width: 10,
      color: colors.blue70,
      linkID: index,
      linkColor: colors.blue70,
      opacity: "0.1"
    },
    {
      from: capitalizeString(data.local_color + "*duplicate*" + "local_color"),
      to: capitalizeString(data.qoe),
      tooltipValue: formatSizeUnitsRound(data.usage, true),
      value: linkValue,
      width: 10,
      color: color,
      linkID: index,
      linkColor: colors.blue70,
      opacity: "0.1"
    },
    {
      from: capitalizeString(data.qoe),
      to:
        data.remote_color.toLowerCase() == "undefined"
          ? "noLabel" + "*duplicate*" + capitalizeString(data.qoe)
          : capitalizeString(
              data.remote_color + "*duplicate*" + "remote_color"
            ),
      tooltipValue: formatSizeUnitsRound(data.usage, true),
      value: linkValue,
      width: data.remote_color.toLowerCase() == "undefined" ? 0 : 10,
      color: colors.blue70,
      linkID: index,
      linkColor: colors.blue70,
      opacity: "0.1"
    }
  ];
  return sankeyChartData;
};

export const sankeyKeys = [
  "local_site_id",
  "local_color",
  "qoe",
  "remote_color",
  "remote_site_id"
];
