import React, { useRef } from "react";
import { PropTypes } from "prop-types";

import { getColumns } from "./tableConfig";
import { getO365EdgeDataExchange } from "./getEdgeDataExchange";
import { DtableWrapper } from "dnx-web-components/dist/index-with-deps-react.min";
import table from "dnx-web-components/dist/table.min";
import { formatRequestPayload, prepareQuery } from "../../../utils/query-utils";
import { edgeDataExchange } from "../../../config/reqConfig/app360";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import reduxContext from "../reducer";
import * as actions from "../actions";
import AppHoc from "../../../generics/AppHOC";

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

const EdgeDataExchangeTable = props => {
  const { globalFilter } = props;
  const DnxTable = DtableWrapper(table);
  const tableRef = useRef();

  const getTableProps = () => {
    const columns = getColumns;
    const tableConfig = {
      columns: columns,
      dataTableClasses: "nowrap",
      columnSearch: true,
      columnSearchEnabled: true,
      serverSide: true,
      getData: getData,
      dtOptions: {
        scrollY: 400,
        scrollX: true,
        order: [[0, "desc"]]
      }
    };
    return tableConfig;
  };

  const getData = async aoData => {
    let dTable = null;
    if (tableRef.current) {
      dTable = tableRef.current.table;
    }

    let defaultRequest = JSON.parse(JSON.stringify(edgeDataExchange));

    // prepareQuery for time filter
    defaultRequest = prepareQuery(defaultRequest, globalFilter);

    // get request payload
    const requestPayload = formatRequestPayload(defaultRequest, aoData, dTable);
    return new Promise(resolve => {
      getO365EdgeDataExchange(requestPayload).then(response => {
        let success = response.data.data;
        const count = response.data.header.counts;
        resolve({
          response: success,
          count: count
        });
      });
    });
  };

  let tableProps = getTableProps();
  return (
    <div className="ede-table" data-cy="edgeDataExchangeView">
      <div
        className="dnx--css_table-container"
        style={{
          maxWidth: "100vw"
        }}
      >
        <DnxTable {...tableProps} ref={tableRef} />
      </div>
    </div>
  );
};

EdgeDataExchangeTable.propTypes = {
  globalFilter: PropTypes.object.isRequired
};

export default reduxContext.withProvider(
  connect(null, mapDispatchToProps)(AppHoc(EdgeDataExchangeTable))
);
