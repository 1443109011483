import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { GanttChart } from "../../../../common/GanttChart";
import { displayDateTime } from "../../../../utils/displayTime";
import utils from "../utils";
import { chartPrimaryColors } from "../../../../utils/colors";

const Chart = props => {
  const [pathData, setPathData] = useState();
  const [interfaces, setInterfaces] = useState([]);

  useEffect(() => {
    if (props.data) {
      const _interfaces = Object.keys(props.data);
      let parsedData = [];
      let colorCount = 0;
      if (_interfaces) {
        _interfaces.forEach(name => {
          if (props.data[name].length > 0) {
            const chartColor =
              chartPrimaryColors[colorCount++ % chartPrimaryColors.length];
            props.data[name].sort((a, b) => a.fromDate - b.fromDate);
            const temp = props.data[name].map(item => {
              item.name =
                item.interface !== undefined ? item.interface : item.name;
              item.color = chartColor;
              return item;
            });
            parsedData = parsedData.concat(temp);
          }
        });
        setInterfaces(_interfaces);
      } else setInterfaces([]);
      setPathData(parsedData);
    }
  }, [props.data]);

  const getTooltip = data => `
    <div class="ttip-header">
      <span class="date">${displayDateTime(data)}</span>
    </div>
  `;

  return (
    <div className="width-full" data-cy="pathChart">
      {pathData && (
        <GanttChart
          id="app360_at_site_path"
          label={props.title}
          disableYAxisLabel
          seriesHeight={8}
          showTipsAlways
          calcBaseIterval
          data={pathData}
          chartHeight={utils.getChartHeight(interfaces.length)}
          min={props.timePeriod[0]}
          max={props.timePeriod[1]}
          chartsData={props.chartsData}
          callbackFromParent={props.created}
          globalToolTipsFn={getTooltip}
          hoverCallback={props.hoverCallback}
        />
      )}
    </div>
  );
};

Chart.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object,
  timePeriod: PropTypes.arrayOf(PropTypes.number),
  created: PropTypes.func,
  chartsData: PropTypes.arrayOf(PropTypes.object),
  hoverCallback: PropTypes.func
};

export default Chart;
