import React from "react";
import { PropTypes } from "prop-types";

import reactWrapper from "@harbor/elements/utils/react/wrapper";

const [HbrTabGroup, HbrTab] = reactWrapper(["hbr-tab-group", "hbr-tab"]);
const App360Tabs = ({ tabs, defaultTabValue, dispatch }) => {
  const onTabChange = tab => {
    dispatch({
      type: tab.name
    });
  };
  return (
    <HbrTabGroup size="small">
      {tabs &&
        tabs.length > 0 &&
        tabs.map(tab => (
          <HbrTab
            className="hbr-type-body3"
            slot="nav"
            active={defaultTabValue}
            key={tab.label}
            panel={tab.label}
            onClick={() => onTabChange(tab)}
          >
            {tab.label}
          </HbrTab>
        ))}
    </HbrTabGroup>
  );
};

App360Tabs.propTypes = {
  dispatch: PropTypes.func.isRequired,
  tabs: PropTypes.array,
  defaultTabValue: PropTypes.string
};

export default App360Tabs;
