// eslint no-undef
import React, { useState, useEffect, useRef, useCallback } from "react";
import { PropTypes } from "prop-types";
import Spinner from "../../../../common/Spinner";
import ErrorComponent from "../../../../common/ErrorComponent";
import NoDataAvailable from "../../../../common/NoDataAvailable";
import PathChart from "../PathChart";
import AppScoreChart from "./Charts/AppScore";
import LineCharts from "../LineChartNew";
import paUtils from "../utils";
import { useMount } from "../../../../utils/genericCommon";
import { getTimestampFromUTCString } from "../../../../utils/displayTime";
import {
  getPAMicrosoftData,
  getPANetworkTelemetryData,
  getPAPathData,
  getPAUsageData
} from "../../actions";
import { chartConfig } from "./Charts/ChartConfig";

const O365PathAnalytics = ({ appName, globalFilter, paLoader, filters }) => {
  const [pathLoader, setPathLoader] = useState(true);
  const [msLoader, setMSLoader] = useState(true);
  const [usageLoader, setUsageLoader] = useState(true);
  const [networkLoader, setNetworkLoader] = useState(true);
  const chartsRef = useRef([]);
  const dataRef = useRef({
    path: { timestamp: 0, error: null, noData: true, data: {} },
    ms: { timestamp: 0, error: null, noData: true, data: {} },
    usage: { timestamp: 0, error: null, noData: true, data: {} },
    network: { timestamp: 0, error: null, noData: true, data: {} }
  });
  const mount = useMount();

  const hoverCallback = (ev, curChartIns) => {
    paUtils.hoverChartsOnO365View(
      ev,
      curChartIns,
      chartsRef.current
    );
  };

  const hoverMultiLineCharts = (ev, curChartIns) => {
    paUtils.hoverMultilineChart(ev, curChartIns, chartsRef.current);
  };

  const hoverOutFromMultiLineChart = () => {
    paUtils.hoverOutFromMultiLineChart(chartsRef.current);
  };

  const chartCallback = chartData => {
    chartData.yAxes.values[0].title.disabled = true;
    const chartIndex = chartsRef.current.findIndex(
      item => item.id === chartData.id
    );
    if (chartIndex !== -1) {
      delete chartsRef.current[chartIndex];
      chartsRef.current[chartIndex] = chartData;
    } else chartsRef.current.push(chartData);
  };

  useEffect(() => {
    const timestamp = Date.now();
    Object.assign(
      dataRef.current,
      {
        path: { timestamp, error: null, noData: true, data: {} },
        ms: { timestamp, error: null, noData: true, data: {} },
        usage: { timestamp, error: null, noData: true, data: {} },
        network: { timestamp, error: null, noData: true, data: {} },
      }
    );
    setPathLoader(true);
    setMSLoader(true);
    setUsageLoader(true);
    setNetworkLoader(true);
    if (filters !== null) {
      getPathData(timestamp, globalFilter, filters);
      getMSData(timestamp, globalFilter, filters);
      getUsageData(timestamp, globalFilter, filters);
      getNTData(timestamp, globalFilter, filters);
    }
    return () => {
      for (let index = 0; index < chartsRef.current.length; index++)
        delete chartsRef.current[index];
      chartsRef.current.splice(0);
    };
  }, [filters]);

  const getPathData = useCallback(async (timestamp, gFilter, filterData) => {
    const payload = {
      ...gFilter.globalV4Payload,
      system_ip: filterData.system_ip.value,
      interface: filterData.interfaces.value,
      service_area: filterData.serviceArea.value
    }
    if ("site_id" in payload) {
      delete payload.site_id;
    }
    const res = await getPAPathData(
      payload,
      filterData
    );
    if (
      mount.mounted === true && timestamp === dataRef.current.path.timestamp
    ) {
      if (res.errorObject instanceof Object)
        dataRef.current.path.error = res.errorObject;
      else if (res.data instanceof Object && Array.isArray(res.data.data)) {
        const [min, max] = gFilter.timeFilter.current_period;
        const interfaces = filterData.interfaces.value;
        const pathData =
          Object.fromEntries(interfaces.map(item => [item, []]));
        let noData = true;
        let pathDataArr = res.data.data
          .map((item)=>{
            return {
              fromDate: getTimestampFromUTCString(item.fromDate),
              toDate: getTimestampFromUTCString(item.toDate),
              interface: item.interface
            }
          })
          .sort((item1, item2) => item1.toDate > item2.toDate ? 1 : -1);
          pathDataArr.forEach(item => {
          if (
            item.toDate >= min && item.toDate <= max
            && Array.isArray(pathData[item.interface])
          )
            pathData[item.interface].push(item);
        });
        for (const key in pathData) {
          if (pathData[key].length === 0) delete pathData[key];
          else noData = false;
        }
        Object.assign(dataRef.current.path, { noData, data: pathData });
      }
      setPathLoader(false);
    }
  }, []);

  const getMSData = useCallback(async (timestamp, gFilter, filterData) => {
    const payload = {
      ...gFilter.globalV4Payload,
      site_id:  gFilter.selectedSite,
      service_area: filterData.serviceArea.value,
      interface: filterData.interfaces.value,
      device_host_name: filterData.devices.value,
      size: 10000,
      sort: {
          "entry_ts": "desc"
      }
    }
    const res = await getPAMicrosoftData(
      payload
    );
    if (mount.mounted === true && timestamp === dataRef.current.ms.timestamp) {
      if (res.errorObject instanceof Object)
        dataRef.current.ms.error = res.errorObject;
      else {
        const [min, max] = gFilter.timeFilter.current_period;
        const interfaces = filterData.interfaces.value;
        let originData = null;
        const msData = Object.fromEntries(interfaces.map(item => [item, []]));
        let noData = true;
        if (typeof res.data === "string")
          originData = JSON.parse(res.data.replace(/NaN/g, '""')).data;
        else if (res.data instanceof Object && Array.isArray(res.data.data)) {
          originData = res.data.data.map(item => {
            const newItem = {};
            for (const key in item)
              newItem[key] = item[key] === "NaN" ? "" : item[key];
            return {...newItem, entry_time: getTimestampFromUTCString(item.entry_ts)};
          });
        }
        if (Array.isArray(originData) && originData.length > 0) {
          const dataObj = {};
          const data = [];
          originData.forEach(item => {
            const key = item.entry_time + item.interface;
            if (dataObj[key] !== true) {
              dataObj[key] = true;
              if (item.entry_time >= min && item.entry_time <= max)
                data.push(item);
            }
          });
          data.forEach(item => {
            if (interfaces.includes(item.interface))
              msData[item.interface].push(item);
          });
        }
        for (const item in msData) {
          if (msData[item].length === 0) delete msData[item];
          else noData = false;
        }
        Object.assign(dataRef.current.ms, { noData, data: msData });
      }
      setMSLoader(false);
    }
  }, []);

  const getUsageData = useCallback(async (timestamp, gFilter, filterData) => {
    const payload = {
      ...gFilter.globalV4Payload,
      vdevice_id: filterData.system_ip.value,
      application: appName,
      service_area: filterData.serviceArea.value,
      size: 10000,
      sort: {
          "entry_ts": "desc"
      },
      interface: filterData.interfaces.value,
    }
    if ("site_id" in payload) {
      delete payload.site_id;
    }
    const res = await getPAUsageData(
      payload,
      filterData
    );
    if (
      mount.mounted === true && timestamp === dataRef.current.usage.timestamp
    ) {
      if (res.errorObject instanceof Object)
        dataRef.current.usage.error = res.errorObject;
      else if (res.data instanceof Object && res.data.data instanceof Object) {
        const [min, max] = gFilter.timeFilter.current_period;
        const interfaces = filterData.interfaces.value;
        const usageData =
          Object.fromEntries(interfaces.map(item => [item, []]));
        let noData = true;
        Object.entries(res.data.data).forEach(([key, value]) => {
          if (Array.isArray(usageData[key])) {
            const interfaceData = [];
            value.forEach(item => {
              const entry_ts = getTimestampFromUTCString(item.entry_ts);
              if (entry_ts >= min && entry_ts <= max)
                interfaceData.push({
                  entry_time: entry_ts,
                  volume: item.octets_sum,
                  interface: key
                });
            });
            usageData[key] = interfaceData.sort(
              (item1, item2) => item1.entry_time > item2.entry_time ? 1 : -1
            );
          }
        });
        for (const key in usageData) {
          if (usageData[key].length === 0) delete usageData[key];
          else noData = false;
        }
        Object.assign(dataRef.current.usage, { noData, data: usageData });
      }
      setUsageLoader(false);
    }
  }, []);

  const getNTData = useCallback(async (timestamp, gFilter, filterData) => {
    const payload = {
      ...gFilter.globalV4Payload,
      interface: filterData.interfaces.value,
      local_system_ip: filterData.system_ip.value,
      size: 10000,
      application_group_name: filterData.feature.value,
      sort: {
          entry_ts: "desc",
          entry_datehour: "asc"
      }
    };
    if ("site_id" in payload) {
      delete payload.site_id;
    }
    const res = await getPANetworkTelemetryData(
      payload,
      filterData
    );
    if (
      mount.mounted === true && timestamp === dataRef.current.network.timestamp
    ) {
      if (res.errorObject instanceof Object)
        dataRef.current.network.error = res.errorObject;
      else if (res.data instanceof Object && Array.isArray(res.data.data)) {
        const [min, max] = gFilter.timeFilter.current_period;
        const interfaces = filterData.interfaces.value;
        const networkData =
          Object.fromEntries(interfaces.map(item => [item, []]));
        let noData = true;
        res.data.data.forEach(item => {
          let entry_ts = getTimestampFromUTCString(item.entry_ts);
          if (
            entry_ts >= min && entry_ts <= max && 
            Array.isArray(networkData[item.interface])
          )
            networkData[item.interface].push({
              application_name: filterData.feature.value,
              interface: item.interface,
              vdevice_id: filterData.system_ip.value,
              latency: item.latency,
              loss: item.loss,
              entry_time:
                Math.floor(entry_ts / (1000 * 60 * 60)) * 1000 * 60 * 60
                + item.ten_min_group * 10 * 60 * 1000
            });
        });
        for (const key in networkData) {
          if (networkData[key].length === 0) delete networkData[key];
          else noData = false;
        }
        Object.assign(dataRef.current.network, { noData, data: networkData });
      }
      setNetworkLoader(false);
    }
  }, []);

  return (
    <div>
      <div className="section-container">
        <div className="hbr-type-h3 app360-sub-section-title">
          {chartConfig.path.title}
        </div>
        <div className="pa-chart">
          {paLoader === true || pathLoader === true ? (
            <Spinner />
          ) : dataRef.current.path.error !== null ? (
            <ErrorComponent
              {...dataRef.current.path.error}
              width="80px"
              className="one-line-dashlet-error"
            />
          ) : dataRef.current.path.noData === true ? (
            <div className="flex-column-full no-data-flex">
              <NoDataAvailable />
            </div>
          ) : (
            <PathChart
              title={chartConfig.path.title}
              hoverCallback={hoverCallback}
              created={chartCallback}
              timePeriod={globalFilter.timeFilter.current_period}
              data={dataRef.current.path.data}
              chartsData={chartsRef.current}
            />
          )}
        </div>
      </div>

      <AppScoreChart
        hoverCallback={hoverCallback}
        created={chartCallback}
        timePeriod={globalFilter.timeFilter.current_period}
        loader={paLoader === true || msLoader === true}
        error={dataRef.current.ms.error}
        data={dataRef.current.ms.data}
        chartsData={chartsRef.current}
      />

      <LineCharts
        config={chartConfig.usageLineChart[0]}
        loader={paLoader === true || usageLoader === true}
        {...dataRef.current.usage}
        timePeriod={globalFilter.timeFilter.current_period}
        chartsData={chartsRef.current}
        created={chartCallback}
        hoverMultiLineCharts={hoverMultiLineCharts}
        hoverOutFromMultiLineChart={hoverOutFromMultiLineChart}
      />

      <LineCharts
        config={chartConfig.networkTelemetry}
        loader={paLoader === true || networkLoader === true}
        {...dataRef.current.network}
        timePeriod={globalFilter.timeFilter.current_period}
        chartsData={chartsRef.current}
        created={chartCallback}
        hoverMultiLineCharts={hoverMultiLineCharts}
        hoverOutFromMultiLineChart={hoverOutFromMultiLineChart}
        numberFormat={"#"}
      />

      {chartConfig.LineCharts.map(item => (
        <LineCharts
          key={item.title}
          config={item}
          loader={paLoader === true || msLoader === true}
          {...dataRef.current.ms}
          timePeriod={globalFilter.timeFilter.current_period}
          chartsData={chartsRef.current}
          created={chartCallback}
          hoverMultiLineCharts={hoverMultiLineCharts}
          hoverOutFromMultiLineChart={hoverOutFromMultiLineChart}
          numberFormat={"#"}
        />
      ))}
    </div>
  );
};

O365PathAnalytics.propTypes = {
  appName: PropTypes.string,
  globalFilter: PropTypes.object,
  paLoader: PropTypes.bool.isRequired,
  filters: PropTypes.object
};

export default O365PathAnalytics;
