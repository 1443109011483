import React from "react";
import css from "./style.less";
import EdgeDataExchangeTable from "./EdgeDataExchangeTable";

const EdgeDataExchange = () => {
  return (
    <div className={css["ede-table"]} data-cy="edeTableView">
      <EdgeDataExchangeTable
        tableId="applicationList"
        rowSelect={false}
      ></EdgeDataExchangeTable>
    </div>
  );
};

export default EdgeDataExchange;
