import React, { useState } from "react";
import { ListBullets, MapPin } from "phosphor-react";

import MapComponent from "../MapComponent";
import SiteList from "../SiteList";
import "../app360MagneticStyle.less";
import reactWrapper from "@harbor/elements/utils/react/wrapper";

const [HbrViewSwitcher, HbrRadioButton] = reactWrapper([
  "hbr-view-switcher",
  "hbr-radio-button"
]);

const SitesComponent = () => {
  const TOGGLER = {
    MAP: "map",
    LIST: "list"
  };

  const [toggle, setToggle] = useState(TOGGLER.MAP);

  const handleChange = evt => {
    const swtch = evt.currentTarget;

    setToggle(swtch.value);
  };

  return (
    <div>
      <div className="map-list-toggler">
        <HbrViewSwitcher
          value={toggle}
          name="selected_view"
          onHbr-change={handleChange}
          className="site-map-switch"
        >
          <HbrRadioButton value={TOGGLER.MAP}>
            <MapPin size={20} color="var(--hbr-color-neutral-text-weak)" weight="bold" />
          </HbrRadioButton>
          <HbrRadioButton value={TOGGLER.LIST}>
            <ListBullets size={20} color="var(--hbr-color-neutral-text-weak)" weight="bold" />
          </HbrRadioButton>
        </HbrViewSwitcher>
      </div>

      <div>{toggle === TOGGLER.MAP ? <MapComponent /> : <SiteList />}</div>
    </div>
  );
};

export default SitesComponent;
