import i18n from "amdi18n-loader!../../../nls/i18n";
import { formatSizeUnitsRound } from "../../../../utils/format";
import { convertMediaTransportNames } from "../utils";
import { defaultTextValue } from "../../../../utils/enums";

export const chartConfig = {
  path: {
    title: i18n.app360PathAnalytics.path,
    fields: { time: "entry_time", status: "status" }
  },
  lineCharts: {
    title: i18n.app360PathAnalytics.networkTelemetry,
    data: [
      {
        title: i18n.app360PathAnalytics.latency,
        label: i18n.applicationTableViewLatency,
        fields: { value: "latency" },
        unit: i18n.app360PathAnalytics.ms
      },
      {
        title: i18n.app360PathAnalytics.loss,
        label: i18n.applicationTableViewLoss,
        fields: { value: "loss" },
        unit: "%"
      }
    ]
  },
  usageLineChart: [
    {
      title: i18n.app360PathAnalytics.usage,
      data: [
        {
          title: i18n.app360PathAnalytics.usage,
          label: i18n.app360PathAnalytics.usage,
          fields: { value: "volume" },
          unitFn: formatSizeUnitsRound
        }
      ]
    }
  ]
};

export const webExStaticData = {
  title: defaultTextValue,
  data: [
    {
      title: i18n.app360PathAnalytics.loss,
      label: i18n.applicationTableViewLoss,
      fields: { value: "packet_loss" },
      unit: "%"
    },
    {
      title: i18n.app360PathAnalytics.jitter,
      label: i18n.applicationTableViewJitter,
      fields: { value: "jitter" },
      unit: i18n.app360PathAnalytics.ms
    },
    {
      title: i18n.app360PathAnalytics.latency,
      label: i18n.applicationTableViewLatency,
      fields: { value: "latency" },
      unit: i18n.app360PathAnalytics.ms
    },
    {
      title: "Resolution Height",
      label: "Resolution Height(ppi)",
      fields: { value: "resolution_height" },
      unit: "ppi"
    },
    {
      title: "Frame Rate",
      label: "Frame Rate(fps)",
      fields: { value: "frame_rate" },
      unit: "fps"
    },
    {
      title: "Media Bit Rate",
      label: "Media Bit Rate(bits)",
      fields: { value: "media_bit_rate" },
      unit: "bits"
    }
  ]
};

export const getWebexChartConfig = appInfo => {
  const subApplications = appInfo[0].sub_application;
  const transportTypes = appInfo[0].transport_type;
  const webExChartConfig = {};
  const data = webExStaticData.data;
  if (
    subApplications && Array.isArray(subApplications) &&
    subApplications.length > 0
  ) {
    subApplications.filter(subApp => {
      webExChartConfig[subApp] = { title: convertMediaTransportNames(subApp) };
      if (
        transportTypes && Array.isArray(transportTypes) &&
        transportTypes.length > 0
      ) {
        transportTypes.filter(transType => {
          webExChartConfig[subApp][transType] = {
            title: convertMediaTransportNames(transType),
            data: data
          };
        });
      }
    });
  }
  return webExChartConfig;
};
