import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import { DnxDropdown } from "../../../loaders/DNXLoader";
import Spinner from "../../../common/Spinner";
import ErrorComponent from "../../../common/ErrorComponent";
import NoDataAvailable from "../../../common/NoDataAvailable";
import TopCharts from "./TopCharts";
import AppMetrics from "./AppMetrics";
import SankeyChart from "../../../common/SankeyChart";
import SankeyChartData from "../SynchrozedCharts/SankeyChartData";
import AppFlow from "../AppFlow";
import TopUsers from "../TopUsers";
import PathAnalytics from "../PathAnalytics";
import _ from "lodash";
import { useMount } from "../../../utils/genericCommon";
import {
  compareAndMatchELT,
  getIsHourlyData,
  getTimeInterval
} from "../../../utils/common";
import { formatSizeUnitsRound } from "../../../utils/format";
import {
  getAggregateData,
  getCircuitsData,
  getPAAppFilterData,
  getPAAppInfo
} from "../actions";
import i18n from "amdi18n-loader!../../nls/i18n";
import css from "../app360MagneticStyle.less";
import { chartPrimaryColors } from "../../../utils/colors";
import { defaultCellValue, webexGroup } from "../../../utils/enums";
import { getTimestampFromUTCString } from "../../../utils/displayTime";

const [HbrCard, HbrTabGroup, HbrTab, HbrTabPanel] =
  reactWrapper(["hbr-card", "hbr-tab-group", "hbr-tab", "hbr-tab-panel",]);

const titles = {
  qoeScore: i18n.syncronizedChartQoE,
  circuits: i18n.syncronizedChartCircuits,
  usage: i18n.syncronizedChartBandwidth,
  packetLoss: i18n.syncronizedChartLoss,
  latency: i18n.syncronizedChartLatency,
  jitter: i18n.syncronizedChartJitter
};
const defaultTabs = [
  {
    label: i18n.syncronizedChartAppMetrics,
    value: "app_metric",
    default: true
  },
  { label: i18n.syncronizedChartAppFlow, value: "app_flow" },
  { label: i18n.syncronizedChartTopClients, value: "top_client" }
];
const paTab = {
  label: i18n.app360PathAnalytics.pathAnalytics,
  value: "path_analytics"
};

const Application360AtSite = ({ setPathAnalyticsTab, appName, globalFilter }) => {
  const [customListLoader, setCustomListLoader] = useState(true);
  const [networkLoader, setNetworkLoader] = useState(true);
  const [circuitLoader, setCircuitLoader] = useState(true);
  const [paLoader, setPALoader] = useState(true);
  const [paFilterLoader, setPAFilterLoader] = useState(true);
  const [tab, setTab] = useState(defaultTabs.find(item => item.default).value);
  const [sankeyChartData, setSankeyChartData] = useState([]);
  const [sankeyChartIdData, setSankeyChartIdData] = useState({});
  const [sankeyChartDropdownData, setSankeyChartDropdownData] = useState([]);
  const [showSankeyLoader, setShowSankeyLoader] = useState(false);
  const [showSankeyError, setShowSankeyError] = useState(false);
  const [sankeyError, setSankeyError] = useState(false);
  const [sankeyNoData, setSankeyNoData] = useState(false);
  const [sankeyChartTopSites, setSankeyChartTopSites] = useState([]);
  const [showRemoteSite, setShowRemoteSite] = useState(true);
  const [
    sankeyChartDropdowndefaultData,
    setSankeyChartDropdownDefaultData
  ] = useState([]);
  const [sankeyDropdownValues, setSankeyDropdownValues] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const dataRef = useRef({
    titles,
    firstLoad: true,
    app: null,
    isGray: true,
    timestamp: 0,
    network: { error: null, data: [], qoe: [] },
    circuit: { error: null, circuit: [], usage: [], usageAvg: 0 },
    pa: {
      firstLoad: true,
      show: false,
      isWebex: true,
      timestamp: 0,
      showTab: { error: null, data: {} },
      filter: { init: null, error: null, data: [] }
    },
    circuits: [],
    colorset: {},
    tabs: [...defaultTabs]
  });
  const chartsRef = useRef([]);
  const app_data_with_family_slaRef = useRef(null);
  const mount = useMount();
  const availableFeature =
    JSON.parse(localStorage.getItem("availableFeatures")) || {};

  const chartCallback = chartData => {
    const title = chartData.yAxes.values[0].title;
    if (!chartData.topChartRender) title.disabled = true;
    if (title.text === i18n.syncronizedChartCircuits) title.marginRight = 36;
    const chartIndex = chartsRef.current.findIndex(item => (
      item instanceof Object && item.yAxes.values[0].title.text === title.text
    ));
    if (chartIndex !== -1) {
      delete chartsRef.current[chartIndex];
      chartsRef.current[chartIndex] = chartData;
    } else chartsRef.current.push(chartData);
    if (title.text === i18n.syncronizedChartBandwidth)
      chartData.yAxes.values[0].numberFormatter.numberFormat = "#.b";
  };

  useMemo(() => {
    chartsRef.current.forEach((item, chartIndex) => {
      const titleText = item.yAxes.values[0].title.text;
      if (titleText !== titles.qoeScore && titleText != titles.circuits)
        delete chartsRef.current[chartIndex];
    });
  }, [tab]);

  const getPAInfo = useCallback(async (timestamp, gFilter, show = false) => {
    const payload = {
      ...gFilter.globalV4Payload,
      application: appName
    };
    const res = await getPAAppInfo(payload);
    if (mount.mounted === true && timestamp === dataRef.current.pa.timestamp) {
      if (res.errorObject instanceof Object)
        dataRef.current.pa.showTab.error = res.errorObject;
      else {
        if (res.data instanceof Object)
          dataRef.current.pa.showTab.data = res.data;
        if (
          dataRef.current.pa.show === true
          && dataRef.current.pa.showTab.data.showPathAnalytics !== true
        ) {
          setTab(tab => {
            if (tab === paTab.value)
              return defaultTabs.find(item => item.default).value;
            return tab;
          });
        }
        dataRef.current.pa.show =
          dataRef.current.pa.showTab.data.showPathAnalytics === true;
        if (show === false && dataRef.current.pa.show === true)
          getPAFilter(timestamp, gFilter);
      }
      setPALoader(false);
    }
  }, []);

  const getPAFilter = useCallback(async (timestamp, gFilter) => {
    const feature = dataRef.current.pa.showTab.data.feature;
    const payload = {
      application: appName,
      site_id: gFilter.selectedSite,
      ...gFilter.globalV4Payload
    }
    const res = await getPAAppFilterData(payload, feature);
    if (mount.mounted === true && timestamp === dataRef.current.pa.timestamp) {
      dataRef.current.pa.filter.init = {};
      dataRef.current.pa.firstLoad = false;
      if (res.errorObject instanceof Object)
        dataRef.current.error = res.errorObject;
      else {
        const data = Array.isArray(res.data.data) ? res.data.data : [];
        dataRef.current.pa.filter.data = [];
        if (data.length > 0) {
          const item = data[0];
          const isWebex = feature === webexGroup;
          dataRef.current.pa.isWebex = isWebex;
          if (
            item.device_host_name !== "" && item.interface?.length > 0
            && ((isWebex === true && item.region_name?.length > 0)
              || (isWebex === false && item.service_area?.length > 0))
          ) {
            dataRef.current.pa.filter.data = data.map((item) => { return { ...item, feature: dataRef.current.pa.showTab.data.feature } });
            const initFilter = {
              system_ip: { value: item.vdevice_id },
              devices: { value: item.device_host_name },
              interfaces: { value: item.interface },
              feature: { value: feature }
            };
            if (isWebex) initFilter.serverRegion = { value: item.region_name[0] };
            else initFilter.serviceArea = { value: item.service_area[0] };
            dataRef.current.pa.filter.init = initFilter;
          }
        }
      }
      setPAFilterLoader(false);
    }
  }, []);

  /****if path analytics tab is active then set true in reducer  */
  useEffect(() => {
    if (tab === paTab.value && globalFilter.selectedSite !== -1) {
      setPathAnalyticsTab(true);
    } else {
      setPathAnalyticsTab(false);
    }
  }, [tab, globalFilter.selectedSite]);

  useEffect(() => {
    if (availableFeature.webex === true) {
      const timestamp = Date.now();
      Object.assign(dataRef.current.pa, {
        timestamp,
        showTab: { error: null, data: {} }
      });
      Object.assign(dataRef.current.pa.filter, { error: null, data: [] });
      setPALoader(true);
      setPAFilterLoader(true);
      getPAInfo(timestamp, globalFilter);
    }
    else {
      dataRef.current.pa.show = false;
      dataRef.current.pa.timestamp = 0;
      if (tab === paTab.value)
        setTab(defaultTabs.find(item => item.default).value);
    }
  }, [
    globalFilter.timeFilter.current_period[0],
    globalFilter.timeFilter.current_period[1],
    globalFilter.currentTimeStamp,
    globalFilter.selectedSite,
    availableFeature.webex
  ]);

  const getNetworkData = useCallback(async (timestamp, gFilter) => {
    const res = await getAggregateData(appName, gFilter, gFilter.selectedSite);
    if (mount.mounted === true && timestamp === dataRef.current.timestamp) {
      if (res.errorObject instanceof Object)
        dataRef.current.network.error = res.errorObject;
      else {
        const v4payload = gFilter.globalV4Payload;
        const data = res.data?.data || [];
        const timeInterval = getTimeInterval(v4payload.time_frame);
        for (const item of data)
          item.date = getTimestampFromUTCString(item.entry_ts);
        if (data.length > 0) {
          const newEntries = [];
          const lastIndex = data.length - 1;
          data.forEach((item1, index) => {
            if (index < lastIndex) {
              const item2 = data[index + 1];
              /* add an entry to extend lines till current entry's end range if
            next entry is after an hour */
              if (item2.date > item1.date + timeInterval)
                newEntries.push({ ...item1, date: item1.date + (timeInterval - 1) });
            } else {
              if (getIsHourlyData(v4payload.time_frame))
                compareAndMatchELT(data, gFilter.timeFilter);
              else
              newEntries.push({ ...item1, date: item1.date + (timeInterval - 1) });
            }
          });
          data.push(...newEntries);
        }
        data.sort((item1, item2) => item1.date > item2.date ? 1 : -1);
        dataRef.current.network.data = data;
        dataRef.current.network.qoe =
          data.map(item => ({ date: item.date, value: item.vqoe_score }));
      }
      setNetworkLoader(false);
    }
  }, []);

  const getCircuitData = useCallback(async (gFilter) => {
    const res = await getCircuitsData(
      appName,
      gFilter.globalV4Payload
    );
    if (mount.mounted === true) {
      if (res.errorObject instanceof Object)
        dataRef.current.circuit.error = res.errorObject;
      else {
        const resData = res.data?.data || [];
        const circuitData = [];
        if (resData.length > 0) {
          let usageAvg = 0;
          resData.forEach(circuit => {
            // color list creation for usage and circuit charts
            const circuitIndex = dataRef.current.circuits.indexOf(
              circuit.circuit_name
            );
            const color =
              chartPrimaryColors[
              circuitIndex === -1
                ? dataRef.current.circuits.length
                : circuitIndex
              ];
            if (circuitIndex === -1)
              dataRef.current.circuits.push(circuit.circuit_name);
            dataRef.current.colorset[circuit.circuit_name] = color;
            circuitData.push({
              name: circuit.circuit_name,
              value: circuit.usage,
              fromDate: getTimestampFromUTCString(circuit.start_ts),
              toDate: getTimestampFromUTCString(circuit.end_ts),
              color: color
            });
            usageAvg += circuit.usage;
            if (getIsHourlyData(gFilter.globalV4Payload.time_frame)) {
              compareAndMatchELT(circuitData, gFilter.timeFilter, "end_time", false);
            }
          });
          dataRef.current.circuit.circuit = circuitData;
          const timeInterval = getTimeInterval(gFilter.globalV4Payload.time_frame);
          const entryData = {};
          const circuitNames = dataRef.current.circuits;
          // create entry object from response data
          for (const { fromDate, name, value } of circuitData) {
            if (entryData[fromDate] instanceof Object)
              entryData[fromDate][name] = value;
            else entryData[fromDate] = { date: fromDate, [name]: value };
          }
          // generate graph data from entry array
          const data = Object.values(entryData);
          data.sort((item1, item2) => item1.date > item2.date ? 1 : -1);
          const newEntries = [];
          const lastIndex = data.length - 1;
          data.forEach((entry1, dataIndex) => {
            if (dataIndex < lastIndex) {
              const entry2 = data[dataIndex + 1];
              /* add an entry to extend lines till current entry's end range if
              next entry is after an hour */
              if (entry2.date > entry1.date + timeInterval) {
                newEntries.push({ ...entry1, date: entry1.date + (timeInterval - 1) });
              } else {
                const newEntry = {
                  ...entry1,
                  date: entry1.date + (timeInterval - 1)
                };
                const missedApps = circuitNames.filter(item => {
                  if (Number.isFinite(entry1[item])) {
                    if (Number.isFinite(entry2[item]) === false) {
                      newEntry[item] = 0;
                      return true;
                    } else newEntry[item] = entry2[item];
                  }
                  return false;
                });
                /* add an entry in current entry's end range if any application
                in current entry is missing in next entry */
                if (missedApps.length > 0) newEntries.push(newEntry);
              }
            } else data.push({
              ...entry1,
              date: entry1.date + (timeInterval - 1)
            });
          });
          // add new records to graph data
          data.push(...newEntries);
          // sort graph data based on entry time
          data.sort((item1, item2) => item1.date > item2.date ? 1 : -1);
          dataRef.current.circuit.usage = data;
          const durationHours = Math.trunc(
            (gFilter.timeFilter.current_period[1]
              - gFilter.timeFilter.current_period[0]) / 3600000
          );
          dataRef.current.circuit.usageAvg =
            formatSizeUnitsRound(usageAvg / durationHours);
        }
      }
      setCircuitLoader(false);
    }
  }, []);

  useEffect(() => {
    if (_.isEqual(
      app_data_with_family_slaRef.current,
      globalFilter.app_data_with_family_sla
    )) return;
    app_data_with_family_slaRef.current = globalFilter.app_data_with_family_sla;
    const timestamp = Date.now();
    Object.assign(dataRef.current.network, { error: null, data: [], qoe: [] });
    Object.assign(dataRef.current, { timestamp });
    for (let index = 0; index < chartsRef.current.length; index++)
      delete chartsRef.current[index];
    chartsRef.current.splice(0);
    if (Array.isArray(globalFilter.app_data_with_family_sla)) {
      const application = globalFilter.app_data_with_family_sla
        .find(row => row.application === appName);
      dataRef.current.firstLoad = false;
      if (application !== undefined) {
        dataRef.current.app = {
          ...application,
          score: Number.isFinite(application.vqoe_score)
            ? Math.round(application.vqoe_score)
            : defaultCellValue,
          change: Number.isFinite(application.vqoe_change)
            ? Math.round(application.vqoe_change)
            : defaultCellValue
        };
        dataRef.current.isGray = application.vqoe_status === "unknown";
        if (dataRef.current.isGray === false) {
          setNetworkLoader(true);
          getNetworkData(timestamp, globalFilter);
        }
        else if (networkLoader === true) setNetworkLoader(false);
      }
      else dataRef.current.app = null;
      setCustomListLoader(false);
    } else {
      setCustomListLoader(true);
      if (networkLoader === true) setNetworkLoader(false);
      if (circuitLoader === true) setCircuitLoader(false);
    }
    setSankeyDropdownValues([]);
  }, [globalFilter.app_data_with_family_sla]);

  useEffect(() => {
    if (globalFilter.globalV4Payload) {
      Object.assign(
        dataRef.current.circuit,
        { error: null, circuit: [], usage: [], usageAvg: 0 }
      );
      Object.assign(dataRef.current, { circuits: [], colorset: {} });
      setCircuitLoader(true);
      getCircuitData(globalFilter);
    }
  }, [
    globalFilter.selectedSite,
    globalFilter.timeFilter.current_period[0],
    globalFilter.timeFilter.current_period[1],
    globalFilter.currentTimeStamp
  ])


  const _handleSankeyDropdownChange = event => {
    setErrMsg("");
    if (
      (event &&
        event.detail.value &&
        event.target.value[0] &&
        event.target.value[0].toLowerCase() == "reset") ||
      event.detail.value.length == 0
    ) {
      /** resets search to default when user selects reset*/
      setSankeyDropdownValues([]);
      event.target.value = sankeyChartTopSites;
    } else if (
      event &&
      event.detail.value &&
      event.detail.value.length > 0 &&
      event.detail.value.length <= 6 &&
      event.target.value[0] &&
      event.target.value[0].toLowerCase() != "reset"
    ) {
      /** updates dropdown search values */
      setSankeyDropdownValues(event.target.value);
      setShowSankeyLoader(true);
      setSankeyChartDropdownDefaultData(event.target.value);
    } else {
      if (
        event &&
        event.detail.value &&
        event.detail.value.length > 0 &&
        event.detail.value.length > 7
      ) {
        /** removes element from search if it has more than 7 elements*/
        let dropdownSelect = event.target.value;
        let updatedSelection = dropdownSelect.splice(7, 1);
        event.target.value = updatedSelection;
      }
      setErrMsg("You can select max 6 sites");
    }
  };

  const sankeyDataCallback = data => {
    /** sets sankey chart data */
    setSankeyChartData(data);
  };
  const sankeyIdDataCallback = data => {
    /** sets the list of links passing through node */
    setSankeyChartIdData(data);
  };
  const sankeyDropdownDataCallback = data => {
    /** sets sankey chart dropdown data */
    setSankeyChartDropdownData(data);
  };
  const sankeyLoaderCallback = data => {
    /** sets sankey chart loader */
    setShowSankeyLoader(data);
  };
  const sankeyErrorCallback = data => {
    /** sets sankey chart error */
    setShowSankeyError(data);
  };

  const sankeyErrorObjectCallback = data => {
    /** sets sankey chart error */
    setSankeyError(data);
  };
  const sankeyNoDataCallback = data => {
    /** sets sankey chart empty response state */
    setSankeyNoData(data);
  };

  const sankeyDefaultDropdownDataCallback = data => {
    /** sets sankey chart default dropdown values data */
    setSankeyChartDropdownDefaultData(data);
    setSankeyChartTopSites(data);
  };

  const showRemoteSiteCallback = data => {
    /** sets if remote site column is shown or hiden */
    setShowRemoteSite(data);
  };

  const appLoader = customListLoader === true
    || Array.isArray(globalFilter.app_data_with_family_sla) === false;

  return (
    <div>
      {dataRef.current.firstLoad === true ? (
        <HbrCard className="widget-container" container>
          <div className="flex-items-center card-content">
            <Spinner />
          </div>
        </HbrCard>
      ) : dataRef.current.app === null ? (
        <HbrCard className="widget-container" container>
          <div className="flex-items-center card-content">
            <NoDataAvailable />
          </div>
        </HbrCard>
      ) : (
        <>
          {appLoader === false && (
            <SankeyChartData
              globalFilter={globalFilter}
              appName={appName}
              sankeyDataCallback={sankeyDataCallback}
              sankeyIdDataCallback={sankeyIdDataCallback}
              sankeyDropdownDataCallback={sankeyDropdownDataCallback}
              sankeyDefaultDropdownDataCallback={sankeyDefaultDropdownDataCallback}
              sankeyFilterKeys={sankeyDropdownValues}
              sankeyLoaderCallback={sankeyLoaderCallback}
              sankeyErrorCallback={sankeyErrorCallback}
              sankeyNoDataCallback={sankeyNoDataCallback}
              sankeyErrorObjectCallback={sankeyErrorObjectCallback}
              tab={tab}
              showRemoteSite={showRemoteSiteCallback}
            />
          )}
          <TopCharts
            appLoader={appLoader}
            networkLoader={networkLoader}
            circuitLoader={circuitLoader}
            dataRef={dataRef.current}
            timePeriod={globalFilter.timeFilter.current_period}
            chartsRef={chartsRef.current}
            chartCallback={chartCallback}
            tab={tab}
          />
          <HbrCard className="widget-container tabs-section" container>
            <div className={tab === "app_metric" ? "" : "card-content"}>
              <HbrTabGroup size="small">
                {dataRef.current.tabs.map(({ value, label }) => (
                  <HbrTab
                    key={value}
                    className="hbr-type-body3"
                    slot="nav"
                    panel={value}
                    active={value === tab}
                    onHbr-click={() => setTab(value)}
                  >
                    {label}
                  </HbrTab>
                ))}
                {
                  dataRef.current.pa.show === true &&
                  availableFeature.webex === true &&
                  globalFilter.timePeriodSelected !== 'Custom' && (
                    <HbrTab
                      className="hbr-type-body3"
                      slot="nav"
                      panel={paTab.value}
                      active={paTab.value === tab}
                      onHbr-click={() => setTab(paTab.value)}
                    >
                      {paTab.label}
                    </HbrTab>
                  )
                }
                <HbrTabPanel name="app_metric">
                  {tab === "app_metric" && (
                    <AppMetrics
                      appLoader={appLoader}
                      networkLoader={networkLoader}
                      circuitLoader={circuitLoader}
                      dataRef={dataRef.current}
                      timePeriod={globalFilter.timeFilter.current_period}
                      chartsRef={chartsRef.current}
                      chartCallback={chartCallback}
                    />
                  )}
                </HbrTabPanel>
                <HbrTabPanel name="app_flow">
                  {tab === "app_flow" && (
                    <div data-cy="sankeyChart">
                      {showSankeyLoader || appLoader === true ? (
                        <div className="sankey-chart-container">
                          <Spinner />
                        </div>
                      ) : showSankeyError ? (
                        <div className="sankey-chart-container">
                          <ErrorComponent
                            className="large-dashlet-error"
                            width="110px"
                            errorCode={sankeyError.errorObject.code}
                            message={sankeyError.errorObject.message}
                          />
                        </div>
                      ) : sankeyNoData ? (
                        <NoDataAvailable />
                      ) : (
                        <div>
                          <div className={css.sankeyTitle}>
                            <div className={css.sankeyDropDown}>
                              {sankeyChartDropdowndefaultData &&
                                sankeyChartDropdowndefaultData.length > 0 &&
                                sankeyChartData.length > 0 && (
                                  <DnxDropdown
                                    name="sankey-chart-dropdown"
                                    value={sankeyChartDropdowndefaultData}
                                    flavor="combobox"
                                    change={_handleSankeyDropdownChange}
                                    hint="You can select max 6 sites"
                                    data={sankeyChartDropdownData}
                                    errormsg={errMsg}
                                    search-text="Site not found"
                                    type="multi"
                                    search-label="search site"
                                    deselectable
                                  />
                                )}
                            </div>
                            <ul>
                              <li>{i18n.groupTitleLocal} {i18n.sankeyHeadingSite}</li>
                              <li>{i18n.groupTitleLocal} {i18n.sankeyHeadingColor}</li>
                              <li>{i18n.sankeyHeadingQoE} </li>
                              {showRemoteSite && (
                                <li>{i18n.groupTitleRemote} {i18n.sankeyHeadingColor}</li>
                              )}
                              <li>{i18n.groupTitleRemote} {i18n.sankeyHeadingSite}</li>
                            </ul>
                          </div>
                          <SankeyChart data={sankeyChartData} idData={sankeyChartIdData} />
                        </div>
                      )}
                      <div>
                        <AppFlow
                          showSnailTrailAction={availableFeature.umts}
                          qoeData={dataRef.current.network.qoe}
                        />
                      </div>
                    </div>
                  )}
                </HbrTabPanel>
                <HbrTabPanel name="top_client">
                  {tab === "top_client" && (
                    <TopUsers />
                  )}
                </HbrTabPanel>
                <HbrTabPanel name="path_analytics">
                  {tab === "path_analytics" && (
                    <PathAnalytics
                      appName={appName}
                      globalFilter={globalFilter}
                      paLoader={paLoader}
                      paFilterLoader={paFilterLoader}
                      paData={dataRef.current.pa}
                    />
                  )}
                </HbrTabPanel>
              </HbrTabGroup>
            </div>
          </HbrCard>
        </>
      )}
    </div>
  );
};

Application360AtSite.propTypes = {
  globalFilter: PropTypes.object.isRequired,
  setPathAnalyticsTab: PropTypes.func,
  appName: PropTypes.string.isRequired
};

export default Application360AtSite;
