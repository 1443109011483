import React, { useState, useCallback, useRef } from "react";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import QoEScoreComponent from "../../../common/QoEScoreComponent";
import Spinner from "../../../common/Spinner";
import ErrorComponent from "../../../common/ErrorComponent";
import NoDataAvailable from "../../../common/NoDataAvailable";
import MultiLineChart from "../../../common/MultiLineChart";
import GanttChart from "../../../common/GanttChart";
import { qoeRanges } from "../../../utils/common";
import { displayDateTime } from "../../../utils/displayTime";
import { formatSizeUnitsRound } from "../../../utils/format";
import { getGanttChartHeight } from "../../../utils/chart";
import i18n from "amdi18n-loader!../../nls/i18n";
import { defaultCellValue, defaultTextValue } from "../../../utils/enums";

const [HbrCard] = reactWrapper(["hbr-card"]);

const initialAccordionData = {
  date: defaultTextValue,
  circuits: [],
  qoeScore: defaultTextValue,
  packetLoss: defaultTextValue,
  latency: defaultTextValue,
  jitter: defaultTextValue
};
const yAxisProps = { valueY: "vqoe_score", max: 10, strictMinMax: true };
const seriesNames = { [yAxisProps.valueY]: i18n.syncronizedChartQoE };

const TopCharts = ({
  appLoader,
  networkLoader,
  circuitLoader,
  dataRef,
  timePeriod,
  chartsRef,
  chartCallback,
  tab
}) => {
  const [accordionData, setAccordionData] = useState(initialAccordionData);
  const [showAccordion, setShowAccordion] = useState(false);
  const accRef = useRef({ tab, data: null, accData: initialAccordionData });

  const topChartAccordionCallback = useCallback(data => {
    if (accRef.current.tab === "app_metric") {
      const newData = {
        ...data,
        circuits: dataRef.circuits
          .filter(item => data.circuits[item])
          .map(item => ([
            item,
            formatSizeUnitsRound(data.circuits[item])
          ]))
      };
      if (
        JSON.stringify(accRef.current.data) !== JSON.stringify(newData)
      ) {
        const accData = {
          date: newData.date
            ? "As of " + displayDateTime(newData.date)
            : defaultTextValue,
          circuits: newData.circuits,
          qoeScore: newData.vqoe_score,
          packetLoss: newData.loss_percentage,
          latency: newData.latency,
          jitter: newData.jitter
        };
        accData.qoeScore = Number.isFinite(accData.qoeScore)
          ? parseFloat(accData.qoeScore.toFixed(1))
          : defaultTextValue;
        accData.packetLoss = Number.isFinite(accData.packetLoss)
          ? parseFloat(accData.packetLoss.toFixed(1)) + " %"
          : defaultTextValue;
        accData.latency = Number.isFinite(accData.latency)
          ? (parseFloat(accData.latency.toFixed(1))
            + ` ${i18n.syncronizedChartUnit}`)
          : defaultTextValue;
        accData.jitter = Number.isFinite(accData.jitter)
          ? (parseFloat(accData.jitter.toFixed(1))
            + ` ${i18n.syncronizedChartUnit}`)
          : defaultTextValue;
        accRef.current.data = newData;
        accRef.current.accData = accData;
      }
    }
  }, []);

  const topChartHoverCallback = hover => {
    if (accRef.current.tab === "app_metric") {
      if (hover === true) setAccordionData(accRef.current.accData);
      setShowAccordion(hover);
    } else setShowAccordion(false);
  };

  const validNetwork = networkLoader === false && appLoader === false
    && dataRef.network.error === null && dataRef.network.data.length !== 0;
  const validCircuits = circuitLoader === false && appLoader === false
    && dataRef.circuit.error === null && dataRef.circuit.usage.length !== 0;

  return (
    <>
      <HbrCard className="widget-container" container>
        <div className="flex-column">
          <div className="flex-items qoe-info">
            <div className="hbr-type-h2">
              {i18n.syncronizedChartQoETitle}
            </div>
            {appLoader === false && (
              <QoEScoreComponent
                appScoreChange={dataRef.app.change}
                appScore={dataRef.app.score}
                tooltipInfo={dataRef.app.change !== defaultCellValue
                  ? i18n.app360TitleIcon
                  : (i18n.appQoeTooltipTitle
                    + " " + i18n.appQoeUnknownTooltip)}

              />
            )}
          </div>
          {dataRef.isGray === false && (
            <div className="top-chart flex-column">
              {validNetwork === false ? (
                <div className="nochart-content flex-main-content flex-items">
                  <div className="chart-title-vertical flex-items-center">
                    {i18n.syncronizedChartQoE}
                  </div>
                  <div
                    className="flex-column-full flex-main-content no-data-flex"
                  >
                    {
                      (networkLoader === true || appLoader === true) ? (
                        <Spinner />
                      ) : dataRef.network.error !== null ? (
                        <ErrorComponent
                          {...dataRef.network.error}
                          width="52px"
                          className="one-line-dashlet-error"
                        />
                      ) : dataRef.network.data.length === 0 && (
                        <NoDataAvailable />
                      )
                    }
                  </div>
                </div>
              ) : (
                <MultiLineChart
                  id="app360_at_site_qoe"
                  label={i18n.syncronizedChartQoE}
                  qualityRange={qoeRanges}
                  valueAxisProps={yAxisProps}
                  seriesNames={seriesNames}
                  topChart
                  showSynchronization
                  callbackFromParent={chartCallback}
                  hoverCallback={topChartHoverCallback}
                  accordionCallbackFromParent={topChartAccordionCallback}
                  min={timePeriod[0]}
                  max={timePeriod[1]}
                  data={dataRef.network.data}
                  chartsData={chartsRef}
                  appMetricData={dataRef}
                />
              )}
            </div>
          )}

          <div
            className="flex-column"
            style={{
              minHeight: getGanttChartHeight(dataRef.colorset)
            }}
          >
            {validCircuits === false ? (
              <div className="nochart-content flex-main-content flex-items">
                <div className="chart-title-vertical flex-items-center">
                  {i18n.syncronizedChartCircuits}
                </div>
                <div
                  className="flex-column-full flex-main-content no-data-flex"
                >
                  {
                    (circuitLoader === true || appLoader === true) ? (
                      <Spinner />
                    ) : dataRef.circuit.error !== null ? (
                      <ErrorComponent
                        {...dataRef.circuit.error}
                        width="52px"
                        className="one-line-dashlet-error"
                      />
                    ) : dataRef.circuit.circuit.length === 0 && (
                      <NoDataAvailable />
                    )
                  }
                </div>
              </div>
            ) : (
              <GanttChart
                id="app360_at_site_circuits"
                label={i18n.syncronizedChartCircuits}
                seriesHeight={8}
                topChart
                showSynchronization
                callbackFromParent={chartCallback}
                hoverCallback={topChartHoverCallback}
                accordionCallbackFromParent={topChartAccordionCallback}
                min={timePeriod[0]}
                max={timePeriod[1]}
                data={dataRef.circuit.circuit}
                chartsData={chartsRef}
                appMetricData={dataRef}
              />
            )}
          </div>
        </div>
      </HbrCard>
      {
        showAccordion === true && (
          <div className="accordion-container">
            <div className="date">
              {accordionData.date}
            </div>

            <div className="section-contents">
              <div className="section-content flex-items-center">
                <div className="hbr-type-h1 qoe-text">
                  {accordionData.qoeScore}
                </div>
              </div>
              <div className="section-content">
                <div className="hbr-type-h3">
                  {i18n.syncronizedChartCircuits}
                </div>
                {accordionData.circuits.length ? (
                  accordionData.circuits.map(([circuit]) => (
                    <div key={circuit} className="hbr-type-body3 content">
                      {circuit}
                    </div>
                  ))
                ) : (
                  <div className="hbr-type-body3 content"> {defaultTextValue} </div>
                )}
              </div>
              <div className="section-content">
                <div className="hbr-type-h3">
                  {" "}
                  {i18n.networkLabel}
                </div>
                <div className="key-data hbr-type-body3">
                  <div className="key">
                    {i18n.syncronizedChartLoss}
                  </div>
                  <div className="value">{accordionData.packetLoss}</div>
                </div>
                <div className="key-data hbr-type-body3">
                  <div className="key">
                    {i18n.syncronizedChartNetworkLatency}
                  </div>
                  <div className="value">{accordionData.latency}</div>
                </div>
                <div className="key-data hbr-type-body3">
                  <div className="key">
                    {i18n.syncronizedChartJitter}
                  </div>
                  <div className="value">{accordionData.jitter}</div>
                </div>
              </div>
              <div className="section-content">
                <div className="hbr-type-h3">
                  {i18n.applicationLabel}
                </div>
                <div className="key-data hbr-type-body3">
                  <div className="key-fair">
                    {i18n.syncronizedChartBandwidth}
                  </div>
                  <div className="value-fair">
                    <ul className="usage-style">
                      {accordionData.circuits.length ? (
                        accordionData.circuits.map(([circuit, usage]) => (
                          <li key={circuit}>{usage}</li>
                        ))
                      ) : (
                        <li> {defaultTextValue} </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
};

TopCharts.propTypes = {
  appLoader: PropTypes.bool.isRequired,
  networkLoader: PropTypes.bool.isRequired,
  circuitLoader: PropTypes.bool.isRequired,
  dataRef: PropTypes.object.isRequired,
  timePeriod: PropTypes.arrayOf(PropTypes.number).isRequired,
  chartsRef: PropTypes.arrayOf(PropTypes.object).isRequired,
  chartCallback: PropTypes.func.isRequired,
  tab: PropTypes.string.isRequired
};

export default TopCharts;
