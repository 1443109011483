import i18nMessageBundle from "amdi18n-loader!../../nls/i18n";
import { formatNumberNDecimalPlaces, formatBytes, formatString } from "../../../utils/format";
import { getColor, colors } from "../../../utils/colors";

export const getColumns = props => {
  return [
    {
      data: "site_name",
      title: i18nMessageBundle.app360siteIDLabel,
      render: data => {
        const displaySiteName = formatString(data, 15); 
        return `
            <hbr-link class="application-trending-apps-qoe-widget-link" id="sites-table-site-${data}">${displaySiteName}</hbr-link>
            <hbr-portal>
             <hbr-tooltip anchor="#sites-table-site-${data}">
               <div slot="content"><strong>${data}</strong></div>
              </hbr-tooltip>
            </hbr-portal>`;
      },
      createdCell: (td, rowData,data) => { 
        td.addEventListener("click", () => { 
          const siteId = data && data.site_id ? data.site_id : rowData;
          props.setSelectedSite(siteId);
        });
      }
    },
    {
      data: "city",
      title: i18nMessageBundle.siteLocation,
      render: data => {
        return data || "NA";
      }
    },
    {
      data: "vqoe_score",
      title: i18nMessageBundle.applicationTableViewQoE,
      render: data => {
        if (data === null || data === undefined) return "";
        let qoe_score = formatNumberNDecimalPlaces(data, 1);
        let color = getColor(qoe_score, "qoe");
        return `<div style="color: ${color}">${qoe_score}</div>`;
      }
    },
    {
      data: "vqoe_change",
      title: i18nMessageBundle.applicationTableViewQoEChange,
      render: data => {
        if (data === null || data === undefined) return "";

        let change_value = formatNumberNDecimalPlaces(data, 1);
        let color = change_value < 0 ? colors.red45 : null;

        return `<div style='color:${color}'>${
          change_value > 0 ? "+" + change_value : change_value
        }</div>`;
      }
    },
    {
      data: "usage",
      title: i18nMessageBundle.applicationTableViewUsage,

      render: data => {
        if (data === null || data === undefined) return "";
        return formatBytes(data);
      }
    },
    {
      data: "packet_loss",
      title: i18nMessageBundle.applicationTableViewLoss, //Loss %

      render: data => {
        if (data === null || data === undefined) return "";
        return formatNumberNDecimalPlaces(data, 1);
      }
    },
    {
      data: "latency",
      title: i18nMessageBundle.latencyInMPS,
      render: data => {
        if (data === null || data === undefined) return "";
        return formatNumberNDecimalPlaces(data, 1);
      }
    },
    {
      data: "jitter",
      title: i18nMessageBundle.jitterInMS,
      render: data => {
        if (data === null || data === undefined) return "";
        return formatNumberNDecimalPlaces(data, 1);
      }
    }
  ];
};
