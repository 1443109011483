import React from "react";
import { PropTypes } from "prop-types";
import i18nMessageBundle from "amdi18n-loader!../../../../nls/i18n";
import QoeListComponent from "../../../../../common/QoeListComponent";
import { COLOR } from "../../../../../utils/enums";

const SummaryComponent = props => {
  const counts = props.summaryData[0];
  const goodQoE = { count: counts.good_count };
  const fairQoE = { count: counts.fair_count };
  const poorQoE = { count: counts.bad_count };
  const unknownQoE = { count: counts.gray_count };
  return (
    <div className="map-summary">
      <div className="map-summary-qoes">
        <QoeListComponent
          overviewPage={false}
          iconType={[COLOR.GOOD, COLOR.FAIR, COLOR.POOR, COLOR.GRAY]}
          qoeScore={[goodQoE, fairQoE, poorQoE, unknownQoE]}
          qoeLabels={[
            i18nMessageBundle.sitesGood,
            i18nMessageBundle.sitesFair,
            i18nMessageBundle.sitesPoor,
            i18nMessageBundle.sitesUnknown
          ]}
        />
      </div>
    </div>
  );
};

SummaryComponent.propTypes = {
  summaryData: PropTypes.array
};

export default SummaryComponent;
