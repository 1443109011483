import { useState } from "react";
import { getSankeyData } from "../../actions";
import { createGenericErrorObject } from "../../../../utils/common";

const useAggregateDataApis = () => {
  const [sankeyChartData, setSankeyChartData] = useState({});
  const [
    isFetchingSankeyChartDataDone,
    setIsFetchingSankeyChartDataDone
  ] = useState(false);
  const [isFetchingSankeyChartData, setIsFetchingSankeyChartData] = useState(
    false
  );
  const [
    isFetchingSankeyChartDataError,
    setIsFetchingSankeyChartDataError
  ] = useState(false);
  const [isFetchingSankeyChartError, setIsFetchingSankeyChartError] = useState({
    errorObject: {}
  });

  const getSankeyChartData = async (timeFilter, appName, siteId) => {
    setIsFetchingSankeyChartError({ errorObject: {} });
    setIsFetchingSankeyChartDataDone(false);
    setIsFetchingSankeyChartDataError(false);
    setIsFetchingSankeyChartData(true);
    setSankeyChartData({});
    try {
      const result = await getSankeyData(timeFilter, appName, siteId);
      setSankeyChartData(result.data);
      setIsFetchingSankeyChartDataDone(true);
      setIsFetchingSankeyChartData(false);
    } catch (error) {
      setIsFetchingSankeyChartError(createGenericErrorObject(error));
      setIsFetchingSankeyChartDataError(true);
      setIsFetchingSankeyChartDataDone(true);
      setIsFetchingSankeyChartData(false);
    }
  };

  return {
    state: {
      sankeyChartData,
      isFetchingSankeyChartDataDone,
      isFetchingSankeyChartData,
      isFetchingSankeyChartDataError,
      isFetchingSankeyChartError
    },
    getSankeyChartData
  };
};

export default useAggregateDataApis;
