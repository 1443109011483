import { createContext } from "@cisco-dna/redux-utils";
import { ACTIONS } from "./actions";

const reduxContext = createContext();

export default reduxContext;

const storeKey = "vanalytics.app360";

const initialState = {
  tunnel_table_loader: true,
  tunnel_data: [],

  top_users_table: [],
  top_users_loader: true,

  appflow_table_loader: false,
  appflow_data: [],

  siteList: [],
  siteList_loader: true,

  siteSummary: [],
  siteSummary_loader: true,

  pathScore: [],
  pathScore_loader: true,

  partnerDataExchange: [],
  partnerDataExchange_loader: true,

  edgeDataExchange: [],
  edgeDataExchange_loader: true,

  isApp360PageView: false
};

const reducer = (state = initialState, action) => {
  const { type, payload, error } = action;

  if (error) {
    // @TODO : Handle errors
  }

  switch (type) {
    case ACTIONS.SET_TUNNEL_TABLE:
      return {
        ...state,
        tunnel_data: payload,
        tunnel_table_loader: false
      };
    case ACTIONS.TUNNEL_TABLE_LOADING:
      return {
        ...state,
        tunnel_data: [],
        tunnel_table_loader: true,
        appflow_table_loader: true
      };
    case ACTIONS.SET_TOPUSERS_TABLE:
      return {
        ...state,
        top_users_table: payload,
        top_users_loader: false
      };
    case ACTIONS.SET_APPFLOW_TABLE:
      return {
        ...state,
        appflow_data: payload,
        appflow_table_loader: false
      };
    case ACTIONS.TOPUSERS_TABLE_LOADING:
      return {
        ...state,
        top_users_loader: true
      };
    case ACTIONS.SITE_LIST_LOADING:
      return {
        ...state,
        siteList_loader: true
      };
    case ACTIONS.SITE_LIST:
      return {
        ...state,
        siteList: payload,
        siteList_loader: false
      };

    case ACTIONS.SITE_SUMMARY_LOADING:
      return {
        ...state,
        siteSummary_loader: true
      };

    case ACTIONS.SITE_SUMMARY:
      return {
        ...state,
        siteSummary: payload,
        siteSummary_loader: false
      };
    case ACTIONS.PATH_SCORE:
      return {
        ...state,
        pathScore: payload,
        pathScore_loader: false
      };
    case ACTIONS.PARTNER_DATA_EXCHANGE:
      return {
        ...state,
        partnerDataExchange: payload,
        partnerDataExchange_loader: false
      };
    case ACTIONS.EDGE_DATA_EXCHANGE:
      return {
        ...state,
        edgeDataExchange: payload,
        edgeDataExchange_loader: false
      };
    default:
      return state;
  }
};

reduxContext.injectReducer({
  STORE: storeKey,
  REDUCER: reducer,
  ACTION_TYPE: [
    ACTIONS.SET_TUNNEL_TABLE,
    ACTIONS.TUNNEL_TABLE_LOADING,
    ACTIONS.SET_TOPUSERS_TABLE,
    ACTIONS.SET_APPFLOW_TABLE,
    ACTIONS.TOPUSERS_TABLE_LOADING,
    ACTIONS.SITE_LIST_LOADING,
    ACTIONS.SITE_LIST,
    ACTIONS.SITE_SUMMARY_LOADING,
    ACTIONS.SITE_SUMMARY
  ]
});
