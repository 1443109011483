import i18n from "amdi18n-loader!../../../nls/i18n";

export const getDeviceInfo = (siteId, filters, appInfo) => {
  const title = i18n.app360PathAnalytics.dataComparison;
  const info = {
    title,
    data: [
      {
        label: i18n.app360PathAnalytics.systemIP,
        value: filters.system_ip.value
      },
      { label: i18n.app360PathAnalytics.siteId, value: siteId },
      {
        label: i18n.app360PathAnalytics.hostName,
        value: filters.devices.value
      },
      {
        label: i18n.app360PathAnalytics.interface,
        value: filters.interfaces?.value.join(", ")
      }
    ]
  };
  if (appInfo[0].region_name && appInfo[0].region_name.length > 0) {
    const regions = filters.serverRegion.value;
    info.data.push({
      label: i18n.app360PathAnalytics.serverRegion,
      value: regions && Array.isArray(regions) ? regions[0] : regions
    });
  } else {
    const serviceArea = filters.serviceArea.value;
    info.data.push({
      label: i18n.app360PathAnalytics.serviceArea,
      value: serviceArea && Array.isArray(serviceArea) ? serviceArea[0] : serviceArea
    });
  }
  return info;
};
