import React, { useMemo } from "react";
import { PropTypes } from "prop-types";
import { getDeviceInfo } from "./DeviceInfoConfig";

const DeviceInfo = ({ siteId, appInfo, filters }) => {
  const data = useMemo(() => {
    return getDeviceInfo(siteId, filters, appInfo);
  }, [siteId, filters, appInfo]);

  return (
    <div className="pa-info">
      <div className="hbr-type-body3 device-info-data">
        {data.data.map((item, index) => (
          <div className="flex-items" key={index}>
            <span className="semi-bold">
              {item.label}
            </span>
            <span className="info-value">
              {item.value}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

DeviceInfo.propTypes = {
  siteId: PropTypes.number.isRequired,
  appInfo: PropTypes.array,
  filters: PropTypes.object
};

export default DeviceInfo;
