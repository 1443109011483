import React, { useRef, useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { DtableWrapper } from "dnx-web-components/dist/index-with-deps-react.min";
import table from "dnx-web-components/dist/table.min";
import { genericViewFromComponent } from "@cisco-dna/react-utils";
import AppHOC from "../../../generics/AppHOC";
import SitesFilter from "../SitesFilter";
import { isCustom, downloadAsCSVFile } from "../../../utils/common";
import { createRequest } from "../../../utils/query-utils";
import { formatNumberNDecimalPlaces, formatSiteTableBytes, capitalizeString } from "../../../utils/format";
import { getColumns } from "./tableConfig";
import apiService from "../../../config/api-config";
import i18n from "amdi18n-loader!../../nls/i18n";
import { defaultCellValue } from "../../../utils/enums";

const SiteList = props => {
  const {
    timeFilter,
    timePeriodSelected,
    selectedCustomTime,
    selectedOverlay,
    selectedSite,
    currentTimeStamp
  } = props.globalFilter;
  const { appName } = props.match.params;
  const [tableComp, seTableComp] = useState();
  const DnxTable = DtableWrapper(table);
  const tableRef = useRef();
  const tableSitesDataRef = useRef(props?.globalFilter?.siteAPIData.sitesTableData);
  let selectedFilter;

  /**** Export table data  ******/
  const getExportData = dataTable => {
    const { columns } = dataTable.props;
    const settings = dataTable.table.settings()[0];
    const payload = {
      ...props.globalFilter.globalV4Payload,
      sort: {
        [columns[settings.aaSorting[0][0]].data]: settings.aaSorting[0][1]
      },
      application: appName
    };
    if (selectedFilter && selectedFilter !== "all")
      payload.vqoe_status = selectedFilter;

    return apiService.getMapViewSitesOptimized(payload);
  };

  const getTools = getExportData => {
    const tools = [
      {
        factory: genericViewFromComponent(SitesFilter),
        level: "secondary",
        justify: "start"
      },
      {
        ref: {
          name: "export",
          config: {
            title: i18n.app360TabViewSites,
            action: async dataTable => {
              const res = await getExportData(dataTable);
              if (
                res.errorObject instanceof Object === false
                && res.data instanceof Object
                && Array.isArray(res.data.data)
              ) {
                const exportData = res.data.data;
                const columns = dataTable.props.columns.filter(item => item.visible !== false);
                if (dataTable.props.rowSelect === true) columns.shift();
                const data = [columns.map(item => `"${item.title}"`).join(",")];
                for (const item of exportData) {
                  const city = item.city ? capitalizeString(item.city) : defaultCellValue;
                  const usage = Number.isFinite(item.usage)
                    ? formatSiteTableBytes(item.usage) : defaultCellValue;
                  const packet_loss = Number.isFinite(item.packet_loss)
                    ? formatNumberNDecimalPlaces(item.packet_loss, 1)
                    : defaultCellValue;
                  const latency = Number.isFinite(item.latency)
                    ? formatNumberNDecimalPlaces(item.latency, 1)
                    : defaultCellValue;
                  const jitter = Number.isFinite(item.jitter)
                    ? formatNumberNDecimalPlaces(item.jitter, 1)
                    : defaultCellValue;

                  data.push([
                    `"${item.site_name}"`,
                    `"${city}"`,
                    `"${item.vqoe_score}"`,
                    `"${item.vqoe_change}"`,
                    `"${usage}"`,
                    `"${packet_loss}"`,
                    `"${latency}"`,
                    `"${jitter}"`
                  ].join(","));
                }

                downloadAsCSVFile(
                  data.join("\n"),
                  i18n.allSiteSitesLabel
                );
              }
            }
          }
        },
        level: "secondary",
        justify: "start"
      }
    ];
    return tools;
  }

  useEffect(() => {
    if (!isCustom(timePeriodSelected) || selectedCustomTime) {
      const getTableProps = () => {
        const columns = getColumns(props);
        const tableConfig = {
          columns: columns,
          tools: getTools(getExportData),
          serverSide: true,
          getData: getData,
          dtOptions: {
            scrollY: 400,
            scrollX: true,
            order: [[1, "desc"]]
          },
          onFilterToggle: onFilterToggle,
          columnSearch: true,
          columnSearchEnabled: true
        };
        return tableConfig;
      };

      const getData = async (aoData) => {
        let dTable = null;
        if (tableRef.current) dTable = tableRef.current.table;
        const defaultRequest = {
          ...props.globalFilter.globalV4Payload,
          application: appName
        };
        if (selectedFilter && selectedFilter !== "all")
          defaultRequest.vqoe_status = selectedFilter;
        const payload = createRequest(defaultRequest, aoData, dTable);

        const result = await apiService.getMapViewSitesOptimized(payload);

        if (result && !result.errorObject) {
          const siteTableData = result.data.data;
          const siteData = tableSitesDataRef?.current?.data;
          if (siteTableData && siteData) {
            siteTableData.forEach(siteListObj => {
              const matchingSite = siteData.find(
                siteDataObj => siteListObj.site_id === siteDataObj.site_id
              );
              if (matchingSite) {
                siteListObj.city = matchingSite.city; // Getting location for Site Table from Site Data
              }
            });
          }
          return {
            response: siteTableData,
            count: result.data.count
          }
        } else return {
          response: [],
          count: 0
        }
      };

      const onFilterToggle = (filter) => {
        selectedFilter = filter;
        let dTable = null;
        if (tableRef.current) {
          dTable = tableRef.current.table;
        }
        dTable.refresh();
      };

      const tableProps = getTableProps();
      seTableComp(<DnxTable {...tableProps} ref={tableRef} />);
    }
  }, [
    timeFilter.current_period && timeFilter.current_period[0],
    timeFilter.previous_period && timeFilter.current_period[1],
    selectedOverlay,
    selectedSite,
    currentTimeStamp && currentTimeStamp
  ]);

  return (
    <div className="site-list-table" data-cy="siteListTableView">
      <div
        className="dnx--css_table-container"
        style={{ maxWidth: "100vw" }}
      >
        {tableComp}
      </div>
    </div>
  );
};

SiteList.propTypes = {
  globalFilter: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default AppHOC(SiteList);
