import React from "react";
import { PropTypes } from "prop-types";

import PathScore from "../PathScore";
import PartnerDataExchange from "../PartnerDataExchange";
import AppFlow from "../AppFlow";
import TopUsers from "../TopUsers";
import EdgeDataExchange from "../EdgeDataExchange";
import css from "../app360MagneticStyle.less";
import SitesComponent from "../SitesComponent";

const SeletedTabView = ({ tabContent }) => {
  let component = <SitesComponent />;

  switch (tabContent) {
    case "sites":
      component = <SitesComponent />;
      break;

    case "pathScore":
      component = <PathScore />;
      break;

    case "partnerDataExchange":
      component = <PartnerDataExchange />;
      break;

    case "appFlow":
      component = <AppFlow showSnailTrailAction={false} />;
      break;

    case "topUsers":
      component = <TopUsers />;
      break;

    case "edgeDataExchange":
      component = <EdgeDataExchange />;
      break;
  }

  return (
    <div className={css["tab-view"]}>
      <div className="selected-tab-view">{component}</div>
    </div>
  );
};
SeletedTabView.propTypes = {
  tabContent: PropTypes.string
};
export default SeletedTabView;
