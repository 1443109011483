import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import { Funnel } from "phosphor-react";
import Spinner from "../../../common/Spinner";
import ErrorComponent from "../../../common/ErrorComponent";
import NoDataAvailable from "../../../common/NoDataAvailable";
import { PathAnaltyicsFilter } from "./Filter";
import DeviceInfo from "./DeviceInfo";
import O365PathAnalytics from "./O365";
import WebexPathAnalytics from "./webexView";
import i18n from "amdi18n-loader!../../nls/i18n";

const [HbrButton] = reactWrapper(["hbr-button"]);

const PathAnalytics = (
  { globalFilter, appName, paLoader, paFilterLoader, paData }
) => {
  const [filters, setFilters] = useState(paData.filter.init);

  useEffect(() => {
    if (paLoader === false && paFilterLoader === false)
      setFilters(paData.filter.init);
    else setFilters(null);
  }, [paLoader, paFilterLoader]);

  const loader = paLoader === true || paFilterLoader === true
    || filters === null;
  const error = paData.showTab.error || paData.filter.error;
  const noData = paData.filter.init === null || paData.filter.data.length === 0;

  return (
    <div>
      {paData.firstLoad === true ? (
        <div className="flex-items-center card-content">
          <Spinner />
        </div>
      ) : (loader === false && error !== null) ? (
        <ErrorComponent
          {...error}
          width="300px"
          className="large-dashlet-error"
        />
      ) : (loader === false && noData === true) ? (
        <div className="pa-no-data">
          <NoDataAvailable text={i18n.appPathDataNoAvailable} />
        </div>
      ) : (
        <>
          {/* common filter and device info components for webex and o365 */}
          {(loader === true ? (
            <div className="section-container">
              <div className="flex-items-two app360-section-title pa-filter">
                <div className="hbr-type-h2">
                  {i18n.app360PathAnalytics.dataComparison}
                </div>
                <div>
                  <HbrButton
                    className="hbr-type-body3"
                    sentiment="neutral"
                    variant="outline"
                    disabled
                  >
                    <Funnel slot="prefix" size={18} weight="bold" />
                    {i18n.app360PathAnalytics.filter}
                  </HbrButton>
                </div>
              </div>
              <div className="flex-items-center pa-filter-loader">
                <Spinner />
              </div>
            </div>
          ) : (
            <div className="section-container">
              <PathAnaltyicsFilter
                appInfo={paData.filter.data}
                filters={filters}
                onChange={setFilters}
              />
              <DeviceInfo
                siteId={globalFilter.selectedSite}
                appInfo={paData.filter.data}
                filters={filters}
              />
            </div>
          ))}
          {/* Based on appinfo feature either webex or O365 is seen */}
          {paData.isWebex === true ? (
            <WebexPathAnalytics
              appName={appName}
              globalFilter={globalFilter}
              paLoader={loader}
              appInfo={paData.filter.data}
              filters={filters}
            />
          ) : (
            <O365PathAnalytics
              appName={appName}
              globalFilter={globalFilter}
              paLoader={loader}
              filters={filters}
            />
          )}
        </>
      )}
    </div>
  );
};

PathAnalytics.propTypes = {
  appName: PropTypes.string,
  globalFilter: PropTypes.object,
  paLoader: PropTypes.bool.isRequired,
  paFilterLoader: PropTypes.bool.isRequired,
  paData: PropTypes.object.isRequired
};

export default PathAnalytics;
