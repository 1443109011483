import React, { useRef, useState, useEffect } from "react";
import { PropTypes } from "prop-types";

import { getColumns, rowGroup, columnGroup } from "./tableConfig";
import { getO365PathScore } from "./getPathScore";
import { DtableWrapper } from "dnx-web-components/dist/index-with-deps-react.min";
import table from "dnx-web-components/dist/table.min";
import i18nMessageBundle from "amdi18n-loader!../../nls/i18n";
import { pathScore } from "../../../config/reqConfig/app360";
import { formatRequestPayload, prepareQuery } from "../../../utils/query-utils";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import reduxContext from "../reducer";
import * as actions from "../actions";
import AppHoc from "../../../generics/AppHOC";
import { isCustom } from "../../../utils/common";

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

const PathScoreTable = props => {
  const { globalFilter } = props;
  const {
    selectedSite,
    selectedOverlay,
    timeFilter,
    timePeriodSelected,
    selectedCustomTime,
    currentTimeStamp
  } = globalFilter;
  const DnxTable = DtableWrapper(table);
  const [tableComponent, setTableComponent] = useState();
  const tableRef = useRef();
  useEffect(() => {
    if (!isCustom(timePeriodSelected) || selectedCustomTime) {
      const getTableProps = () => {
        const columns = getColumns;
        const tableConfig = {
          id: "table-serverSide",
          columns: columns,
          complexColumns: [
            {
              title: i18nMessageBundle.app360PathScoreComplexColumnScore,
              columns: [6, 7]
            },
            {
              title: i18nMessageBundle.app360PathScoreComplexColumnLatency,
              columns: [8, 10]
            }
          ],
          dataTableClasses: "nowrap",
          columnSearch: true,
          columnSearchEnabled: true,
          serverSide: true,
          getData: getData,
          dtOptions: {
            scrollY: 400,
            scrollX: true,
            order: [[0, "desc"]]
          }
        };
        return tableConfig;
      };

      const getData = async aoData => {
        let dTable = null;
        if (tableRef.current) {
          dTable = tableRef.current.table;
        }
        let defaultRequest = JSON.parse(JSON.stringify(pathScore));

        // prepareQuery for time filter
        defaultRequest = prepareQuery(defaultRequest, globalFilter);

        // get request payload
        const requestPayload = formatRequestPayload(
          defaultRequest,
          aoData,
          dTable,
          columnGroup
        );
        return new Promise(resolve => {
          getO365PathScore(requestPayload).then(response => {
            let success = response.data.data;
            const count = response.data.header.counts;
            const sortColIndex = aoData.find(ao => ao.name === "iSortCol_0")
              .value;
            const groupColIndex = getColumns.findIndex(
              col => col.data === columnGroup
            );
            if (sortColIndex === groupColIndex) {
              success = groupData(response.data.data);
            }
            resolve({
              response: success,
              count: count
            });
          });
        });
      };

      // Group rows data for site id and published date
      const groupData = response => {
        const output = [];
        rowGroup.reduce((key1, key2) => {
          response.reduce(function(acc, val) {
            if (acc[key1] === val[key1] && acc[key2] === val[key2]) {
              const copyObj = Object.assign({}, val);
              copyObj[key1] = null;
              copyObj[key2] = null;
              output.push(copyObj);
            } else {
              output.push(val);
              acc = Object.assign({}, val);
            }
            return acc;
          }, {});
        });
        return output;
      };

      let tableProps = getTableProps();
      setTableComponent(<DnxTable {...tableProps} ref={tableRef} />);
    }
  }, [
    timeFilter.current_period && timeFilter.current_period[0],
    timeFilter.previous_period && timeFilter.current_period[1],
    selectedSite,
    selectedOverlay,
    currentTimeStamp && currentTimeStamp
  ]);
  return (
    <div className="path-score-table" data-cy="pathScoreTableView">
      <div
        className="dnx--css_table-container"
        style={{
          maxWidth: "100vw"
        }}
      >
        {tableComponent}
      </div>
    </div>
  );
};

PathScoreTable.propTypes = {
  globalFilter: PropTypes.object
};

export default reduxContext.withProvider(
  connect(null, mapDispatchToProps)(AppHoc(PathScoreTable))
);
