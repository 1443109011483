import React from "react";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import Spinner from "../../../common/Spinner";
import ErrorComponent from "../../../common/ErrorComponent";
import NoDataAvailable from "../../../common/NoDataAvailable";
import StackedMultiLineChart from "../../../common/StackedMultiLineChart";
import MultiLineChart from "../../../common/MultiLineChart";
import i18n from "amdi18n-loader!../../nls/i18n";

const [HbrBadge] = reactWrapper(["hbr-badge"]);
const networkCharts = [
  { title: i18n.syncronizedChartLatency, valueY: "latency", unit: "ms" },
  { title: i18n.syncronizedChartLoss, valueY: "loss_percentage", unit: "%" },
  { title: i18n.syncronizedChartJitter, valueY: "jitter", unit: "ms" }
];

const AppMetrics = ({
  appLoader,
  circuitLoader,
  networkLoader,
  dataRef,
  timePeriod,
  chartsRef,
  chartCallback
}) => {

  const validCircuits = circuitLoader === false && appLoader === true
    && dataRef.circuit.error === null && dataRef.circuit.usage.length !== 0;

  return (
    <div>
      <div className="section-container" data-cy="application">
        <div className="hbr-type-h2 app360-section-title">
          {i18n.applicationLabel}
        </div>
        <hbr-accordion open size="large" triggerposition="right">
          <div slot="label" className="width-full">
            <div className="flex-items-two">
              <div className="hbr-type-body2">{i18n.syncronizedChartBandwidth}</div>
              {validCircuits === true && (
                <HbrBadge pill size="small">
                  {i18n.syncronizedChartAverage + " : "
                    + dataRef.circuit.usageAvg}
                </HbrBadge>
              )}
            </div>
          </div>
          <div className="app-metric-chart-container">
            {(circuitLoader === true || appLoader === true) ? (
              <Spinner />
            ) : dataRef.circuit.error !== null ? (
              <ErrorComponent
                {...dataRef.circuit.error}
                width="85px"
                className="one-line-dashlet-error"
              />
            ) : dataRef.circuit.usage.length === 0 ? (
              <div className="flex-column-full no-data-flex">
                <NoDataAvailable />
              </div>
            ) : (
              <StackedMultiLineChart
                id="app360_appmetrics_usage_chart"
                label={i18n.syncronizedChartBandwidth}
                showSynchronization
                callbackFromParent={chartCallback}
                min={timePeriod[0]}
                max={timePeriod[1]}
                colorset={dataRef.colorset}
                data={dataRef.circuit.usage}
                chartsData={chartsRef}
                appMetricData={dataRef}
              />
            )}
          </div>
        </hbr-accordion>
      </div>
      {dataRef.isGray === false && (
        <div className="section-container" data-cy="network">
          <div className="hbr-type-h2 app360-section-title">
            {i18n.networkLabel}
          </div>
          {networkCharts.map(item => (
            <hbr-accordion
              key={item.title}
              open
              size="large"
              triggerposition="right"
            >
              <div slot="label" className="width-full">
                <div className="flex-items-two">
                  <div className="hbr-type-body2">{item.title}</div>
                </div>
              </div>
              <div className="app-metric-chart-container">
                {(networkLoader === true || appLoader === true) ? (
                  <Spinner />
                ) : dataRef.network.error !== null ? (
                  <ErrorComponent
                    {...dataRef.network.error}
                    width="85px"
                    className="one-line-dashlet-error"
                  />
                ) : dataRef.network.data.length === 0 ? (
                  <div className="flex-column-full no-data-flex">
                    <NoDataAvailable />
                  </div>
                ) : (
                  <MultiLineChart
                    id={`app360_at_site_network_${item.title}`}
                    label={item.title}
                    chartUnits={item.unit}
                    valueAxisProps={{ valueY: item.valueY }}
                    seriesNames={{ [item.valueY]: item.title }}
                    showSynchronization
                    callbackFromParent={chartCallback}
                    min={timePeriod[0]}
                    max={timePeriod[1]}
                    data={dataRef.network.data}
                    chartsData={chartsRef}
                    appMetricData={dataRef}
                  />
                )}
              </div>
            </hbr-accordion>
          ))}
        </div>
      )}
    </div>
  );
};

AppMetrics.propTypes = {
  appLoader: PropTypes.bool.isRequired,
  networkLoader: PropTypes.bool.isRequired,
  circuitLoader: PropTypes.bool.isRequired,
  dataRef: PropTypes.object.isRequired,
  timePeriod: PropTypes.arrayOf(PropTypes.number).isRequired,
  chartsRef: PropTypes.arrayOf(PropTypes.object).isRequired,
  chartCallback: PropTypes.func.isRequired
};

export default AppMetrics;
