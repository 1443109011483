import React, { useRef } from "react";
import { PropTypes } from "prop-types";
import { DtableWrapper } from "dnx-web-components/dist/index-with-deps-react.min";
import DTable from "dnx-web-components/dist/table.min";
import i18n from "amdi18n-loader!../../nls/i18n";
import AppHOC from "../../../generics/AppHOC";
import { formatBytes, formatNumberNDecimalPlaces } from "../../../utils/format";
import { getColor } from "../../../utils/colors";
import { formatRequestPayload } from "../../../utils/query-utils";
import { sortdata } from "../../../utils/common";
import css from "../app360MagneticStyle.less";

const DNXTable = DtableWrapper(DTable);

const TopUsersTable = props => {
  /* const { reportView } = props.reportView === true
      ? props.app360.top_users_table
      : props.data.app360.top_users_table; */
  const tableRef = useRef();

  const getTableProps = () => {
    const columns = [
      { data: "user_ip", title: i18n.userIP },
      { data: "site_id", title: i18n.site },
      {
        data: "city",
        title: i18n.siteLocation,
        render: data => {
          if (!data) return "NA";
          return data;
        }
      },
      {
        data: "vqoe",
        title: i18n.qoe,
        render: data =>
          `<div style="color: ${getColor(
            data,
            "qoe"
          )}">${formatNumberNDecimalPlaces(data, 1)}</div>`
      },
      {
        data: "usage",
        title: i18n.applicationOverviewLabelUsage,
        render: data => formatBytes(data)
      },
      {
        data: "loss",
        title: i18n.app360Loss,
        render: data => formatNumberNDecimalPlaces(data, 1)
      },
      {
        data: "latency",
        title: i18n.app360Latency,
        render: data => formatNumberNDecimalPlaces(data, 1)
      },
      {
        data: "jitter",
        title: i18n.app360Jitter,
        render: data => formatNumberNDecimalPlaces(data, 1)
      }
    ];

    const getData = async aoData => {
      const sortedData =
        props.reportView === true
          ? props.app360.top_users_table
          : props.data.app360.top_users_table;
      const siteData = props.reportView === true 
          ? props.globalFilter.siteAPIData.sitesTableData
          : props.globalFilter.siteAPIData.sitesTableData.data;
      const requestPayload = formatRequestPayload(
        {},
        aoData,
        tableRef.current?.table
      );

      if (sortedData && siteData) {
        sortedData.forEach(item1 => {
          const matchingSite = siteData.find(
            item2 => item1.site_id === item2.site_id
          );
          // getting location for app360 top clients from site data
          if (matchingSite) item1.city = matchingSite.city;
        });
      }

      return {
        response: sortdata(requestPayload, sortedData),
        count: sortedData.length
      };
    };

    const tableConfig = {
      columns,
      serverSide: true,
      getData: getData,
      dtOptions: { paging: false, stateSave: true, stateDuration: 0 }
    };

    if (props.reportView !== true) {
      tableConfig.dtOptions.scrollX = true;
      tableConfig.dtOptions.scrollY = 400;
      tableConfig.tools = [{
        ref: {
          name: "export",
          config: { title: i18n.app360TabViewTopClients }
        },
        level: "secondary",
        justify: "end"
      }];
    }

    return tableConfig;
  };

  const tableProps = getTableProps();

  return (
    <div className={css["topusers-table"]} data-cy="topusersTableView">
      <DNXTable {...tableProps} ref={tableRef} />
    </div>
  );
};

TopUsersTable.propTypes = {
  data: PropTypes.object.isRequired,
  reportView: PropTypes.bool,
  globalFilter: PropTypes.object.isRequired,
  app360: PropTypes.object.isRequired
};

export default AppHOC(TopUsersTable);
