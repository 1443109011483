import React, { useState, useEffect, useRef, useCallback } from "react";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import Spinner from "../../../../common/Spinner";
import ErrorComponent from "../../../../common/ErrorComponent";
import NoDataAvailable from "../../../../common/NoDataAvailable";
import PathChart from "../PathChart";
import LineCharts from "../LineChartNew";
import { useMount } from "../../../../utils/genericCommon";
import paUtils, { convertMediaTransportNames } from "../utils";
import {
  getPAPathData,
  getPANetworkTelemetryData,
  getPAUsageData,
  getPAMediaData,
  getLineChartFormattedData
} from "../../actions";
import {
  chartConfig,
  getWebexChartConfig,
  webExStaticData
} from "./chartConfig";
import i18n from "amdi18n-loader!../../../nls/i18n";
import { defaultTextValue } from "../../../../utils/enums";
import { getTimestampFromUTCString } from "../../../../utils/displayTime";

const [HbrViewSwitcher, HbrRadioButton, HbrSwitchGroup, HbrSwitch] =
  reactWrapper([
    "hbr-view-switcher",
    "hbr-radio-button",
    "hbr-switch-group",
    "hbr-switch"
  ]);
// these types are used to set default value after getting the transport type list from the api
const transportTypeNames = { udp: "udp", tcp: "tcp" };

// get default transport type
const getTransportType = transportTypes => {
  if (transportTypes.length > 0) {
    if (transportTypes.some(item => item === transportTypeNames.udp))
      return [transportTypeNames.udp];
    else if (transportTypes.some(item => item === transportTypeNames.tcp))
      return [transportTypeNames.tcp];
    else return [transportTypes[0]];
  }
  return [];
};

const Webex = ({ appName, globalFilter, paLoader, appInfo, filters }) => {
  // initialize state values for charts, media type filter and transport type filter
  const [pathLoader, setPathLoader] = useState(true);
  const [networkLoader, setNetworkLoader] = useState(true);
  const [usageLoader, setUsageLoader] = useState(true);
  const [mediaType, setMediaType] = useState(null);
  const [transportType, setTransportType] = useState([]);
  const [mediaLoader, setMediaLoader] = useState(true);
  const mount = useMount();
  const chartsRef = useRef([]);
  const dataRef = useRef({
    path: { timestamp: 0, error: null, noData: true, data: {} },
    network: { timestamp: 0, error: null, noData: true, data: {} },
    usage: { timestamp: 0, error: null, noData: true, data: {} },
    media: { timestamp: 0, error: null, noData: true, data: {} },
    // media type list for media type filter
    mediaTypes: [],
    // transport type list for transport type filter
    transportTypes: [],
    // show media charts / no data available
    showMedia: false,
    // chart configurations for all the charts
    chartConfig: {}
  });

  // hover event action in path gantt chart
  const hoverCallback = (ev, curChartIns) => {
    paUtils.hoverChartsOnO365View(
      ev,
      curChartIns,
      chartsRef.current
    );
  };

  // hover event action in multi line charts
  const hoverMultiLineCharts = (ev, curChartIns) => {
    paUtils.hoverMultilineChart(ev, curChartIns, chartsRef.current);
  };

  // hover out event action in multi line charts
  const hoverOutFromMultiLineChart = () => {
    paUtils.hoverOutFromMultiLineChart(chartsRef.current);
  };

  // update chart references after the creation of a new chart
  const chartCallback = chartData => {
    chartData.yAxes.values[0].title.disabled = true;
    const chartIndex = chartsRef.current.findIndex(
      item => item.id === chartData.id
    );
    if (chartIndex !== -1) {
      delete chartsRef.current[chartIndex];
      chartsRef.current[chartIndex] = chartData;
    } else chartsRef.current.push(chartData);
  };

  // load charts data, media type filter data and transport type filter on app filter change
  useEffect(() => {
    // update state values for charts, media type filter and transport type filter
    const timestamp = Date.now();
    Object.assign(
      dataRef.current,
      {
        path: { timestamp, error: null, noData: true, data: {} },
        network: { timestamp, error: null, noData: true, data: {} },
        usage: { timestamp, error: null, noData: true, data: {} },
        media: { timestamp, error: null, noData: true, data: {} }
      }
    );
    setPathLoader(true);
    setNetworkLoader(true);
    setUsageLoader(true);
    setMediaLoader(true);
    // remove all the chart references before api call
    chartsRef.current.forEach((item, index) => {
      delete chartsRef.current[index];
    });
    chartsRef.current.splice(0);
    let initMediaType = null;
    let initTransportType = [];
    if (mount.initMount === true || filters !== null) {
      let chartConfig = {};
      let mediaTypes = [];
      let transportTypes = [];
      // update media type list and transport type list
      if (appInfo.length > 0) {
        if (Array.isArray(appInfo[0].sub_application))
          mediaTypes = appInfo[0].sub_application;
        if (Array.isArray(appInfo[0].transport_type))
          transportTypes = appInfo[0].transport_type;
        chartConfig = getWebexChartConfig(appInfo);
      }
      Object.assign(dataRef.current, {
        mediaTypes,
        transportTypes,
        showMedia: mediaTypes.length > 0 && transportTypes.length > 0,
        chartConfig
      });
      initMediaType = mediaTypes[0] || null;
      initTransportType = getTransportType(transportTypes);
      setMediaType(initMediaType);
      setTransportType(initTransportType);
    }
    if (filters !== null) {
      // get data for the charts
      getPathData(timestamp, globalFilter, filters);
      getNTData(timestamp, globalFilter, filters);
      getUsageData(timestamp, globalFilter, filters);
      loadMediaData(
        { mediaType: initMediaType, transportType: initTransportType }
      );
    }
    mount.initMount = false;
    // remove chart references before component unmount
    return () => {
      for (let index = 0; index < chartsRef.current.length; index++)
        delete chartsRef.current[index];
      chartsRef.current.splice(0);
    };
  }, [filters]);

  // validate selected media filters and load media charts data
  const loadMediaData = mediaFilters => {
    const timestamp = Date.now();
    Object.assign(
      dataRef.current.media,
      { timestamp, error: null, noData: true, data: {} }
    );
    // remove media chart references before the api call
    const transportTypes = dataRef.current.transportTypes;
    chartsRef.current.forEach((item, index) => {
      for (const value of transportTypes)
        if (item.id.startsWith(`"app360_at_site_path_${value}`)) {
          delete chartsRef.current[index];
          break;
        }
    });
    if (mediaFilters.mediaType !== null && mediaFilters.transportType.length > 0) {
      setMediaLoader(true);
      getMediaData(timestamp, globalFilter, filters, mediaFilters);
    } else setMediaLoader(false);
  };

  // reload media charts on media type selection
  const onMediaClick = event => {
    const value = event.currentTarget.value || null;
    setMediaType(value);
    loadMediaData({ mediaType: value, transportType: transportType });
  };

  // reload media charts on transport type selection change
  const onTransportSelect = event => {
    const value = event.target.value;
    const checked = event.target.checked;
    let newTransportTypes = transportType;
    if (checked === true) {
      if (transportType.every(item => item !== value))
        newTransportTypes = transportType.concat(value);
    } else if (transportType.some(item => item === value))
      newTransportTypes = transportType.filter(item => item !== value);
    if (transportType !== newTransportTypes) {
      setTransportType(newTransportTypes);
      loadMediaData(
        { mediaType: mediaType, transportType: newTransportTypes }
      );
    }
  };

  // get path chart data
  const getPathData = useCallback(async (timestamp, gFilter, filterData) => {
    const payload = {
      ...gFilter.globalV4Payload,
      system_ip: filterData.system_ip.value,
      interface: filterData.interfaces.value,
      region: filterData.serverRegion.value
    }
    if ("site_id" in payload) {
      delete payload.site_id;
    }
    const res = await getPAPathData(
      payload,
      filterData
    );
    if (
      mount.mounted === true && timestamp === dataRef.current.path.timestamp
    ) {
      if (res.errorObject instanceof Object)
        dataRef.current.path.error = res.errorObject;
      else if (res.data instanceof Object && Array.isArray(res.data.data)) {
        const [min, max] = gFilter.timeFilter.current_period;
        const interfaces = filterData.interfaces.value;
        const pathData =
          Object.fromEntries(interfaces.map(item => [item, []]));
        let noData = true;
        let pathDataArr = res.data.data
          .map((item)=>{
            return {
              fromDate: getTimestampFromUTCString(item.fromDate),
              toDate: getTimestampFromUTCString(item.toDate),
              interface: item.interface
            }
          })
          .sort((item1, item2) => item1.toDate > item2.toDate ? 1 : -1);
          pathDataArr.forEach(item => {
          if (
            item.toDate >= min && item.toDate <= max
            && Array.isArray(pathData[item.interface])
          )
            pathData[item.interface].push(item);
        });
        for (const key in pathData) {
          if (pathData[key].length === 0) delete pathData[key];
          else noData = false;
        }
        // set path chart data
        Object.assign(dataRef.current.path, { noData, data: pathData });
      }
      setPathLoader(false);
    }
  }, []);

  // get network telemetry charts data
  const getNTData = useCallback(async (timestamp, gFilter, filterData) => {
    const payload = {
      ...gFilter.globalV4Payload,
      interface: filterData.interfaces.value,
      local_system_ip: filterData.system_ip.value,
      size: 10000,
      application_group_name: filterData.feature.value,
      sort: {
          entry_ts: "desc",
          entry_datehour: "asc"
      },
      region : filterData.serverRegion.value
    };
    if ("site_id" in payload) {
      delete payload.site_id;
    }
    const res = await getPANetworkTelemetryData(
      payload,
      filterData
    );
    if (
      mount.mounted === true && timestamp === dataRef.current.network.timestamp
    ) {
      if (res.errorObject instanceof Object)
        dataRef.current.network.error = res.errorObject;
      else if (res.data instanceof Object && Array.isArray(res.data.data)) {
        const [min, max] = gFilter.timeFilter.current_period;
        const interfaces = filterData.interfaces.value;
        const networkData =
          Object.fromEntries(interfaces.map(item => [item, []]));
        let noData = true;
        res.data.data.forEach(item => {
          let entry_ts = getTimestampFromUTCString(item.entry_ts);
          if (  
            entry_ts >= min && entry_ts <= max
            && Array.isArray(networkData[item.interface])
          )
            networkData[item.interface].push({
              application_name: filterData.feature.value,
              interface: item.interface,
              vdevice_id: filterData.system_ip.value,
              latency: item.latency,
              loss: item.loss,
              entry_time:
                Math.floor(entry_ts / (1000 * 60 * 60)) * 1000 * 60 * 60
                + item.ten_min_group * 10 * 60 * 1000
            });
        });
        for (const key in networkData) {
          if (networkData[key].length === 0) delete networkData[key];
          else noData = false;
        }
        // set network telemetry charts data
        Object.assign(dataRef.current.network, { noData, data: networkData });
      }
      setNetworkLoader(false);
    }
  }, []);

  // get usage chart data
  const getUsageData = useCallback(async (timestamp, gFilter, filterData) => {
    const payload = {
      ...gFilter.globalV4Payload,
      vdevice_id: filterData.system_ip.value,
      application: appName,
      size: 10000,
      sort: {
          "entry_ts": "desc"
      },
      interface: filterData.interfaces.value,
      region_name: filterData.serverRegion.value
    }
    if ("site_id" in payload) {
      delete payload.site_id;
    }
    const res = await getPAUsageData(
      payload,
      filterData
    );
    if (
      mount.mounted === true && timestamp === dataRef.current.usage.timestamp
    ) {
      if (res.errorObject instanceof Object)
        dataRef.current.usage.error = res.errorObject;
      else if (res.data instanceof Object && res.data.data instanceof Object) {
        const [min, max] = gFilter.timeFilter.current_period;
        const interfaces = filterData.interfaces.value;
        const usageData =
          Object.fromEntries(interfaces.map(item => [item, []]));
        let noData = true;
        Object.entries(res.data.data).forEach(([key, value]) => {
          if (Array.isArray(usageData[key])) {
            const interfaceData = [];
            value.forEach(item => {
              const entry_ts = getTimestampFromUTCString(item.entry_ts);
              if (entry_ts >= min && entry_ts <= max)
                interfaceData.push({
                  entry_time: entry_ts,
                  volume: item.octets_sum,
                  interface: key
                });
            });
            usageData[key] = interfaceData.sort(
              (item1, item2) => item1.entry_time > item2.entry_time ? 1 : -1
            );
          }
        });
        for (const key in usageData) {
          if (usageData[key].length === 0) delete usageData[key];
          else noData = false;
        }
        // set usage chart data
        Object.assign(dataRef.current.usage, { noData, data: usageData });
      }
      setUsageLoader(false);
    }
  }, []);

  // get media charts data
  const getMediaData = useCallback(async (
    timestamp,
    gFilter,
    filterData,
    mediaFilters
  ) => {
    const payload = {
      ...gFilter.globalV4Payload,
      dpi_region: filterData.serverRegion.value,
      interface: filterData.interfaces.value,
      size: 10000,
      sort: {
          "entry_ts": "desc"
      },
      sub_application: mediaFilters.mediaType !== undefined ? mediaFilters.mediaType : [],
      transport_type: mediaFilters.transportType[0],
      vdevice_id: filterData.system_ip.value
    }
    const res = await getPAMediaData(
      payload
    );
    if (mount.mounted === true && timestamp === dataRef.current.media.timestamp) {
      if (res.errorObject instanceof Object)
        dataRef.current.media.error = res.errorObject;
      else {
        const [min, max] = gFilter.timeFilter.current_period;
        const interfaces = filterData.interfaces.value;
        let originData = null;
        const wxData = Object.fromEntries(interfaces.map(item => [item, []]));
        let noData = true;
        if (typeof res.data === "string")
          originData = JSON.parse(res.data.replace(/NaN/g, '""')).data;
        else if (res.data instanceof Object && Array.isArray(res.data.data)) {
          originData = res.data.data.map(item => {
            const newItem = {};
            for (const key in item)
              newItem[key] = item[key] === "NaN" ? "" : item[key];
            return {...newItem, entry_time: getTimestampFromUTCString(item.entry_ts)};
          });
        }
        if (Array.isArray(originData) && originData.length > 0) {
          const dataObj = {};
          const data = [];
          originData.forEach(item => {
            const key = item.entry_time + item.interface;
            if (dataObj[key] !== true) {
              dataObj[key] = true;
              if (item.entry_time >= min && item.entry_time <= max)
                data.push(item);
            }
          });
          data.forEach(item => {
            if (interfaces.includes(item.interface))
              wxData[item.interface].push(getLineChartFormattedData(item));
          });
        }
        for (const item in wxData) {
          if (wxData[item].length === 0) delete wxData[item];
          else noData = false;
        }
        // set media charts data
        Object.assign(dataRef.current.media, { noData, data: wxData });
      }
      setMediaLoader(false);
    }
  }, []);

  // conditions to display media charts
  const hasMedia = dataRef.current.showMedia === true
    && transportType.length > 0 && mediaType !== null;

  return (
    <div>
      <div className="section-container">
        <div className="hbr-type-h3 app360-sub-section-title">
          {chartConfig.path.title}
        </div>
        {/* path chart */}
        <div className="pa-chart">
          {paLoader === true || pathLoader === true ? (
            <Spinner />
          ) : dataRef.current.path.error !== null ? (
            <ErrorComponent
              {...dataRef.current.path.error}
              width="80px"
              className="one-line-dashlet-error"
            />
          ) : dataRef.current.path.noData === true ? (
            <div className="flex-column-full no-data-flex">
              <NoDataAvailable />
            </div>
          ) : (
            <PathChart
              title={chartConfig.path.title}
              hoverCallback={hoverCallback}
              created={chartCallback}
              timePeriod={globalFilter.timeFilter.current_period}
              data={dataRef.current.path.data}
              chartsData={chartsRef.current}
            />
          )}
        </div>
      </div>

      {/* network telemetry charts */}
      <LineCharts
        config={chartConfig.lineCharts}
        loader={paLoader === true || networkLoader === true}
        {...dataRef.current.network}
        timePeriod={globalFilter.timeFilter.current_period}
        chartsData={chartsRef.current}
        created={chartCallback}
        hoverMultiLineCharts={hoverMultiLineCharts}
        hoverOutFromMultiLineChart={hoverOutFromMultiLineChart}
        numberFormat={"#"}
      />

      {/* usage chart */}
      <LineCharts
        config={chartConfig.usageLineChart[0]}
        loader={paLoader === true || usageLoader === true}
        {...dataRef.current.usage}
        timePeriod={globalFilter.timeFilter.current_period}
        chartsData={chartsRef.current}
        created={chartCallback}
        hoverMultiLineCharts={hoverMultiLineCharts}
        hoverOutFromMultiLineChart={hoverOutFromMultiLineChart}
      />

      {/* media type filter with single selection */}
      <HbrViewSwitcher
        name="media_type_switch"
        className="section-container"
        label={i18n.app360PathAnalytics.mediaType}
        value={mediaType}
        onHbr-change={onMediaClick}
      >
        {dataRef.current.showMedia === true ?
          dataRef.current.mediaTypes.map(item => (
            <HbrRadioButton
              key={item}
              value={item}
              disabled={paLoader === true}
            >
              {convertMediaTransportNames(item)}
            </HbrRadioButton>
          )) : (<HbrRadioButton disabled>{defaultTextValue}</HbrRadioButton>)
        }
      </HbrViewSwitcher>

      {/* transport type filter with multiple selection */}
      {dataRef.current.showMedia === true ? (
        <HbrSwitchGroup
          name="transport_type_switch"
          className="section-container"
          label={i18n.app360PathAnalytics.transportType}
          onHbr-click={onTransportSelect}
        >
          {dataRef.current.transportTypes.map(item => (
            <HbrSwitch
              key={item}
              value={item}
              checked={transportType.includes(item)}
              disabled={paLoader === true}
            >
              {convertMediaTransportNames(item)}
            </HbrSwitch>
          ))}
        </HbrSwitchGroup>
      ) : (
        <HbrSwitchGroup
          className="section-container"
          label={i18n.app360PathAnalytics.transportType}
        >
          <HbrSwitch checked={false} disabled>{defaultTextValue}</HbrSwitch>
        </HbrSwitchGroup>
      )}

      {/* charts based on selected one media type and one or more transport types */}
      {(hasMedia ? transportType : [dataRef.current.transportTypes[0] || 0])
        .map(item => (
          <LineCharts
            key={item}
            config={hasMedia === true ?
              dataRef.current.chartConfig[mediaType][item]
              : webExStaticData}
            loader={paLoader === true || mediaLoader === true}
            {...dataRef.current.media}
            error={hasMedia === true ? dataRef.current.media.error : null}
            noData={hasMedia === true ? dataRef.current.media.noData : true}
            timePeriod={globalFilter.timeFilter.current_period}
            chartsData={chartsRef.current}
            created={chartCallback}
            hoverMultiLineCharts={hoverMultiLineCharts}
            hoverOutFromMultiLineChart={hoverOutFromMultiLineChart}
            numberFormat={"#"}
          />
        ))
      }
    </div>
  );
};

Webex.propTypes = {
  appName: PropTypes.string.isRequired,
  globalFilter: PropTypes.object.isRequired,
  paLoader: PropTypes.bool.isRequired,
  appInfo: PropTypes.arrayOf(PropTypes.object).isRequired,
  filters: PropTypes.object
};

export default Webex;
