import i18nMessageBundle from "amdi18n-loader!../../nls/i18n";
import { getLocalStorageFlag } from "../../../utils/common";

const tabList = [
  {
    label: i18nMessageBundle.app360TabViewSites,
    name: "sites",
    default: true
  },
  {
    label: i18nMessageBundle.app360TabViewAppFlow,
    name: "appFlow"
  },
  {
    label: i18nMessageBundle.app360TabViewTopClients,
    name: "topUsers"
  }
];

const specialAppList = [
  {
    label: i18nMessageBundle.app360TabViewPathScore,
    name: "pathScore",
    isVisible: () => getLocalStorageFlag("PathAnalyticsType") === "PS"
  }
  // Uncomment this if you want to show Partner Data Exchange and Edge Data Exchange tabs
  // {
  //   label: i18nMessageBundle.app360TabViewPartnerDataExchange,
  //   name: "partnerDataExchange"
  // },
  // {
  //   label: i18nMessageBundle.app360TabViewEdgeDataExchange,
  //   name: "edgeDataExchange"
  // }
];

export const o365AppList = [
  "ms-office-365",
  "ms-office-web-apps",
  "ms-stream",
  "share-point",
  "ms-live-accounts",
  "skydrive",
  "skype",
  "outlook-web-service",
  "groove",
  "ms-services",
  "ms-update",
  "ms-lync",
  "ms-lync-audio",
  "ms-lync-control",
  "ms-lync-video",
  "ms-teams",
  "ms-teams-app-sharing",
  "ms-teams-audio",
  "ms-teams-media",
  "ms-teams-video"
];

export const webExAppList = ["webex-media"];

export const getTabList = isSpecialApp => {
  let listReturned = [...tabList];
  if (isSpecialApp) {
    const _specialAppList = specialAppList.filter(n => n.isVisible());
    listReturned = [...listReturned, ..._specialAppList];
  }
  return listReturned;
};
