import React from "react";
import { PropTypes } from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import reduxContext from "../reducer";
import * as actions from "../actions";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import AppHOC from "../../../generics/AppHOC";
import Spinner from "../../../common/Spinner";
import SitesDropdownWidget from "../../../generics/SitesDropdownWidget";
import TimeFilter from "../../../generics/TimeFilter";
import TimeStamp from "../../../generics/TimeStamp";
import OverlayedLineChart from "../../../common/OverlayedLineChart";
import _ from "lodash";
import {
  hasFilterChanged,
  qoeRanges,
  getCustomListApi,
  compareAndMatchELT,
  getTimeInterval,
  getIsHourlyData
} from "../../../utils/common";
import { formatNumber } from "../../../utils/format";
import apiService from "../../../config/api-config";
import i18n from "amdi18n-loader!../../nls/i18n";
import css from "../app360MagneticStyle.less";
import { BASE_URL } from "../../../apis/apiConstants";
import Application360AtSite from "../atSite";
import { defaultCellValue } from "../../../utils/enums";
import { getTimestampFromUTCString } from "../../../utils/displayTime";
import QoEScoreComponent from "../../../common/QoEScoreComponent";

const [
  HbrIcon,
  HbrCard,
  HbrBreadcrumb,
  HbrBreadcrumbItem
] = reactWrapper([
  "hbr-icon",
  "hbr-card",
  "hbr-breadcrumb",
  "hbr-breadcrumb-item"
]);

const yAxisProps = {
  valueY: ["vqoe_score"],
  yAxisTitle: i18n.qoeScore,
  yAxisminGridDistance: 80
};
const seriesNames = { [yAxisProps.valueY[0]]: yAxisProps.yAxisTitle };

class FilterView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: this.props.globalFilter.selectedSite < 0,
      data: [],
      isGrayApplication: false,
      appScore: "-",
      appScoreChange: 0,
      appDataRender: false,
      loader: true
    };
  }

  getData = () => {
    if (
      Array.isArray(this.props.globalFilter.app_data_with_family_sla) &&
      !this.state.appDataRender
    ) {
      const appName = this.props.match.params.appName;
      let app = this.props.globalFilter.app_data_with_family_sla
        .find(row => row.application === appName);
      const element = document.getElementsByClassName("react-grid-layout")[0];
      if (element) {
        if (
          this.props.globalFilter.selectedSite === -1 && app !== null
          && app?.vqoe_status === "unknown"
        ) {
          /* This block of code is to hide and show QoE chart based on gray application or not */
          element.style.height = "66px";
          element.firstChild.style.height = "97px";
          element.firstChild.style.zIndex = "99";
        } else {
          element.style.height = "300px";
          element.firstChild.style.height = "300px";
        }
      }
      this.setState({
        isGrayApplication: app?.vqoe_status === "unknown",
        appScore:
          app === undefined
            ? 0
            : app.vqoe_score === defaultCellValue
              ? defaultCellValue
              : formatNumber(app.vqoe_score),
        appScoreChange:
          app === undefined
            ? 0
            : app.vqoe_change === defaultCellValue
              ? defaultCellValue
              : formatNumber(app.vqoe_change, "changeValue"),
        appDataRender: true,
        loader: false
      });
    } else if (
      !Array.isArray(this.props.globalFilter.app_data_with_family_sla)
    ) this.setState({ loader: true });
  };

  componentDidMount() {
    this.getData();
    if (this.props && this.props.globalFilter) {
      if (this.props.globalFilter.selectedSite < 0) this.getChartQOE();
      if (this.props.globalFilter.selectedSite == -1)
        this.props.app360.isApp360PageView = true;
    }
  }

  getCustomData = (props) => {
    this.props.setAppsWithFamilySLA(undefined);

    getCustomListApi(props);
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.globalFilter.currentTimeStamp !==
      this.props.globalFilter.currentTimeStamp
    ) {
      this.getCustomData(this.props);
      this.setState({
        appDataRender: false
      });
    }

    if (hasFilterChanged(this.props.globalFilter, prevProps.globalFilter)) {
      this.setState({
        appDataRender: false
      });
    }

    if (
      !_.isEqual(
        this.props.globalFilter.app_data_with_family_sla,
        prevProps.globalFilter.app_data_with_family_sla
      )
    ) {
      this.setState({
        appDataRender: false
      });
      this.getData();
    }
    if (hasFilterChanged(this.props.globalFilter, prevProps.globalFilter)) {
      if (this.props.globalFilter.selectedSite < 0) this.getChartQOE();
    }
  }

  async getChartQOE() {
    const v4payload = this.props.globalFilter.globalV4Payload;
    this.setState({ loading: true });
    const QOEChartConfig = {
      ...v4payload,
      application: [this.props.match.params.appName],
      sort: { entry_ts: "asc" }
    };
    let data = [];
    if (!this.props.app360.isApp360PageView) {
      const res = await apiService.getAggregateData(QOEChartConfig);
      if (
        res.errorObject instanceof Object === false
        && res.data instanceof Object
        && Array.isArray(res.data.data)
        && res.data.data.length > 0
      ) {
        const timeInterval = getTimeInterval(v4payload.time_frame);
        data = res.data.data;
        for (const item of data)
          item.date = getTimestampFromUTCString(item.entry_ts);

        const newEntries = [];
        const lastIndex = data.length - 1;
        data.forEach((entry1, dataIndex) => {
          if (dataIndex < lastIndex) {
            const entry2 = data[dataIndex + 1];
            /* add an entry to extend lines till current entry's end range if
            next entry is after an hour */
            if (entry2.date > entry1.date + timeInterval)
              newEntries.push({ ...entry1, date: entry1.date + (timeInterval - 1) });
          }
          else {
            if (getIsHourlyData(v4payload.time_frame))
              data = compareAndMatchELT(data, this.props.globalFilter.timeFilter);
            else
              newEntries.push({ ...entry1, date: entry1.date + (timeInterval - 1) });
          }
        });
        // add new records to graph data
        data.push(...newEntries);
        // sort graph data based on entry time
        data.sort((item1, item2) => item1.date > item2.date ? 1 : -1);
      }
      this.setState({ data, loading: false });
      this.props.app360.isApp360PageView = false;
    }
  }

  render() {
    const { appName } = this.props.match.params;
    // @TODO : display 404 if not a valid app name
    return (
      <div className={css.filter} data-cy="app360_filter">
        <div className="flex-column header-filters-container">
          <HbrBreadcrumb>
            <HbrBreadcrumbItem
              onClick={() => this.props.history.push(`${BASE_URL}/applications`)}
            >
              <div className="flex-items">
                <HbrIcon name="arrow-left" />
                {i18n.appViewApplication}
              </div>
            </HbrBreadcrumbItem>
          </HbrBreadcrumb>
          <div className="flex-items header-filters-bar">
            <div className="flex-items header-filters">
              <div className="hbr-type-h1 app360-title">{appName}</div>
              <div className="header-sites">
                <SitesDropdownWidget {...this.props} />
              </div>
              <TimeFilter {...this.props} />
            </div>
            <div className="header-timestamp">
              <TimeStamp {...this.props} />
            </div>
          </div>
        </div>
        {this.props.globalFilter.selectedSite < 0 &&
          !this.state.isGrayApplication && (
            <HbrCard
              id="non-interactive"
              slot="label"
              className="widget-container qoe-chart"
              container
            >
              {this.state.loading ? (
                <Spinner />
              ) : (
                <div className="flex-column">
                  <div className="flex-items qoe-info">
                    <div className="hbr-type-h2">
                      {i18n.syncronizedChartQoETitle}
                    </div>
                    <QoEScoreComponent
                      appScoreChange={this.state.appScoreChange}
                      appScore={this.state.appScore}
                      tooltipInfo={i18n.app360TitleIcon}
                    />
                  </div>
                  <OverlayedLineChart
                    data={this.state.data}
                    qualityRange={qoeRanges}
                    range={{
                      yAxes: {
                        min: 0,
                        max: 10
                      }
                    }}
                    chartPadding={{
                      top: 15,
                      left: 2,
                      right: 30
                    }}
                    yAxisConfig={yAxisProps}
                    seriesNames={seriesNames}
                    min={
                      this.props.globalFilter.timeFilter.current_period[0]
                    }
                    max={
                      this.props.globalFilter.timeFilter.current_period[1]
                    }
                    hideLegend={true}
                  />
                </div>
              )}
            </HbrCard>
          )}{" "}
        {this.props.globalFilter.selectedSite !== -1 && (
          <div className={css["app-360-at-site"]}>
            <Application360AtSite
              globalFilter={this.props.globalFilter}
              appName={appName}
              setPathAnalyticsTab={this.props.setPathAnalyticsTab}
            />
          </div>
        )}
      </div>
    );
  }
}

FilterView.propTypes = {
  actions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  globalFilter: PropTypes.object.isRequired,
  app360: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  setPathAnalyticsTab: PropTypes.func,
  setAppsWithFamilySLA: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = state => ({
  app360: state.vanalytics.app360
});

export default reduxContext.withProvider(
  connect(mapStateToProps, mapDispatchToProps)(AppHOC(FilterView))
);
