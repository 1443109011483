/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import { DtableWrapper } from "dnx-web-components/dist/index-with-deps-react.min";
import table from "dnx-web-components/dist/table.min";
import AppHOC from "../../../generics/AppHOC";
import SnailTrail from "../../../common/SnailTrail";
import { enableTunnelSelection } from "../../../utils/common";
import { formatNumberNDecimalPlaces } from "../../../utils/format";
import i18n from "amdi18n-loader!../../nls/i18n";
import css from "../app360MagneticStyle.less";

const AppFlowTable = props => {
  const [showSnailTrail, setShowSnailTrail] = useState(false);
  const [selectedTunnels, setSelectedTunnels] = useState(props.topTunnels);
  const { reportView } = props;
  let selectedRows = [];
  const DnxTable = DtableWrapper(table);
  const dTableRef = useRef(null);
  useEffect(() => {
    if (dTableRef.current) {
      document.body.addEventListener("click", handleActionClick);
    }

    return () => {
      document.body.removeEventListener("actionclick", handleActionClick);
    };
  }, []);

  useEffect(() => {
    if (props.topTunnels) {
      if (dTableRef.current && props.topTunnels && props.showSnailTrailAction) {
        dTableRef.current.table.selectRows(props.topTunnels);
      }
    }
  }, [
    props && props.topTunnels,
    dTableRef && dTableRef.current,
    showSnailTrail
  ]);

  const handleActionClick = async event => {
    if (event.target.dataset.name === "snail-trail-actions") {
      const taskId = event.target.dataset.taskid;

      setSelectedTunnels([taskId]);
      setShowSnailTrail(true);
    }
  };

  const getTableProps = () => {
    const getColumns = [
      {
        data: "site_id",
        title: i18n.site,
        searchable: true
      },
      {
        data: "city",
        title: reportView ? i18n.hostLocationForReports : i18n.hostLocation,
        searchable: true,
        render: data => {
          if (!data) return "NA";
          return data;
        }
      },
      {
        data: "tunnel_name",
        title: i18n.hostTunnelName,
        searchable: true,
        searchPopover: true
      },
      {
        data: "usage",
        title: i18n.applicationOverviewLabelUsage,
        searchable: true,
        orderData: props.globalFilter.selectedSite > 0 ? 5 : 4
      },
      { data: "octets_sum", visible: false },
      {
        data: "vqoe_score",
        title: reportView ? i18n.appflowQoeScoreForReports : i18n.appflowQoeScore,
        searchable: true,
        render: data => formatNumberNDecimalPlaces(data, 1)
      },
      {
        data: "loss_percentage",
        title: reportView ? i18n.app360LossForReports : i18n.app360Loss,
        searchable: true,
        render: data => formatNumberNDecimalPlaces(data, 1)
      },
      {
        data: "latency",
        title: reportView ? i18n.app360LatencyForReports : i18n.app360Latency,
        searchable: true,
        render: data => formatNumberNDecimalPlaces(data, 1)
      },
      {
        data: "jitter",
        title: reportView ? i18n.app360JitterForReports : i18n.app360Jitter,
        searchable: true,
        render: data => formatNumberNDecimalPlaces(data, 1)
      },
      {
        data: "local_system_ip",
        title:  reportView ? i18n.app360AppFlowLocalDeviceForReports : i18n.app360AppFlowLocalDevice,
        searchable: true,
        searchPopover: true
      },
      {
        data: "remote_system_ip",
        title: reportView ? i18n.app360AppFlowRemoteDeviceForReports : i18n.app360AppFlowRemoteDevice,
        searchable: true,
        searchPopover: true
      },
      {
        data: "local_color",
        title: reportView ? i18n.app360AppFlowLocalColorForReports : i18n.app360AppFlowLocalColor,
        searchable: true
      },
      {
        data: "remote_color",
        title: reportView ? i18n.app360AppFlowRemoteColorForReports : i18n.app360AppFlowRemoteColor,
        searchable: true
      }
    ];

    const columns = getColumns;

    if (props.showSnailTrailAction && columns) {
      const columnsConfig = {
        data: "action",
        render: function (data, type, row) {
          const isNotDiaorSig = enableTunnelSelection(row);
          return type === "display" && isNotDiaorSig
            ? `
            <button
            type="button"
            name="edit"
            id="${row.tunnel_name}"
            class="btn wc-btn--link active-icon table-action"
            role="link"
            style="padding: 0px; min-width: auto; color: var(--primary-color, #049fd9);border: none;background: none;">
            <i data-taskId="${row.tunnel_name}" data-name="snail-trail-actions"
            class="dnac-icon-pathTrace-horizontal-sm" style="font-size: 16px; cursor: pointer; width: 36px; height: 20px; padding-left: 28px;"
            id=${row.tunnel_name}"
          ></i>
            </button>
                `
            : "--";
        }
      };

      columns.push(columnsConfig);
    }
    const appFlowData =
      props.reportView === true ? props.data : props.data.app360.appflow_data;
    const siteData =
      props.reportView === true
        ? props?.globalFilter?.siteAPIData?.sitesTableData
        : props.data.globalFilter.siteAPIData.sitesTableData.data;
    if (appFlowData && siteData) {
      appFlowData.forEach(item1 => {
        const matchingSite = siteData.find(
          item2 => item1.site_id === item2.site_id
        );
        if (matchingSite) {
          item1.city = matchingSite.city; //Getting location for App low table from Site Data
        }
      });
    }

    const reportTableConfig = {
      data: appFlowData,
      columns: columns,
      idData: "tunn",
      dtOptions: {
        paging: false,
        stateSave: true,
        stateDuration: 0
      }
    };

    const tableConfig = {
      data: appFlowData,
      columns: columns,
      idData: "tunnel_name",
      columnSearch: true,
      tools: [
        {
          ref: {
            name: "title",
            config: {
              text: i18n.tunnels,
              count: true
            }
          }
        },
        {
          ref: {
            name: "export",
            config: {
              title: i18n.tunnelsData,
              customize: (csvString, { csvParser }) => {
                const data = csvParser.csvStringToArray(csvString);
                for (const item of data) item.splice(4, 1);
                return csvParser.arrayToCsvString(data);
              }
            }
          },

          level: "secondary",
          justify: "start"
        },
        {
          ref: {
            name: "search"
          }
        }
      ],
      dtOptions: {
        scrollY: 300,
        scrollX: true,
        stateSave: false,
        order: [[5, "asc"]]
      }
    };

    if (props.globalFilter.selectedSite > 0) {
      return {
        ...tableConfig,
        rowSelect: true,
        multiSelectLimit: 5,
        rowSelectCallback: (item, itemList, itemObj) => {
          selectedRows = [];
          const deleteSwitch = document.getElementsByName("pathTrace")[0] || {};
          if (itemList && itemList.length == 0) {
            deleteSwitch.disabled = true;
          } else {
            deleteSwitch.disabled = false;
          }
          if (item == "SELECT_ALL") {
            selectedRows = "SELECT_ALL";
          }
          if (
            item != "SELECT_ALL" &&
            selectedRows.indexOf(itemObj.tunnel_name) == -1 &&
            itemList &&
            itemList.length != 0
          ) {
            selectedRows.push(itemList);
          }
        },
        rowSelectFilter: ({ data }) => enableTunnelSelection(data)
      };
    }
    return reportView ? reportTableConfig : tableConfig;
  };

  let tableProps = getTableProps();
  const onCloseCallback = hover => {
    setSelectedTunnels(props.topTunnels);
    if (props.topTunnels) {
      if (dTableRef.current && props.topTunnels && props.showSnailTrailAction) {
        dTableRef.current.table.selectRows(props.topTunnels);
      }
    }
    setShowSnailTrail(false);
    setSelectedTunnels([]);
    if (hover) {
      setSelectedTunnels([]);
    }
  };

  if (props.showApplyFilter && tableProps && tableProps.tools) {
    tableProps.tools.push({
      ref: {
        name: "actions",
        config: {
          actions: [
            {
              id: "granularity",
              label: i18n.apply,
              action: () => {
                props.appFlowSelectionCallback(selectedRows[0]);
              }
            }
          ]
        }
      }
    });
  }

  if (props.showSnailTrailAction && tableProps && tableProps.tools) {
    let toolsConfig = {
      name: "actions",
      config: {
        actions: [
          {
            id: "pathTrace",
            label: "View Path Trace",
            action: () => {
              let selectedTunnelNamesList =
                selectedRows == "SELECT_ALL"
                  ? props.topTunnels
                  : selectedRows[0];
              setSelectedTunnels(selectedTunnelNamesList);
              setShowSnailTrail(true);
            }
          }
        ]
      }
    };
    if (props.topTunnels && props.topTunnels.length == 0) {
      (toolsConfig["config"]["disabled"] = ["pathTrace"]),
        (toolsConfig["config"]["actionsFilter"] = ["pathTrace"]);
    }
    tableProps.tools.push({
      ref: toolsConfig
    });
  }
  if (!showSnailTrail) {
    return (
      <div className={css["appflow-table"]} data-cy="appflowTableView">
        <div
          className="dnx--css_table-container commonTableStyle"
          style={{
            maxWidth: "100vw"
          }}
        >
          <DnxTable ref={dTableRef} {...tableProps} />
        </div>
      </div>
    );
  }

  if (showSnailTrail) {
    return (
      <div>
        <SnailTrail
          {...props}
          onCloseCallback={onCloseCallback}
          selectedTunnels={
            selectedTunnels && selectedTunnels.length > 0
              ? selectedTunnels
              : props.topTunnels
          }
        />
      </div>
    );
  }
};

AppFlowTable.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  appFlowSelectionCallback: PropTypes.func,
  showApplyFilter: PropTypes.bool,
  reportView: PropTypes.bool,
  topTunnels: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  showSnailTrailAction: PropTypes.bool,
  globalFilter: PropTypes.object
};

export default AppHOC(AppFlowTable);
