export const init = initialFilter => initialFilter;

export const reducer = (state, action) => {
  let s = state;

  switch (action.type) {
    case "overview":
      s = "overview";
      break;

    case "topSites":
      s = "topSites";
      break;
  }

  return s;
};
