import React, { useState } from "react";
import { PropTypes } from "prop-types";

import i18nMessageBundle from "amdi18n-loader!../../nls/i18n";
import reactWrapper from "@harbor/elements/utils/react/wrapper";

import css from "../app360MagneticStyle.less";

const [HbrViewSwitcher, HbrRadioButton] = reactWrapper([
  "hbr-view-switcher",
  "hbr-radio-button"
]);

const SitesFilter = props => {
  const {
    app360AllLabel,
    app360PoorLabel,
    app360FairLabel,
    app360GoodLabel,
    app360UnknownLabel
  } = i18nMessageBundle;

  const TOGGLER = {
    ALL: "all",
    POOR: "poor",
    FAIR: "fair",
    GOOD: "good",
    NO_DATA: "no_data",
    UNKNOWN: "unknown"
  };

  const [toggle, setToggle] = useState(TOGGLER.ALL);

  const handleChange = evt => {
    const swtch = evt.currentTarget;
    setToggle(swtch.value);
    props.table.props.onFilterToggle(swtch.value);
  };

  return (
    <div className={css["site-filter-toggler"]}>
      <HbrViewSwitcher value={toggle} onClick={handleChange}>
        <HbrRadioButton value={TOGGLER.ALL}>{app360AllLabel}</HbrRadioButton>
        <HbrRadioButton value={TOGGLER.GOOD}>{app360GoodLabel}</HbrRadioButton>
        <HbrRadioButton value={TOGGLER.FAIR}>{app360FairLabel}</HbrRadioButton>
        <HbrRadioButton value={TOGGLER.POOR}>{app360PoorLabel}</HbrRadioButton>
        <HbrRadioButton value={TOGGLER.UNKNOWN}>
          {app360UnknownLabel}
        </HbrRadioButton>
      </HbrViewSwitcher>
    </div>
  );
};

SitesFilter.propTypes = {
  table: PropTypes.object
};

export default SitesFilter;
