import i18n from "amdi18n-loader!../../../../nls/i18n";
import { formatSizeUnitsRound } from "../../../../../utils/format";

export const chartConfig = {
  path: {
    title: i18n.app360PathAnalytics.path,
    fields: { time: "entry_time", status: "status" }
  },
  appScore: {
    title: i18n.app360PathAnalytics.appScore,
    data: [
      {
        label: i18n.app360PathAnalytics.sdWanAppScore,
        fields: { value: "status", score: "score" }
      },
      {
        label: i18n.app360PathAnalytics.msftAppScore,
        fields: { value: "msft_user_qoe_score" }
      }
    ]
  },
  usageLineChart: [
    {
      title: i18n.app360PathAnalytics.usage,
      data: [
        {
          title: i18n.app360PathAnalytics.usage,
          label: i18n.app360PathAnalytics.usage,
          isUsageChart: true,
          fields: { value: "volume" },
          unitFn: formatSizeUnitsRound
        }
      ]
    }
  ],
  networkTelemetry: {
    title: i18n.app360PathAnalytics.networkTelemetry,
    data: [
      {
        title: i18n.app360PathAnalytics.latency,
        label: i18n.applicationTableViewLatency,
        fields: { value: "latency" },
        unit: i18n.app360PathAnalytics.ms
      },
      {
        title: i18n.app360PathAnalytics.loss,
        label: i18n.applicationTableViewLoss,
        fields: { value: "loss" },
        unit: "%"
      }
    ]
  },
  LineCharts: [
    {
      title: i18n.app360PathAnalytics.microsoftTelemetry,
      data: [
        {
          title: i18n.app360PathAnalytics.latency,
          label: i18n.applicationTableViewLatency,
          fields: { value: "msft_avg_latency" },
          unit: i18n.app360PathAnalytics.ms
        }
      ]
    },
    {
      title: i18n.app360PathAnalytics.networkDelay,
      data: [
        {
          title: i18n.app360PathScoreCND,
          label: "CND",
          fields: { value: "artcxp_avg_cnd" }
        },
        {
          title: i18n.app360PathScoreSND,
          label: "SND",
          fields: { value: "artcxp_avg_snd" }
        }
      ]
    }
  ]
};
