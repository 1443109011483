import React, { useRef, useEffect, useState } from "react";
import { PropTypes } from "prop-types";

import ReactDOM from "react-dom";
import { GeoMap } from "@cisco/geomaps";
import {
  formatNumberNDecimalPlaces,
  formatBytes,
  formatString
} from "../../../../utils/format";
import { getColor } from "../../../../utils/colors";
import i18nMessageBundle from "amdi18n-loader!../../../nls/i18n";
import Spinner from "../../../../common/Spinner";
import { colors } from "../../../../utils/colors";
import { defaultCellValue } from "../../../../utils/enums";

const GeoMapComponent = props => {
  const { sites, options, reportView } = props;
  const {
    customMarker,
    disableClustering,
    experienceColors: EXPERIENCE
  } = options;

  const mapVisualRef = useRef(null);
  const mapRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const POINT_STATE = {
    OK: "ok",
    WAITING: "waiting",
    DOWN: "down",
    UNKNOWN: "unknown"
  };

  const getDefaultMarkerOptions = experience => {
    let color = colors.gray15;

    switch (experience) {
      case EXPERIENCE.GOOD:
        color = EXPERIENCE.GOOD;
        break;

      case EXPERIENCE.FAIR:
        color = EXPERIENCE.FAIR;
        break;

      case EXPERIENCE.POOR:
        color = EXPERIENCE.POOR;
        break;
      case EXPERIENCE.UNKNOWN:
        color = EXPERIENCE.UNKNOWN;
        break;
    }

    return {
      color: color
    };
  };

  const getClusterOptions = clusterOption => {
    let clusterOptions = {
      ok: 0,
      waiting: 0,
      down: 0,
      unknown: 0
    };
    switch (clusterOption) {
      case EXPERIENCE.GOOD:
        clusterOptions = { ...clusterOptions, ok: 1 };
        break;

      case EXPERIENCE.FAIR:
        clusterOptions = { ...clusterOptions, waiting: 1 };
        break;

      case EXPERIENCE.POOR:
        clusterOptions = { ...clusterOptions, down: 1 };
        break;

      case EXPERIENCE.UNKNOWN:
        clusterOptions = { ...clusterOptions, unknown: 1 };
        break;
    }

    return clusterOptions;
  };

  const getExperienceColorOnScore = (qoe, vqoe_status) => {
    let expColor = "";
    const { GOOD, FAIR, POOR, UNKNOWN } = EXPERIENCE;

    if (vqoe_status === "unknown" && qoe === 0) {
      return UNKNOWN;
    }

    if (qoe >= 8 && qoe <= 10) {
      expColor = GOOD;
    } else if (qoe >= 5 && qoe < 8) {
      expColor = FAIR;
    } else if (qoe >= 0 && qoe < 5) {
      expColor = POOR;
    } else if (qoe == "-") {
      expColor = UNKNOWN;
    } else {
      expColor = GOOD;
    }

    return expColor;
  };

  const pointsToShow = sites.map(
    ({ site_id, latitude, longitude, vqoe_score, usage, vqoe_status }, index) => {
      const experience = getExperienceColorOnScore(vqoe_score, vqoe_status);

      const snoToShow = index + 1;

      if (isNaN(longitude))
        longitude = 0;
      if (isNaN(latitude))
        latitude = 0;

      let defaultPointingOptions = {
        id: crypto.randomUUID(),
        siteId: site_id,
        location: [parseFloat(longitude).toFixed(2), parseFloat(latitude).toFixed(2)],
        qoeScore: vqoe_status === "unknown" ? defaultCellValue : vqoe_score,
        usage: usage,
        clusterOptions: getClusterOptions(experience),
        getMarker: () => {
          const color = getDefaultMarkerOptions(experience);
          let svgToRender = ``;
          if (customMarker === "CIRCLE") {
            svgToRender = `<svg width="20" height="20">
            <circle cx='10' cy='10' r='10' fill='${color.color}' />
          </svg>`;
          } else {
            svgToRender = `<svg width="50" height="20">
            <rect x="15" y="0" width="45" height="18" style="fill:${color.color};stroke-width:3;stroke:${color.color}" />
            <circle cx="10" cy="10" r="10" stroke="${colors.gray100}" stroke-width="3" fill="${color.color}" />
            <circle cx="10" cy="10" r="7" fill="${colors.gray100}" />
            <text x='25' y='15' font-size='12' fill="${colors.gray100}" stroke='${colors.gray100}' stroke-width='1'>#  ${snoToShow}</text>
          </svg>`;
          }
          const el = document.createElement("div");
          el.innerHTML = svgToRender;
          return el;
        },
      };
      return defaultPointingOptions;
    }
  );

  const getClusterMarkerProps = () => {
    return {
      stateColorMap: {
        ok: EXPERIENCE.GOOD,
        waiting: EXPERIENCE.FAIR,
        down: EXPERIENCE.POOR,
        unknown: EXPERIENCE.UNKNOWN
      }
    };
  };

  const getClusterProperties = () => {
    return {
      ok: ["+", ["get", POINT_STATE.OK]],
      waiting: ["+", ["get", POINT_STATE.WAITING]],
      down: ["+", ["get", POINT_STATE.DOWN]],
      unknown: ["+", ["get", POINT_STATE.UNKNOWN]]
    };
  };

  const getMapSettings = () => {
    return {
      zoom: 1,
      center: [0, 0],
      preserveDrawingBuffer: true
    };
  };

  const getSiteDetails = siteId => {
    if (siteId) {
      props.setSelectedSite(parseInt(siteId));
    }
  };

  const getToolTipSettings = () => {
    return {
      showTooltipOnClusterClick: false,
      tooltipOnHover: true
    };
  };

  const getLegend = () => {
    const content = document.createElement("div");
    let cnt  = <div>
    <div className='legends-details-container'>
      <div className='legendsvalue high'>&nbsp;</div> 
      <div className='hbr-type-body3'>{i18nMessageBundle.sitesGood}</div>
    </div>
    <div className='legends-details-container'>
      <div className='legendsvalue fair'>&nbsp;</div>
      <div className='hbr-type-body3'>{i18nMessageBundle.sitesFair}</div>
    </div>
    <div className='legends-details-container'>
      <div className='legendsvalue low'>&nbsp;</div>
      <div className='hbr-type-body3'>{i18nMessageBundle.sitesPoor}</div>
    </div>
    <div className='legends-details-container'>
      <div className='legendsvalue unknown'>&nbsp;</div>
      <div className='hbr-type-body3'>{i18nMessageBundle.sitesUnknown}</div>
    </div>
  </div>;
    ReactDOM.render(cnt, content);
    return content;
  };

  const getTooltipContent = (id, data) => {
    const el = document.createElement("div");
    let toolTip = undefined;
    if (data?.props?.cluster) {
      const poorQoeWithinCluster = data.props.down;
      const fairQoeWithinCluster = data.props.waiting;
      const goodQoeWithinCluster = data.props.ok;
      const unknownQoeWithinCluster = data.props.unknown;

      toolTip = (
        <div id={id} className="common-map-sites-tooltip-cluster">
          <div className="heading">
            {data.props.point_count} {i18nMessageBundle.geoMapSitesLabel}
          </div>
          <div className="details">
            <div className="details-high">
              <div className="details-high-label hbr-type-body3">
                {i18nMessageBundle.applicationOverviewLabelGood}
              </div>
              <div className="details-high-value">
                <div className="details-high-value-icon">
                  &nbsp;
                </div>
                <div className="hbr-type-body3">
                  {goodQoeWithinCluster}
                </div>
              </div>
            </div>
            <div className="details-fair">
              <div className="details-fair-label hbr-type-body3">
                {i18nMessageBundle.geoMapFair}
              </div>
              <div className="details-fair-value">
                <div className="details-fair-value-icon">
                  &nbsp;
                </div>
                <div className="hbr-type-body3">
                  {fairQoeWithinCluster}
                </div>
              </div>
            </div>

            <div className="details-poor">
              <div className="details-poor-label hbr-type-body3">
                {i18nMessageBundle.sitesPoor}
              </div>
              <div className="details-poor-value">
                <div className="details-poor-value-icon">
                  &nbsp;
                </div>
                <div className="hbr-type-body3">
                  {poorQoeWithinCluster}
                </div>
              </div>
            </div>

            <div className="details-unknown">
              <div className="details-unknown-label hbr-type-body3">
                {i18nMessageBundle.app360UnknownLabel}
              </div>
              <div className="details-unknown-value">
                <div className="details-unknown-value-icon">
                  &nbsp;
                </div>
                <div className="hbr-type-body3">
                  {unknownQoeWithinCluster}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (data && data?.node && data?.node?.siteId) {
      const { id, siteId, site_name, usage, qoeScore } = data?.node;
      const iconWithColorStyle = {
        backgroundColor: getColor(
          formatNumberNDecimalPlaces(qoeScore, 1),
          "qoe"
        ),
      };
      const siteNameDisplay = formatString(site_name,21);
      toolTip = (
        <div id={id} className="common-map-sites-tooltip-single-site">
          <div className="site-details">
            <div className="site-details-qoe">
              <div className="site-details-qoe-label hbr-type-body3">
                {i18nMessageBundle.app30SiteName}
              </div>
              <div className="site-details-qoe-value">
                <div className="hbr-type-body3">
                  <a className="hbr-type-body3 sitename" data-cy={id} onClick={() => getSiteDetails(siteId)}>
                    {siteNameDisplay}
                  </a>
                </div>
              </div>
            </div>
            <div className="site-details-qoe">
              <div className="site-details-qoe-label hbr-type-body3">
                {i18nMessageBundle.geoMapQoE}
              </div>
              <div className="site-details-qoe-value">
                <div
                  className="site-details-qoe-value-icon"
                  style={iconWithColorStyle}
                >
                  &nbsp;
                </div>
                <div className="hbr-type-body3">
                  {formatNumberNDecimalPlaces(qoeScore, 1)}
                </div>
              </div>
            </div>
            <div className="site-details-usage">
              <div className="site-details-usage-label hbr-type-body3">
                {i18nMessageBundle.geoMapUsage}
              </div>
              <div className="hbr-type-body3">
                {formatBytes(usage)}
              </div>
            </div>
          </div>
        </div>
      );
    }
    ReactDOM.render(toolTip, el);
    return el;
  };

  const createMapViual = () => {
    let defaultInitProperties = {
      domHolder: mapVisualRef.current,
      trackResize: true,
      enableNav: reportView ? false : true,
      enableLegend: true,
      points: pointsToShow,
      disableClustering: disableClustering,
      mapSettings: getMapSettings(),
      tooltipSettings: getToolTipSettings(),
      getLegendContent: getLegend,
      getTooltipContent: getTooltipContent,
      clusterProperties: getClusterProperties(),
      clusterMarkerProps: getClusterMarkerProps(),
    };
    mapRef.current = new GeoMap(defaultInitProperties);
    mapRef.current?.init();
    mapRef.current?.fitBounds(100);
  };

  useEffect(() => {
    createMapViual();
    return () => {
      mapRef.current = null;
      mapVisualRef.current = null;
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    if (mapRef.current !== null) {
      setTimeout(() => {
        const points = sites
          .filter((r) => r.latitude && r.longitude)
          .map(({ site_id, site_name, latitude, longitude, vqoe_score, usage, vqoe_status }, index) => {
            const experience = getExperienceColorOnScore(vqoe_score, vqoe_status);
            const snoToShow = index + 1;
            return {
              id: crypto.randomUUID(),
              siteId: site_id,
              site_name: site_name,
              location: [longitude, latitude],
              qoeScore: vqoe_status === "unknown" ? defaultCellValue : vqoe_score,
              usage: usage,
              clusterOptions: getClusterOptions(experience),
              getMarker: () => {
                const color = getDefaultMarkerOptions(experience);
                let svgToRender = "";
                if (customMarker === "CIRCLE") {
                  svgToRender = `<svg width="20" height="20">
                  <circle cx='10' cy='10' r='10' fill='${color.color}' />
                </svg>`;
                } else {
                  svgToRender = `<svg width="50" height="20">
                  <rect x="15" y="0" width="45" height="18" style="fill:${color.color};stroke-width:3;stroke:${color.color}" />
                  <circle cx="10" cy="10" r="10" stroke="${colors.gray100}" stroke-width="3" fill="${color.color}" />
                  <circle cx="10" cy="10" r="7" fill="${colors.gray100}" />
                  <text x='25' y='15' font-size='12' fill="${colors.gray100}" stroke='${colors.gray100}' stroke-width='1'>#  ${snoToShow}</text>
                </svg>`;
                }
                const el = document.createElement("div");
                el.innerHTML = svgToRender;
                return el;
              },
            };
          });
        mapRef.current?.updatePoints(points);
        setLoading(false);
      }, 2000);
    }
  }, [sites]);

  return (
    <>
      {loading && <Spinner />}
      <div
        ref={mapVisualRef}
        style={{
          height: "100%",
          width: "100%",
          visibility: loading ? "hidden" : "visible",
        }}
        name="map-visual"
      ></div>
    </>
  );
};

GeoMapComponent.propTypes = {
  sites: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  options: PropTypes.object.isRequired,
  childCount: PropTypes.object,
  children: PropTypes.object,
  node: PropTypes.object,
  reportView: PropTypes.bool,
  setSelectedSite: PropTypes.func.isRequired,
  globalFilter: PropTypes.object.isRequired
};

export default GeoMapComponent;